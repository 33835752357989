<template>
  <div class="app-container">
    <slot name="navbar">
      <Navbar :back="back">
        <slot name="navbutton" />
      </Navbar>
    </slot>
    <main>
      <article :class="{ 'no-top-bot-padding': noTBpadding, wide: wide }">
        <slot />
      </article>
      <slot name="sidebar" />
    </main>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Navbar from "@/core/components/Navbar.vue";
import { RawLocation } from "vue-router";

@Component({
  components: {
    Navbar,
  },
})
export default class MainLayout extends Vue {
  @Prop() public back!: RawLocation;
  @Prop({ default: false, type: Boolean }) public noTBpadding!: boolean;
  @Prop({ default: false, type: Boolean }) public wide!: boolean;
}
</script>

<style scoped lang="scss">
.app-container {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100%;
  background: $primary-softer;

  main {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: center;
  }

  article {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $desktop;
    &.wide {
      max-width: $widescreen;
    }
    transition: max-width 0.75s ease, padding 0.75s ease;
    @media screen and (min-width: $ultrawide) {
      max-width: $widescreen;
    }
    min-height: 100%;
    padding: 1.5rem 1.25rem;
    @media screen and (min-width: $widescreen) {
      padding: 2rem 1.5rem;
    }
    @media screen and (min-width: $ultrawide) {
      padding: 3rem 2rem;
    }
    &.no-top-bot-padding {
      padding-top: 0rem;
      padding-bottom: 0rem;
      @media screen and (max-width: $tablet) {
        padding: 0rem;
      }
    }
  }
}
</style>
