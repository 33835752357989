import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const getInstance = (baseURL?: string): AxiosInstance => {
  const options: AxiosRequestConfig = {
    baseURL,
    withCredentials: true,
  };

  return axios.create(options);
};

class ApiClient {
  private instance: AxiosInstance;

  constructor() {
    this.instance = getInstance(process.env.VUE_APP_API_URL);
  }

  public async get(url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    try {
      return await this.instance.get(url, conf);
    } catch (err) {
      throw err;
    }
  }

  public async post(url: string, data: any = {}, headers: any = {}): Promise<AxiosResponse> {
    try {
      return await this.instance.post(url, data, headers);
    } catch (err) {
      throw err;
    }
  }

  public async delete(url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    try {
      return await this.instance.delete(url, conf);
    } catch (err) {
      throw err;
    }
  }

  public async put(url: string, data: any = {}, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    try {
      return await this.instance.put(url, data, conf);
    } catch (err) {
      throw err;
    }
  }

  public async patch(url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    try {
      return await this.instance.patch(url, conf);
    } catch (err) {
      throw err;
    }
  }

  public async head(url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    try {
      return await this.instance.head(url, conf);
    } catch (err) {
      throw err;
    }
  }
}

export { ApiClient };

export default {
  get: async (url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    try {
      return await getInstance().get(url, conf);
    } catch (err) {
      throw err;
    }
  },

  post: async (url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    try {
      return await getInstance().post(url, conf);
    } catch (err) {
      throw err;
    }
  },

  delete: async (url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    try {
      return await getInstance().delete(url, conf);
    } catch (err) {
      throw err;
    }
  },

  put: async (url: string, data: any = {}, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    try {
      return await getInstance().put(url, data, conf);
    } catch (err) {
      throw err;
    }
  },

  patch: async (url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    try {
      return await getInstance().patch(url, conf);
    } catch (err) {
      throw err;
    }
  },

  head: async (url: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    try {
      return await getInstance().head(url, conf);
    } catch (err) {
      throw err;
    }
  },
};
