<template>
  <div class="bar">
    <div class="bar__logo">
      <h3>
        <router-link :to="{ name: 'Home' }">
          Redeneren over Literatuur
        </router-link>
      </h3>
    </div>
    <div class="bar__content">
      <slot />
    </div>
    <div class="bar__version">
      <span> © {{ year }}. </span>
      <span> Versie {{ version }} </span>
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import environment from "@/environment";

@Component
export default class LoginBar extends Vue {
  private get year() {
    const start = "2021";
    const yearNow = new Date().getFullYear().toString();
    if (start === yearNow) {
      return start;
    }
    return start + " - " + yearNow;
  }

  private get version() {
    return environment.version + " — " + process.env?.VUE_APP_SHA;
  }
}
</script>

<style scoped lang="scss">
.bar {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 16px;
  padding: 1rem;
  &__logo {
    color: var(--primary);
    font-weight: 800;
    font-size: 1.25rem;
    a:hover, a:focus {
      text-decoration: none;
    }
  }
  &__content {
    width: 100%;
    max-width: 400px;
    margin: 3rem auto 0 auto;
  }
  &__version {
    display: flex;
    gap: 12px;
    font-size: 0.875rem;
    color: #666;
  }
}
</style>
