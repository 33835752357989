import { render, staticRenderFns } from "./ScoreNavItem.vue?vue&type=template&id=fa96bcb8&scoped=true"
import script from "./ScoreNavItem.vue?vue&type=script&lang=ts"
export * from "./ScoreNavItem.vue?vue&type=script&lang=ts"
import style0 from "./ScoreNavItem.vue?vue&type=style&index=0&id=fa96bcb8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa96bcb8",
  null
  
)

export default component.exports