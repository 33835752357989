<template>
  <ArticleLayout>
    <template #header>
      <div class="header">
        <div>
          <h1>Lesmateriaal</h1>
        </div>
      </div>
    </template>
    <section>
      <p>
        Redeneren in de klas kan op diverse manieren: mondeling, schriftelijk en
        digitaal. In de loop van mijn onderzoek paste ik de redeneerdidactiek op
        verschillende manieren toe. Op deze pagina vind je verschillende
        lesideeën die je kunt gebruiken in de klas. Dit materiaal is gemaakt
        voor zowel de onderbouw- als bovenbouw van vmbo, havo en vwo, en richt
        zich op diverse vormen van literatuur. Ook is een analoge versie van de
        lessenreeks over De donkere kamer van Damokles beschikbaar, gemaakt voor
        de bovenbouw van het vwo. Bij deze lessenreeks wordt het papieren boek
        gebruikt.
      </p>
    </section>
    <section class="downloads">
      <b-image
        class="feature-image"
        :src="require(`@/assets/images/42.jpg`)"
        :alt="'test'"
        lazy
      />
      <div class="downloads-wrapper">
        <h2>Downloads</h2>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="
            require(`@/assets/pdfs/Lessenreeks Redeneren over De donkere kamer van Damokles.pdf`)
          "
          icon-left="download"
          target="_blank"
        >
          Lessenreeks Redeneren over De donkere kamer van Damokles
        </b-button>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="
            require(`@/assets/pdfs/Opdrachten bij de lessenreeks Redeneren over De donkere kamer van Damokles.docx`)
          "
          icon-left="file-word"
          target="_blank"
        >
          Opdrachten - Lessenreeks Redeneren over De donkere kamer van Damokles
        </b-button>
        <div />
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="
            require(`@/assets/pdfs/Lesideeen voor redeneren met en over literatuur - Renate van Keulen.pdf`)
          "
          icon-left="download"
          target="_blank"
        >
          Lesideeën voor redeneren over en met literatuur
        </b-button>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="
            require(`@/assets/pdfs/Zakelijk en literair lezen combineren met redeneren. Onderbouw.pdf`)
          "
          icon-left="download"
          target="_blank"
        >
          Zakelijk en literair lezen combineren met redeneren:
          <strong>onderbouw</strong>
        </b-button>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="
            require(`@/assets/pdfs/Zakelijk en literair lezen combineren met redeneren. Bovenbouw.pdf`)
          "
          icon-left="download"
          target="_blank"
        >
          Zakelijk en literair lezen combineren met redeneren:
          <strong>bovenbouw</strong>
        </b-button>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="
            require(`@/assets/pdfs/Lessenreeks auteurschap W.F. Hermans.pdf`)
          "
          icon-left="download"
          target="_blank"
        >
          Docentenhandleiding - lessenreeks W.F. Hermans
        </b-button>
      </div>
    </section>

    <section class="three-sides">
      <b-image
        class="feature-image"
        :src="require(`@/assets/images/22.jpg`)"
        :alt="'test'"
        lazy
      />
      <b-image
        class="feature-image"
        :src="require(`@/assets/images/26.jpg`)"
        :alt="'test'"
        lazy
      />

      <b-image
        class="feature-image"
        :src="require(`@/assets/images/35.jpg`)"
        :alt="'test'"
        lazy
      />
    </section>
    <ContactForm />
  </ArticleLayout>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ArticleLayout from "@/core/layout/ArticleLayout.vue";
import Navbar from "@/core/components/Navbar.vue";
import VDivider from "@/core/components/VDivider.vue";
import CtaArea from "@/core/components/CtaArea.vue";
import ContactForm from "@/core/components/ContactForm.vue";

@Component({
  components: {
    ArticleLayout,
    Navbar,
    VDivider,
    CtaArea,
    ContactForm,
  },
})
export default class TeachingMaterials extends Vue {}
</script>


<style scoped lang="scss">
.vdivider {
  @media screen and (max-width: $desktop) {
    display: none;
  }
}
.header {
  max-width: $widescreen + 100px;
  display: grid;
  // grid-template-columns: 1fr 1fr;
  gap: 32px;
  justify-items: center;
  margin: 0 auto;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }
  iframe {
    max-width: 100%;
    border-radius: 8px;
  }
}
.downloads {
  background-color: $secondary-soft;
  border-radius: 1rem;
  padding: 2rem 2rem;
  display: grid;
  grid-template-columns: minmax(320px, 1fr) 3fr;
  gap: 2rem;
  justify-items: center;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }

  .feature-image {
    max-width: 560px;
    border-radius: 1rem;
    overflow: hidden;
    margin: auto;
    display: flex;
  }
  .downloads-wrapper {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    max-width: max-content;
    width: 100%;
    .download-btn {
      justify-content: flex-start;
      height: max-content;
      border-radius: 1.5rem;
      :deep span:not(.icon) {
        white-space: normal;
        text-align: left;
        @media screen and (max-width: $tablet) {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.three-sides {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  width: 100%;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }

  .feature-image {
    max-height: 240px;
    max-width: 480px;
    aspect-ratio: 330 / 230;
    border-radius: 1rem;
    overflow: hidden;
    margin: auto;
    display: flex;
  }
}
</style>
