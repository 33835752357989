import { Block, InformationBlockType, QuestionBlockType } from "@/lesson/block.types";
import { state } from "@/lesson/store/lesson.state";
import { LessonStepType } from "../lesson.types";

export function isBlockComingUp(block: Block): boolean {
  const stepOfBlock = state.lesson?.steps.find((s) => {
    return s.blocks.some((b) => { return b.id === block.id })
  });
  if (stepOfBlock?.type === LessonStepType.QUIZ) {
    return false;
  }
  if (stepOfBlock && state.currentStep) {
    if (stepOfBlock.order_no > state.currentStep.order_no) {
      return true;
    }
    if (stepOfBlock.order_no < state.currentStep.order_no) {
      return false;
    }
  }

  // If first block then is never coming up
  if (block.order_no === 0) {
    return false;
  }

  // If info block and next block has been completed return false
  if (Object.values(InformationBlockType).includes(block.block_type as InformationBlockType)) {
    const nextBlock = stepOfBlock?.blocks[block.order_no + 1];
    if (
      nextBlock?.complete &&
      !Object.values(InformationBlockType).includes(nextBlock.block_type as InformationBlockType)
    ) {
      return false;
    }
  }

  // Otherwise check if all previous blocks have been completed
  for (let i = 0; i < block.order_no - 1; i++) {
    const prevBlock = stepOfBlock?.blocks[i];
    if (prevBlock && !prevBlock.complete && Object.values(QuestionBlockType).includes(prevBlock.block_type as QuestionBlockType)) {
      return true;
    }
  }

  return false;
}