import { RouteConfig } from 'vue-router';
import LessonView from '@/lesson/views/LessonView.vue';
import StartView from '@/lesson/views/StartView.vue';
import FinishView from '@/lesson/views/FinishView.vue';

export const lessonRoutes: RouteConfig[] = [
  {
    path: ":lessonId",
    name: "Lesson",
    props: true,
    component: LessonView,
  },
  {
    path: ":lessonId/start",
    name: "LessonStart",
    props: true,
    component: StartView,
  },
  {
    path: ":lessonId/einde",
    name: "LessonFinish",
    props: true,
    component: FinishView,
  },
  {
    path: '*',
    redirect: { name: 'Home' },
  }
];
