<template>
  <HelperLayout
    :isCourse="true"
    :text="text"
    :to="{ name: 'Course', params: { courseId: course.id } }"
    :btnText="btnText"
  />
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import HelperLayout from "@/lesson/layout/HelperLayout.vue";
import { Course } from "@/course/course.types";

@Component({
  components: {
    HelperLayout,
  },
})
export default class IntroMessageView extends Vue {
  @Getter("course", { namespace: "course" })
  private course!: Course;

  private text =
    "Je gaat nu starten met je zoektocht naar de waarheid in De donkere kamer van Damokles. Begin met level 1 waar je het eerste deel van het verhaal hoort en een opdracht maakt over het verzet.";
  private btnText = "Terug naar level overzicht";
}
</script>

<style scoped lang="scss">
</style>
