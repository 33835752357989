<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <b-loading is-full-page :active.sync="isLoading" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Channel } from "laravel-echo";
import { Action, Getter } from "vuex-class";
import { User } from "./login/login.types";

@Component
export default class AppView extends Vue {
  @Action("UPDATE_USER_SCORE", { namespace: "login" })
  private updateUserScore!: (score: number) => void;
  @Getter("user", { namespace: "login" })
  private user!: User;

  private privateChannel!: Channel;
  private isLoading = false;

  private created() {
    this.isLoading = true;
    this.$router.beforeEach((to, from, next) => {
      this.isLoading = true;
      next();
    });
    this.$router.afterEach(() => {
      this.isLoading = false;
    });
  }

  private mounted() {
    this.isLoading = false;
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
  height: 100%;
  min-height: 100%;
}
</style>
