'use strict';

import { RootState } from '@/store/store.types';
import { GetterTree } from 'vuex';
import { LessonState } from '@/lesson/lesson.types';
import { stepIsComplete } from '../utils/stepIsComplete';

export const getters: GetterTree<LessonState, RootState> = {
  lesson(state: LessonState) {
    return state.lesson;
  },
  currentStep(state: LessonState) {
    return state.currentStep;
  },
  furthestStep(state: LessonState) {
    // starts from 1
    if (state.lesson) {
      for (const step of state.lesson.steps) {
        // check for each step is done. First one who isnt done, that is the furthest step.
        if (!stepIsComplete(step)) {
          return step.order_no;
        }
      }
      // if all pages are done, furthest step is last step.
      return state.lesson.steps.every((step) => stepIsComplete(step)) ? state.lesson.steps.length : 1;
    }
    return 1;
  },
  progressPassedCurrentStep(state: LessonState) {
    return state.progressPassedCurrentStep;
  },
  blocksInEdit(state: LessonState) {
    return state.blocksInEdit;
  },
};
