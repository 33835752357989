import { AxiosResponse } from "axios";
import { ApiClient } from "@/core/api/api-client.class";
import { Course, CourseBase } from "@/course/course.types";
// import { LessonBase, LessonStatus } from "@/lesson/lesson.types";

const client = new ApiClient();

export const courseApi = {
  async getCourses(): Promise<CourseBase[]> {
    try {
      const response = (await client.get(`/courses`)) as AxiosResponse<CourseBase[]>;
      const courses = response.data;
      return courses;
    } catch (err) {
      throw err;
    }
  },
  async getCourse(courseId: string): Promise<Course> {
    try {
      const response = (await client.get(`/course/${courseId}`)) as AxiosResponse<Course>;
      const course = response.data;
      return course;
    } catch (err) {
      throw err;
    }
  },
  async postCourseStarted(id: string): Promise<any> {
    try {
      const response = (await client.post(`/course/${id}/started`)) as AxiosResponse<any>;
      return response;
    } catch (err) {
      throw err;
    }
  },
};
