<template>
  <b-collapse
    v-if="hint_html"
    class="hint-container"
    :open="showHint"
    :aria-id="'contentIdForBlock' + blockId"
  >
    <b-message
      type="is-primary"
      has-icon
      icon="help-circle-outline"
      icon-size="is-medium"
    >
      <div v-html="hint_html" />

      <b-collapse
        animation="slide"
        v-for="(item, index) of hint_items"
        :key="index"
        :open="isOpen == index"
        @open="isOpen = index"
        :aria-id="'contentIdForHintItem-' + index"
        class="hint-item"
      >
        <template #trigger="props">
          <div
            class="card-header"
            :class="{ 'card-header__active': props.open }"
            role="button"
            :aria-controls="'contentIdForHintItem-' + index"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">
              {{ item.attributes.hint_header }}
            </p>
            <a class="card-header-icon">
              <b-icon :icon="'chevron-down'" :class="{ rotate: props.open }" />
            </a>
          </div>
        </template>
        <div class="card-content" v-html="item.attributes.hint_text_html" />
      </b-collapse>
    </b-message>
  </b-collapse>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { HintItem } from "@/lesson/block.types";

@Component
export default class BlockBase extends Vue {
  @Prop({
    default() {
      return [];
    },
  })
  private hint_items!: HintItem[];
  @Prop({ default: "", type: String }) private hint_html!: string;
  @Prop({ default: 0, type: Number }) private blockId!: number;
  @Prop({ default: false, type: Boolean }) private showHint!: boolean;

  private isOpen = -1; // all are closed at render
}
</script>

<style scoped lang="scss">
.hint-container {
  .message.is-primary {
    box-shadow: 1px 2px 10px #e3d0b550;
    margin-bottom: 2rem;
    :deep .message-body {
      border-color: $secondary;
      .icon {
        color: $secondary;
      }
    }
  }
  :deep [id^="contentIdForBlock"] {
    margin: 0.625rem 2.5rem;

    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 1.4em;
    }
    h3 {
      font-size: 1.3em;
    }
    h4 {
      font-size: 1.25em;
    }

    div:empty {
      display: none;
    }
  }
}
.hint-item {
  .card-header {
    display: flex;
    align-content: stretch;
    border-radius: 8px;
    color: $secondary;
    font-family: $headering-font-family;
    box-shadow: none;
    transition: background 0.2s ease, padding 0.2s ease;
    background: #e3d0b536;
    margin-top: 4px;
    padding: 0;
    &:hover,
    &:focus-visible,
    &__active {
      background: #e3d0b564;
    }

    &__active {
      padding: 4px 0;
    }
  }
  .card-header-title {
    color: $secondary;
    font-family: $headering-font-family;
    padding: 0.75rem 1.25rem;
  }
  .card-header-icon :deep .icon {
    transition: transform 0.4s ease;
    &.rotate {
      transform: rotate(180deg);
    }
  }
  :deep [id^="contentIdForHintItem"] {
    margin: 0;
    .card-content {
      padding: 1rem 1.5rem 2rem;
    }
  }
}
</style>
