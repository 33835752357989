<template>
  <b-dropdown
    v-model="navigation"
    position="is-bottom-left"
    append-to-body
    aria-role="menu"
    v-if="isAuthenticated"
  >
    <template #trigger>
      <a class="navbar-item navbar-item--dropdown-button" role="button">
        <b-icon icon="account-circle-outline" />
        <span class="btn-text">{{ user.first_name }} {{ user.last_name }}</span>
        <b-icon icon="menu-down-outline" />
      </a>
    </template>
    <b-dropdown-item custom aria-role="menuitem">
      <p>
        Ingelogd als
        <strong> {{ user.first_name }} {{ user.last_name }} </strong>
        <br />
        <a
          :href="`mailto:${user.email}`"
          :alt="`email: ${user.email}`"
          target="_blank"
        >
          {{ user.email }}
        </a>
      </p>
    </b-dropdown-item>
    <hr class="dropdown-divider" />
    <!-- <b-dropdown-item value="Profiel" aria-role="menuitem">
      <b-icon icon="account-circle-outline" />
      <span>Profiel</span>
    </b-dropdown-item> -->
    <!-- <hr class="dropdown-divider" aria-role="menuitem" /> -->
    <!-- <b-dropdown-item value="settings">
            <b-icon icon="cog-outline" />
            Settings
          </b-dropdown-item> -->
    <b-dropdown-item @click="logout()" value="loguit" aria-role="menuitem">
      <b-icon icon="logout-variant" />
      <span>Loguit</span>
    </b-dropdown-item>
  </b-dropdown>
  <div v-else>
    <b-button
      type="is-primary"
      icon-left="login-variant"
      rounded
      tag="router-link"
      :to="{ name: 'Login' }"
    >
      Login
    </b-button>
  </div>
</template>


<script lang="ts">
import { User } from "@/login/login.types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class NavbarProfileItem extends Vue {
  @Getter("user", { namespace: "login" })
  private user!: User;
  @Getter("isAuthenticated", { namespace: "login" })
  private isAuthenticated!: boolean;
  @Action("LOGOUT", { namespace: "login" })
  private logout!: () => void;

  private navigation = false;
}
</script>

<style scoped lang="scss">
:deep .navbar-item,
.dropdown-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

.btn-text {
  width: 100%;
  font-size: 0.875rem;
  font-family: $headering-font-family;
}
.vdivider {
  @media screen and (max-width: $desktop) {
    display: none;
  }
}

:deep .navbar-item {
  &--dropdown-button {
    padding: 0;
  }
}
</style>
