<template>
  <section class="number-spotlight">
    <div class="number-spotlight__upper">
      <h2>
        {{ title }}
        <small v-if="subtitle">
          <br />
          {{ subtitle }}
        </small>
      </h2>
    </div>
    <div class="number-spotlight__lower">
      <div class="number-spotlight__card">
        <div style="display: contents" v-for="(item, i) in items" :key="i">
          <div>
            <p class="main">{{ item.main }}</p>
            <p class="sub">{{ item.sub }}</p>
          </div>
          <div
            class="number-spotlight__card-divider"
            v-if="i !== items.length - 1"
          />
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NumberSpotlight extends Vue {
  @Prop({ default: "Title", type: String }) private title!: string;
  @Prop({ default: "", type: String }) private subtitle!: string;
  @Prop({
    default() {
      return [
        {
          main: "100+",
          sub: "Leerlingen",
        },
        {
          main: "5",
          sub: "Lessen",
        },
      ];
    },
  })
  private items!: any;
}
</script>

<style scoped lang="scss">
.number-spotlight {
  &:first-child {
    margin-top: 0;
  }
  &__upper {
    text-align: center;
    background: $primary-light;
    padding: 2.5rem 0 4rem 0;
    border-radius: 1rem;
  }
  &__card {
    background: $white;
    max-width: 80%;
    margin: 0 auto;
    margin-top: -3rem;
    border-radius: 1rem;
    box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1),
      0 0px 0 1px rgba($scheme-invert, 0.02);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 2px;
    justify-content: space-around;
    text-align: center;

    @media screen and (max-width: $tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto 1fr;
    }

    .main {
      font-size: 2.25rem;
      font-weight: 700;
      color: $primary;
      margin: 1rem 0 0 0;

      @media screen and (max-width: $tablet) {
        font-size: 1.75rem;
      }
    }
    .sub {
      font-size: 1.375rem;
      color: $grey;
      margin: -0.25rem 0 1rem 0;
      @media screen and (max-width: $tablet) {
        font-size: 1rem;
      }
    }
  }
  &__card-divider {
    background: $light;
    width: 1px;
    height: auto;
    @media screen and (max-width: $tablet) {
      width: auto;
      height: 1px;
    }
  }
}
</style>
