<template>
  <LoginContainer>
    <template>
      <ContentLayout>
        <template #title>Wachtwoord vergeten</template>
        <template #subtitle>
          Vul hieronder je emailadres in. Dan ontvang je een mail met een link
          om een nieuw wachtwoord aan te maken.
        </template>
        <template>
          <form>
            <b-field
              label="Email"
              :type="!emailError ? '' : 'is-danger'"
              :message="emailMsg"
            >
              <b-input
                icon="email"
                type="username"
                v-model="username"
                @blur="emailCheck()"
                placeholder="leerling@school.nl"
              />
            </b-field>
            <b-button
              @click="reset()"
              type="is-primary"
              icon-right="chevron-right"
              expanded
              rounded
            >
              Reset wachtwoord
            </b-button>
          </form>
        </template>
      </ContentLayout>
    </template>
  </LoginContainer>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LoginContainer from "@/login/layout/LoginContainer.vue";
import ContentLayout from "@/login/layout/ContentLayout.vue";
import { loginApi } from "@/login/api/login.api";
import { ResetPasswordPost } from "@/login/login.types";

@Component({
  components: {
    LoginContainer,
    ContentLayout,
  },
})
export default class ForgotPassword extends Vue {
  private username = "";
  private emailError = false;
  private emailMsg = "";
  private emailCheck() {
    const reEmail = /\S+@\S+\.\S+/;
    if (this.username === "") {
      this.emailError = true;
      this.emailMsg = "Vul hier je (school-)emailadres in.";
      return true;
    } else if (!reEmail.test(this.username)) {
      this.emailError = true;
      this.emailMsg = "Dit is geen geldig emailadres";
      return true;
    } else {
      this.emailError = false;
      this.emailMsg = "";
      return false;
    }
  }

  private get badInput() {
    let error = false;
    if (this.emailCheck()) {
      error = true;
    }
    return error;
  }

  private async reset() {
    if (!this.badInput) {
      const data: ResetPasswordPost = {
        email: this.username,
      };
      try {
        await loginApi.resetPassword(data);
        this.$buefy.notification.open({
          duration: 9000,
          message: `Gelukt! Je ontvangt zometeen een email van ons.`,
          position: "is-bottom-right",
          type: "is-success",
          hasIcon: true,
        });
        this.$router.push({ name: "Login" });
      } catch (err) {
        this.$buefy.notification.open({
          indefinite: true,
          message: `Er ging helaas wat mis. Probeer het later nog eens of neem contact met ons op.`,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: true,
        });
        throw err;
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
