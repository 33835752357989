<template>
  <b-navbar shadow class="navbar-sticky" ref="nav">
    <template #brand>
      <b-navbar-item v-if="back" tag="router-link" :to="back" class="animate">
        <b-tooltip label="Level overzicht" position="is-bottom">
          <b-icon icon="arrow-left" />
        </b-tooltip>
      </b-navbar-item>
      <!-- <b-navbar-item v-if="home" tag="router-link" :to="{ name: 'Home' }">
        <b-icon icon="home-outline" />
        <span class="btn-text">Home</span>
      </b-navbar-item> -->
      <b-navbar-item tag="span" class="show" v-if="back">
        <VDivider />
      </b-navbar-item>

      <b-navbar-item tag="h4" class="title-slot">
        <slot name="title">
          <router-link :to="{ name: 'Home' }" class="home-link">
            <span class="title--redeneren">Redeneren</span>
            <span class="title--over-literatuur">over Literatuur</span>
          </router-link>
        </slot>
      </b-navbar-item>
    </template>

    <template #end>
      <slot />
      <b-navbar-item tag="span" v-if="hasSlot">
        <VDivider />
      </b-navbar-item>
      <b-navbar-item
        v-if="showUnAuthNavBtns"
        tag="router-link"
        :to="{ name: 'Research' }"
        :active="btnActive('Research')"
      >
        <b-icon icon="feature-search-outline" class="mdi-20px" />
        <span class="btn-text">Onderzoek</span>
      </b-navbar-item>
      <b-navbar-item
        v-if="showUnAuthNavBtns"
        tag="router-link"
        :to="{ name: 'ForTeachers' }"
        :active="btnActive('ForTeachers')"
      >
        <b-icon icon="human-male-board" class="mdi-20px" />
        <span class="btn-text">Voor docenten</span>
      </b-navbar-item>
      <b-navbar-item
        v-if="showUnAuthNavBtns"
        tag="router-link"
        :to="{ name: 'TeachingMaterials' }"
        :active="btnActive('TeachingMaterials')"
      >
        <b-icon icon="feature-search-outline" class="mdi-20px" />
        <span class="btn-text">Lesmateriaal</span>
      </b-navbar-item>
      <!-- <b-navbar-item
        v-if="showTeacherBtn"
        tag="router-link"
        :to="{ name: 'Teacher' }"
        :active="btnActive('Teacher')"
      >
        <b-icon icon="human-male-board" />
        <span class="btn-text">Docenten</span>
      </b-navbar-item> -->
      <b-navbar-item tag="span" v-if="showAuthNavBtns">
        <VDivider />
      </b-navbar-item>
      <b-navbar-item v-if="showAdminBtn" tag="a" :href="cms" target="_blank">
        <b-icon icon="school-outline" class="mdi-20px" />
        <span class="btn-text">CMS</span>
        <b-icon icon="open-in-new" size="is-small" />
      </b-navbar-item>
      <b-navbar-item
        v-if="showAuthNavBtns"
        tag="router-link"
        :to="{ name: 'Course' }"
        :active="btnActive('Course')"
      >
        <b-icon icon="book-open-page-variant-outline" class="mdi-20px" />
        <span class="btn-text">Levels</span>
      </b-navbar-item>
      <b-navbar-item
        v-if="showAuthNavBtns"
        tag="router-link"
        :to="{ name: 'Leaderboard' }"
        :active="btnActive('Leaderboard')"
      >
        <b-icon icon="trophy-outline" class="mdi-20px" />
        <span class="btn-text">Leaderboard</span>
      </b-navbar-item>
      <b-navbar-item tag="span" v-if="showUnAuthNavBtns">
        <VDivider />
      </b-navbar-item>
      <b-navbar-item v-if="isAuthenticated">
        <ScoreNavItem />
      </b-navbar-item>
      <b-navbar-item>
        <NavbarProfileItem />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>


<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VDivider from "@/core/components/VDivider.vue";
import NavbarProfileItem from "@/core/components/NavbarProfileItem.vue";
import { RawLocation } from "vue-router";
import { Getter } from "vuex-class";
import ScoreNavItem from "../../core/components/ScoreNavItem.vue";

@Component({
  components: {
    VDivider,
    NavbarProfileItem,
    ScoreNavItem,
  },
})
export default class Navbar extends Vue {
  @Prop() public back!: RawLocation;

  private get hasSlot() {
    return !!this.$slots.default;
  }

  @Getter("isAuthenticated", { namespace: "login" })
  private isAuthenticated!: boolean;
  @Getter("score", { namespace: "login" })
  private score!: number;
  @Getter("isTeacher", { namespace: "login" })
  private isTeacher!: boolean;
  @Getter("isAdmin", { namespace: "login" })
  private isAdmin!: boolean;

  private get showUnAuthNavBtns() {
    return !this.back;
  }
  private get showAuthNavBtns() {
    return this.showUnAuthNavBtns && this.isAuthenticated;
  }
  private get showTeacherBtn() {
    return this.showAuthNavBtns && (this.isTeacher || this.isAdmin);
  }
  private get showAdminBtn() {
    return this.showAuthNavBtns && this.isAdmin;
  }

  private btnActive(name: string) {
    return this.$route.name === name;
  }

  private get cms() {
    return `${process.env.VUE_APP_CMS_URL}`;
  }

  private timer = 0;
  @Watch("score")
  private onScoreChange() {
    clearTimeout(this.timer);
    const nav = (this.$refs.nav as any).$el;
    nav.style.top = "0";
    this.timer = setTimeout(() => {
      nav.style.top = "-3.75rem";
    }, 2000);
  }
}
</script>

<style scoped lang="scss">
.navbar {
  transition: padding 0.375s ease;
  @media screen and (min-width: $widescreen) {
    padding: 0 1.25rem !important;
  }
  @media screen and (min-width: $fullhd) {
    padding: 0 2rem !important;
  }
  &.navbar-sticky {
    transition: 0.2s ease top;
    position: sticky;
    top: -3.75rem;
  }
}
:deep h4.navbar-item {
  color: $primary;
}
.title-slot {
  text-align-last: justify;
  display: table !important;
  line-height: 1.175;
  font-size: 1.15rem;

  .home-link {
    color: $secondary;
    &:hover, &:focus, &:focus-visible, &:active {
      color: $secondary-dark;
      text-decoration: none;
    }
  }

  .title--redeneren {
    letter-spacing: 0.2px;
    font-weight: 600;
    font-size: 1.125em;
  }
  .title--over-literatuur {
    letter-spacing: -0.2px;
    display: block;
    font-size: 0.825em;
  }
  :deep .nav-title {
    & + .nav-title {
      margin-left: 0.625rem;
    }
  }
}
:deep .navbar-item,
.dropdown-item {
  display: flex !important;
  align-items: center;
  gap: 6px;
}
:deep .navbar-item {
  padding: 0.5rem 0.675rem;
  min-height: 44px;
  &--dropdown-button {
    padding: 0;
  }
  &.is-active {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      inset: auto 0 0 0;
      background: $primary;
      height: 2px;
      width: 100%;
      border-radius: 2px;
    }
  }
  &:hover {
    text-decoration: none;
  }
}
@media screen and (max-width: $desktop) {
  .dropdown-menu-animation {
    width: 100%;
    :deep .dropdown-trigger {
      width: 100%;
    }
  }
}
.btn-text {
  width: 100%;
  line-height: 1;
  font-size: 0.875rem;
  font-family: $headering-font-family;
  & + .icon.is-small {
    margin-left: 2px;
    opacity: 0.64;
  }
}
.vdivider {
  @media screen and (max-width: $desktop) {
    display: none;
  }
}

.animate {
  min-width: 32px;
  transition: 0.25s ease;
  box-sizing: content-box;
  justify-content: center;
  &:hover {
    transform: translateX(-4px);
  }
  @media screen and (max-width: $desktop) {
    margin-left: 8px;
  }
}

.show :deep * {
  display: block !important;
}
</style>
