<template>
  <div class="app-container">
    <slot name="navbar">
      <Navbar :back="back">
        <slot name="navbutton" />
      </Navbar>
    </slot>
    <header>
      <slot name="header" />
    </header>
    <main>
      <article>
        <slot />
      </article>
    </main>
    <Footer />
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Navbar from "@/core/components/Navbar.vue";
import Footer from "@/core/components/Footer.vue";
import { RawLocation } from "vue-router";

@Component({
  components: {
    Navbar,
    Footer,
  },
})
export default class ArticleLayout extends Vue {
  @Prop() public back!: RawLocation;
}
</script>

<style scoped lang="scss">
.app-container {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  min-height: 100%;

  header {
    background: $primary-soft;
    padding: 3rem 2.5rem;
    :deep .header * {
      &:first-child {
        justify-self: start;
      }
      &:nth-child(2) {
        @media screen and (min-width: $desktop) {
          justify-self: end;
        }
      }
    }
  }

  article {
    max-width: $widescreen;
    margin: 0 auto 0 auto;
    padding: 3rem 2rem;

    section + section {
      margin: 8rem 0;
    }
    section:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
