<template>
  <section class="form">
    <h2>Contact</h2>
    <div class="form-grid">
      <div class="form-wrapper">
        <p>
          Heb je vragen, ideeën, of andere suggesties? Wil je meer informatie
          over dit promotieonderzoek? Neem dan gerust contact op!
        </p>
        <form @submit.prevent="send()">
          <b-field label="Naam" label-for="name">
            <b-input id="name" type="text" v-model="contactInfo.name" />
          </b-field>
          <b-field label="E-mailadres" label-for="email">
            <b-input id="email" type="email" v-model="contactInfo.emailFrom" />
          </b-field>
          <b-field label="Bericht" label-for="message">
            <b-input
              id="message"
              v-model="contactInfo.message"
              type="textarea"
              rows="4"
            />
          </b-field>
          <input type="hidden" :value="secret" />
          <b-button
            class="form__button"
            type="is-primary"
            icon-right="send"
            rounded
            native-type="submit"
            :disabled="!canSend"
          >
            Verstuur
          </b-button>
        </form>
      </div>
      <div class="image-wrapper">
        <b-image
          class="feature-image"
          :src="require(`@/assets/images/6.jpg`)"
          :alt="'test'"
          lazy
        />
        <b-image
          class="feature-image"
          :src="require(`@/assets/images/8.jpg`)"
          :alt="'test'"
          lazy
        />

        <b-image
          class="feature-image"
          :src="require(`@/assets/images/24.jpg`)"
          :alt="'test'"
          lazy
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { mailApi } from "@/core/api/mail.api";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ContactForm extends Vue {
  private contactInfo = {
    name: "",
    emailFrom: "",
    message: "",
  };

  private token = "";
  private ran = "";
  private secret = "";

  private async mounted() {
    this.token = await mailApi.getParams();
    const obj = JSON.parse(atob(this.token.split(".")[1]));
    this.ran = obj.form.ran;
  }

  private get canSend() {
    if (this.timeout) return false;
    if (Object.values(this.contactInfo).some((v) => v === "")) return false;
    return true;
  }

  private timeout = false;

  private send() {
    if (this.canSend) {
      mailApi.sendContact({
        ...this.contactInfo,
        token: this.token,
        [this.ran]: this.secret,
      });
      this.$buefy.notification.open({
        duration: 9000,
        message: `Dankjewel! Je bericht is verzonden.`,
        position: "is-bottom-right",
        type: "is-success",
        hasIcon: true,
      });
      this.contactInfo = {
        name: "",
        emailFrom: "",
        message: "",
      };
    }
    this.timeout = true;
    setTimeout(() => {
      this.timeout = false;
    }, 5000);
  }
}
</script>

<style scoped lang="scss">
.form {
  &__button {
    margin-top: 1rem;
    margin-left: auto;
    display: block;
  }
}

.form-grid {
  display: grid;
  grid-template-columns: 5fr 2fr;
  gap: 2rem;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.image-wrapper {
  justify-self: end;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1rem;
  @media screen and (max-width: $desktop) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.75rem;
    width: 100%;
  }

  .feature-image {
    max-height: 140px;
    max-width: 400px;
    aspect-ratio: 320/180;
    border-radius: 1rem;
    overflow: hidden;
    margin: auto;
    display: flex;

    @media screen and (max-width: $desktop) {
      border-radius: 0.5rem;
      aspect-ratio: unset;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
