<template>
  <PageLayout>
    <h1 class="title has-text-centered">{{ step.name }}</h1>
    <component
      v-for="block in step.blocks"
      :key="block.id"
      :is="getBlockComponent(block)"
      :block="block"
    />
  </PageLayout>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueConstructor } from "vue";
import { LessonStep } from "@/lesson/lesson.types";
import { Block, BlockType } from "@/lesson/block.types";
import { step } from "@/lesson/utils/exampleTypes";
import SomeBlock from "@/lesson/components/Blocks/SomeBlockComponent.vue";
import TextBlockComponent from "@/lesson/components/Blocks/TextBlockComponent.vue";
import InfoBlockComponent from "@/lesson/components/Blocks/InfoBlockComponent.vue";
import SourceBlockComponent from "@/lesson/components/Blocks/SourceBlockComponent.vue";
import FillBlanksBlockComponent from "./Blocks/FillBlockComponent.vue";
import MultipleChoiceBlockComponent from "./Blocks/MultipleChoiceBlockComponent.vue";
import ChecklistBlockComponent from "./Blocks/ChecklistBlockComponent.vue";
import RankingBlockComponent from "./Blocks/RankingBlockComponent.vue";
import PageLayout from "../layout/PageLayout.vue";

@Component({
  components: {
    PageLayout,
  },
})
export default class Page extends Vue {
  @Prop({
    default() {
      return step;
    },
  })
  private step!: LessonStep;

  private getBlockComponent(block: Block): VueConstructor<Vue> {
    switch (block.block_type) {
      // Question blocks
      case BlockType.TextBlock:
        return TextBlockComponent;
      case BlockType.FillBlanksBlock:
        return FillBlanksBlockComponent;
      // Info blocks
      case BlockType.InfoBlock:
        return InfoBlockComponent;
      case BlockType.SourceBlock:
        return SourceBlockComponent;
      case BlockType.MultipleChoiceBlock:
        return MultipleChoiceBlockComponent;
      case BlockType.ChecklistBlock:
        return ChecklistBlockComponent;
      case BlockType.RankingBlock:
        return RankingBlockComponent;
      case BlockType.SomeBlock:
        return SomeBlock;
      default:
        return SomeBlock;
    }
  }
}
</script>

<style scoped lang="scss">
</style>
