<template>
  <MainLayout wide>
    <div class="wrapper">
      <div class="left">
        <h1>Winnaars</h1>
        <div class="podium-container">
          <div class="podium">
            <p class="podium__name" v-if="leaderboard[1]">
              {{ leaderboard[1].first_name }} {{ leaderboard[1].last_name }}
            </p>
            <b-skeleton width="60%" height="24px" animated v-else />
            <div class="podium__rank second">2</div>
          </div>
          <div class="podium">
            <p class="podium__name" v-if="leaderboard[0]">
              {{ leaderboard[0].first_name }} {{ leaderboard[0].last_name }}
            </p>
            <b-skeleton width="60%" height="24px" animated v-else />
            <div class="podium__rank first">
              <b-icon icon="trophy-outline" size="is-large" />
            </div>
          </div>
          <div class="podium">
            <p class="podium__name" v-if="leaderboard[2]">
              {{ leaderboard[2].first_name }} {{ leaderboard[2].last_name }}
            </p>
            <b-skeleton width="60%" height="24px" animated v-else />
            <div class="podium__rank third">3</div>
          </div>
        </div>
      </div>
      <div class="right">
        <h2>Leaderboard</h2>
        <b-table
          :data="leaderboard"
          hoverable
          :loading="loading"
          mobile-cards
          :selected="selected"
        >
          <b-table-column width="8" field="place" centered v-slot="props">
            <b-icon icon="trophy" v-if="props.row.place == 1" />
            <b-icon
              icon="seal"
              v-if="props.row.place == 2 || props.row.place == 3"
            />
          </b-table-column>
          <b-table-column
            width="8"
            field="place"
            label="#"
            centered
            v-slot="props"
          >
            {{ props.row.place }}
          </b-table-column>
          <b-table-column field="first_name" label="Naam" v-slot="props">
            {{ props.row.first_name }} {{ props.row.last_name }}
          </b-table-column>
          <b-table-column field="score" label="Punten" centered v-slot="props">
            {{ props.row.score }}
          </b-table-column>
        </b-table>
      </div>
      <div class="below">
        <NumberSpotlight
          :title="'Jouw score'"
          :subtitle="
            leaderboardUser?.first_name + ' ' + leaderboardUser?.last_name
          "
          :items="[
            { main: leaderboardUser?.place, sub: 'plaats' },
            { main: leaderboardUser?.score, sub: 'punten' },
          ]"
          v-if="leaderboardUser"
        />
      </div>
    </div>
  </MainLayout>
</template>


<script lang="ts">
import NumberSpotlight from "@/core/components/NumberSpotlight.vue";
import MainLayout from "@/core/layout/MainLayout.vue";
import { User } from "@/login/login.types";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { leaderboardApi } from "../api/leaderboard.api";
import { LeaderboardUser } from "../leaderboard.types";

@Component({
  components: {
    MainLayout,
    NumberSpotlight,
  },
})
export default class LeaderboardView extends Vue {
  @Getter("user", { namespace: "login" })
  private user!: User;
  private leaderboard: LeaderboardUser[] = [];
  private leaderboardUser: LeaderboardUser | null = null;

  private loading = false;

  private get selected() {
    return this.leaderboard.find((entry) => entry.is_auth_user);
  }

  private beforeMount() {
    this.loading = true;
    this.loadLeaderboard();
    this.loadLeaderboardUser();
  }

  private async loadLeaderboard() {
    try {
      this.leaderboard = await leaderboardApi.getLeaderboard();
      this.loading = false;
    } catch (error) {
      this.errorNotification("LBA1");
    }
  }

  private async loadLeaderboardUser() {
    try {
      this.leaderboardUser = await leaderboardApi.getLeaderboardForUser(
        this.user.id
      );
    } catch (error) {
      this.errorNotification("LBS1");
    }
  }

  private errorNotification(errorNumber?: string) {
    this.$buefy.notification.open({
      indefinite: true,
      message: `Er ging helaas wat mis. Probeer het later nog eens. <br/><code>${errorNumber}</code>`,
      position: "is-bottom-right",
      type: "is-danger",
      hasIcon: true,
    });
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 1.5em;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }
}
.left {
  @media screen and (max-width: 560px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .podium-container {
    display: flex;
    align-items: flex-end;
    font-family: $headering-font-family;

    .podium {
      min-width: 160px;
      width: 33.3%;
      transition: 0.15s ease;
      &:hover {
        color: $secondary;
        scale: 1.05;
        transform-origin: bottom center;
        z-index: 99;
        font-weight: 600;
      }

      &__rank {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.25rem;
        font-weight: 600;
        color: #fff;
        border-radius: 6px 6px 0 0;
        &.first {
          min-height: 280px;
          background: rgb(255, 172, 37);
          background: $secondary;
        }
        &.second {
          min-height: 220px;
          background: hsl(35 20% 50%);
        }
        &.third {
          min-height: 190px;
          background: $primary;
        }
      }

      &__name,
      :deep .b-skeleton {
        text-align: center;
        align-items: center;
        padding: 0.5rem 0.05rem;
      }
    }
  }
}
.right {
  h2 {
    margin-bottom: 1rem;
  }
  :deep .b-table .table {
    border-radius: 6px;
    overflow: hidden;
  }
}
.below {
  grid-column: 1/3;

  @media screen and (max-width: $desktop) {
    grid-column: unset;
  }
  margin-top: 2rem;
}
</style>
