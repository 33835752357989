/**
 * General
 */
export interface Block {
  id: string,
  order_no: number,
  content: string,
  hint_html?: string,
  hint_items: HintItem[],
  block_type: QuestionBlockType | InformationBlockType,
  complete: boolean,
  depends_on_other_question: boolean,
  correct_answers?: BlockAnswerType,
}

export interface HintItem {
  key: string,
  layout: "item",
  attributes: {
    hint_header?: string,
    hint_text_html: string,
  },
}

export enum QuestionBlockType {
  SomeBlock = 'SomeBlock',
  TextBlock = 'TextBlock',
  FillBlanksBlock = 'FillBlanksBlock',
  MultipleChoiceBlock = 'MultipleChoiceBlock',
  ChecklistBlock = 'ChecklistBlock',
  RankingBlock = 'RankingBlock',
}

export enum InformationBlockType {
  InfoBlock = 'InfoBlock',
  SourceBlock = 'SourceBlock',
}

export const BlockType = { ...QuestionBlockType, ...InformationBlockType };

/**
 * Question Blocks
 */
// Main
export interface QuestionBlock extends Block {
  question: string;
  latest_answer?: BlockAnswer;
  is_example: 0 | 1;
}

export interface BlockAnswer {
  answer: BlockAnswerType;
  used_hint: 0 | 1 | boolean;
  correct?: 0 | 1 | boolean;
}
export interface AnswerPost extends BlockAnswer {
  block_id: string;
  used_hint: boolean;
}

export interface AnswerReply {
  message: string;
  valid: boolean;
  correct?: boolean;
  correct_answer: BlockAnswerType;
}

export type BlockAnswerType = string | [string] | ChoiceItem | ChecklistItem[] | RankItem[];

// Specific
export interface TextBlock extends QuestionBlock {
  prefilled_answer?: string,
  min_length_answer?: number,
  type: 'short' | 'long',
}

export interface FillBlanksBlock extends QuestionBlock {
  question_items: FillBlanksItem[],
}

export interface FillBlanksItem {
  key: string,
  layout: "item",
  attributes: {
    subquestion_text?: string,
    prefilled_answer: string,
    subquestion_hint?: string,
  }
}

export interface MultipleChoiceBlock extends QuestionBlock {
  choice_items: ChoiceItem[],
  layout: MultipleChoiceBlockLayout,
}
export interface ChoiceItem {
  key: string,
  text: string,
  correct?: boolean,
}

export enum MultipleChoiceBlockLayout {
  COLUNN = 'column',
  ROW = 'row',
}

export interface ChecklistBlock extends QuestionBlock {
  show_amount_correct: boolean,
  amount_correct?: number,
  checklist_items: ChecklistItem[],
}
export interface ChecklistItem {
  key: string,
  text: string,
  correct?: boolean,
}

export interface RankingBlock extends QuestionBlock {
  rank_items: RankItem[],
}
export interface RankItem {
  key: string,
  text: string,
  correct?: boolean,
}

/**
 * Information Blocks
 */
// Main
export type InformationBlock = Block

// Specific
export type InfoBlock = InformationBlock

export interface SourceBlock extends InformationBlock {
  source?: string;
  source_no: string;
  source_type: 'bron' | 'fragment';
  audio?: string;
  video_id?: string;
  video_begin_time?: number;
  video_end_time?: number;
}

/**
 * TEMP Blocks
 */
export type SomeBlock = QuestionBlock
