<template>
  <footer class="footer-container">
    <div class="footer-main">
      <!-- <div class="footer-item">
        <h4>Ondersteuning</h4>
        <ul class="none">
          <li>
            <a
              href="mailto:info@redenerenoverliteratuur.nl"
              target="_blank"
              class="btn-footer"
            >
              Contact
            </a>
          </li>
          <li>
            <router-link :to="{ name: 'Teacher' }" class="btn-footer">
              Docentenomgeving
            </router-link>
          </li>
        </ul>
      </div> -->
      <div class="footer-item">
        <h4>Pagina's</h4>
        <ul class="none">
          <li>
            <router-link :to="{ name: 'Research' }" class="btn-footer">
              Onderzoek
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ForTeachers' }" class="btn-footer">
              Voor docenten
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'TeachingMaterials' }" class="btn-footer">
              Lesmateriaal
            </router-link>
          </li>
        </ul>
      </div>
      <div class="footer-item">
        <h4>Voor leerlingen</h4>
        <ul class="none">
          <li>
            <router-link :to="{ name: 'Login' }" class="btn-footer">
              Login
            </router-link>
          </li>
          <li v-if="!isAuthenticated">
            <router-link :to="{ name: 'ForgotPassword' }" class="btn-footer">
              Wachtwoord vergeten
            </router-link>
          </li>
          <li v-if="isAuthenticated">
            <router-link :to="{ name: 'Course' }" class="btn-footer">
              Levels
            </router-link>
          </li>
          <li v-if="isAuthenticated">
            <router-link :to="{ name: 'Leaderboard' }" class="btn-footer">
              Leaderboard
            </router-link>
          </li>
        </ul>
      </div>
      <div class="footer-item"></div>
      <div class="footer-item-2">
        <ul class="none">
          <li>
            <h3>
              <router-link :to="{ name: 'Home' }">
                Redeneren over literatuur
              </router-link>
            </h3>
          </li>
          <li>
            <a
              href="mailto:info@redenerenoverliteratuur.nl"
              target="_blank"
              class="btn-footer"
            >
              info@redenerenoverliteratuur.nl
            </a>
          </li>
          <!-- <li>
            <a href="tel:+31 40 400 6973" class="btn-footer">
              +31 40 400 6973
            </a>
          </li> -->
          <!-- <li>
            <a
              href="https://g.page/codeskillz?share"
              target="_blank"
              rel="noreferrer"
              class="btn-footer"
            >
              Kastanjelaan 400, 5616LZ Eindhoven
              
            </a>
          </li> -->
        </ul>
      </div>
    </div>

    <!-- <SocialFooter /> -->

    <div class="copyright">© {{ year }}. Versie {{ version }}</div>
  </footer>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SocialFooter from "@/core/components/SocialFooter.vue";
import environment from "@/environment";
import { Getter } from "vuex-class";

@Component({
  components: {
    SocialFooter,
  },
})
export default class Footer extends Vue {
  @Getter("isAuthenticated", { namespace: "login" })
  private isAuthenticated!: boolean;

  private get year() {
    const start = "2021";
    const yearNow = new Date().getFullYear().toString();
    if (start === yearNow) {
      return start;
    }
    return start + " - " + yearNow;
  }

  private get version() {
    return environment.version + " — " + process.env?.VUE_APP_SHA;
  }
}
</script>

<style scoped lang="scss">
.footer-main {
  display: grid;
  grid-template-areas:
    "ondersteuning"
    "informatie"
    "meer"
    "title";
  border-top: 1px solid #dbdbdb;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding: 0 0.4rem;
  font-size: 1.125rem;
  @media screen and (min-width: $tablet) {
    grid-template-areas:
      "ondersteuning informatie"
      "meer meer"
      "title title";
    padding: 0 1rem;
  }
  @media screen and (min-width: $desktop) {
    grid-template-areas: "ondersteuning informatie meer title";
    padding: 0 3rem;
  }
  @media screen and (min-width: $widescreen) {
    padding: 0 5rem;
  }
  @media screen and (min-width: $fullhd) {
    padding: 0 7.5rem;
  }
}

.footer-item,
.footer-item-2 {
  padding: 0.6rem;
  min-width: 200px;
  max-width: 100%;
}

.footer-item-2 {
  text-align: right;
  grid-area: "title";
}

@media only screen and (max-width: $mobile) {
  .footer-item-2 {
    text-align: left;
    flex-grow: 1;
  }
}

.btn-footer {
  color: $grey-light;
}
.btn-footer:hover {
  color: $grey;
}

.copyright {
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: $grey;
  text-align: center;
}
</style>
