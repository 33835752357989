<template>
  <PageLayout>
    <h1 class="title has-text-centered">
      <small class="upper">Voordat je verdergaat met de opdracht</small>
      Er zijn vragen van medeleerlingen
    </h1>
    <p class="explination">
      Als jij een helpend en inhoudelijk antwoord geeft aan een medeleerling,
      kan je daarmee 5 punten verdienen. Dat kan maximaal 2 keer per level.
    </p>
    <p class="explination">
      Ga je liever gelijk verder, klik dan op de knop hieronder:
    </p>
    <b-button
      type="is-primary"
      class="continue-btn"
      outlined
      rounded
      icon-right="chevron-right"
      @click="continueStep()"
    >
      Doorgaan met het level
    </b-button>

    <div class="divider" />

    <div class="wrapper">
      <h2 class="title has-text-centered">Vragen & antwoorden</h2>
      <StudentQuestionCard
        v-for="(question, i) in questions"
        :key="question?.id ? question.id : 'all-' + i"
        :question="question"
        class="question-card"
        answerForm
      />
    </div>

    <div class="divider" />

    <b-button
      type="is-primary"
      class="continue-btn"
      rounded
      icon-right="chevron-right"
      @click="continueStep()"
    >
      Doorgaan met het level
    </b-button>
  </PageLayout>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { lessonApi } from "../api/lesson.api";
import PageLayout from "../layout/PageLayout.vue";
import { Lesson } from "../lesson.types";
import { StudentQuestion } from "../studentQuestion.types";
import StudentQuestionCard from "./StudentQuestionCard.vue";

@Component({
  components: {
    PageLayout,
    StudentQuestionCard,
  },
})
export default class AnswerStudentQuestionPage extends Vue {
  @Getter("lesson", { namespace: "lesson" })
  private lesson!: Lesson;
  private questions: (StudentQuestion | null)[] = [null, null, null];

  private continueStep() {
    this.$emit("continue");
  }

  private async mounted() {
    await this.loadQuestions();
  }

  private async loadQuestions() {
    try {
      this.questions = await lessonApi.getStudentQuestions(
        this.lesson.id,
        false
      );
    } catch (error) {
      this.$buefy.notification.open({
        indefinite: true,
        message: `Er ging helaas wat mis. Je kan nu even geen vragen beantwoorden.`,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
      this.continueStep();
    }
  }
}
</script>

<style scoped lang="scss">
.upper {
  display: block;
  margin-top: 2rem;
  margin-bottom: 8px;
  font-size: 0.56em;
  text-transform: uppercase;
  color: $secondary;
  letter-spacing: 0.215rem;
  transform: scaleY(0.925);
}
.explination {
  font-family: $header-family;
  padding: 0 1.5rem;
  &:first-of-type {
    margin-top: 3.5rem;
  }
}
.divider {
  height: 1px;
  width: 100%;
  margin: 3rem 0;
  background: $secondary-dark;
}
.continue-btn {
  display: block;
  margin: 2rem auto;
}
.wrapper {
  max-width: 720px;
  margin: 2rem auto;
  .question-card {
    padding: 1rem 1.5rem;
    background: #ffffff75;
    border-radius: 1rem;
    border-top: none;
    + .question-card {
      margin-top: 2.5rem;
    }
  }
}
</style>
