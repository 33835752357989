import { QuestionBlock, QuestionBlockType } from "../block.types";
import { LessonStep, LessonStepType } from "../lesson.types";
import { state } from "@/lesson/store/lesson.state";

export function stepIsComplete(step: LessonStep): boolean {
  if (step.type === LessonStepType.QUIZ) {
    const allQuestions = step.blocks.filter((block) =>
      Object.values(QuestionBlockType).includes(
        block.block_type as QuestionBlockType
      )
    ) as QuestionBlock[];
    const questionsDone = allQuestions.filter((block) => block.complete);
    if (questionsDone.length === allQuestions.length) {
      return true;
    }
    const questionsCorrect = questionsDone.filter((block) => block.latest_answer?.correct);
    return Math.ceil(questionsCorrect.length / allQuestions.length * 100) > 66;
  }
  if (state.blocksInEdit.length > 0) {
    if (state.blocksInEdit.some((eId) => step.blocks.some((b) => b.id === eId))) {
      // if step contains block which is in edit
      return false;
    }
  }
  return !step.blocks.some(
    (block) =>
      !block.complete &&
      Object.values(QuestionBlockType).includes(
        block.block_type as QuestionBlockType
      )
  );
}