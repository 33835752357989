import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "@/store/store.types";
import { loginStore } from "@/login/store/login.state";
import { courseStore } from "@/course/store/course.state";
import { lessonStore } from "@/lesson/store/lesson.state";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {},
  mutations: {},
  actions: {},
  modules: {
    login: loginStore,
    course: courseStore,
    lesson: lessonStore,
  },
};

const storeInstance = new Vuex.Store<RootState>(store);
export default storeInstance;