<template>
  <NewPassword :succesMessage="'Gelukt! Je kan nu direct inloggen.'">
    <template #title>Bijna klaar!</template>
    <template #subtitle>
      <p>Nog een dingetje voor dat jij kan gaan rondkijken.</p>
      <p>
        Kies hier een wachtwoord. Zorg dat je wachtwoord uit minimaal 12 tekens
        bestaat.
      </p>
    </template>
    <template #btn>
      Maak account aan
    </template>
  </NewPassword>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import NewPassword from "@/login/views/NewPassword.vue";

@Component({
  components: {
    NewPassword,
  },
})
export default class NewAccountFromRequest extends Vue {
 
}
</script>

<style scoped lang="scss">
</style>
