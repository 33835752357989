<template>
  <ArticleLayout>
    <template #header>
      <div class="header">
        <div>
          <h1>Onderzoek</h1>
        </div>
      </div>
    </template>
    <ImageWithText
      :image="require(`@/assets/images/renate.webp`)"
      alt="Renate van Keulen"
      portrait
    >
      <p>
        <strong>Renate van Keulen</strong> is docent Nederlands op het Van
        Maerlantlyceum in Eindhoven en docent vakdidactiek/lerarenopleider aan
        de Radboud Docenten Academie. Ze deed promotieonderzoek aan Tilburg
        University op het gebied van didactiek voor het
        literatuur(geschiedenis)onderwijs. Tijdens haar onderzoek ontwierp ze
        een digitale gamificationdidactiek waarmee leerlingen leren redeneren
        over historische literatuur. Naast haar werk als docent Nederlands,
        lerarenopleider en onderzoeker is Renate van Keulen coördinator van de
        redactie letterkunde van het Vakdidactisch handboek van WODN (Werkgroep
        Onderzoek en Didactiek Nederlands).
      </p>
      <!-- <p>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          href="mailto:r.vankeulen@tilburguniversity.edu"
          icon-left="email-outline"
        >
          r.vankeulen@tilburguniversity.edu
        </b-button>
      </p> -->
    </ImageWithText>
    <section class="downloads" style="align-items: center">
      <a
        href="https://www.publicatie-online.nl/uploaded/flipbook/173327-Renate-van-Keulen/"
        class="fbp-embed feature-image"
        data-fbp-thumbnail="https://www.publicatie-online.nl/uploaded/flipbook/173327-Renate-van-Keulen/files/assets/cover300.jpg?uni=f0c8fd02118f0293c90cd30a2520c9fb"
        data-fbp-lightbox="yes"
        data-fbp-width="360px"
        data-fbp-height="315px"
        data-fbp-method="site"
        data-fbp-version="4.9.2"
        style="max-width: 100%"
      >
        Renate van Keulen
      </a>
      <script
        type="application/javascript"
        async
        defer
        src="https://www.publicatie-online.nl/uploaded/flipbook/173327-Renate-van-Keulen/files/html/static/embed.js?uni=f0c8fd02118f0293c90cd30a2520c9fb"
      />
      <div class="downloads-wrapper">
        <div>
          <h2>Bekijk het proefschrift</h2>
          <h3 class="subtitle">Klik de cover of deze link:</h3>
        </div>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          href="https://www.publicatie-online.nl/uploaded/flipbook/173327-Renate-van-Keulen/"
          target="_blank"
          icon-left="open-in-new"
        >
          Spelen met de waarheid (publicatie-online.nl)
        </b-button>
      </div>
    </section>

    <section>
      <h2>Spelen met de waarheid</h2>
      <h4>
        Literatuurhistorisch leren redeneren met een digitale game over De
        donkere kamer van Damokles
      </h4>
      <p>
        Onderwijs in historische literatuur positief kan bijdragen aan het
        historisch en cultureel besef, het sociaal inzicht en de taalvaardigheid
        van leerlingen. De kansen die dit onderwijs biedt, worden in de praktijk
        echter niet altijd benut. Tijdens mijn promotieonderzoek ontwikkelde ik
        een digitale game die leerlingen leert redeneren over historische
        literatuur. Op basis van diverse vooronderzoeken ontwierp ik een
        redeneerdidactiek die ik eerst analoog toepaste op de roman De donkere
        kamer van Damokles en vervolgens steeds verder digitaliseerde en
        gamificeerde. Uit het onderzoek bleek dat de didactiek van
        literatuurhistorisch redeneren zowel in analoge als digitale vorm
        geschikt is om leerlingen in de bovenbouw van het vwo te leren nadenken
        over en met historische literatuur. Ook werd duidelijk dat de
        ontwikkelde redeneeropdrachten de leerlingen die hiertoe in staat zijn
        uitnodigen tot redeneren op een hoog niveau. De opdrachten dagen de
        leerlingen met andere woorden uit om hun literaire capaciteiten te
        benutten. De digitalisering en gamificering van het ontwerp hebben niet
        geleid tot een hogere motivatie. Een uitgebreidere uitleg over het
        onderzoek vind je hier:
      </p>
      <p>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="require(`@/assets/pdfs/Informatie.onderzoek.pdf`)"
          icon-left="download"
        >
          Informatie onderzoek
        </b-button>
      </p>
    </section>

    <section class="downloads" v-if="canSeeFiles">
      <b-image
        class="feature-image"
        :src="require(`@/assets/images/42.jpg`)"
        :alt="'test'"
        lazy
      />
      <div class="downloads-wrapper">
        <h2>Lessenreeksen onderzoek</h2>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="require(`@/assets/pdfs/Lessenreeks 1.pdf`)"
          icon-left="download"
        >
          Lessenreeks 1
        </b-button>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="require(`@/assets/pdfs/Lessenreeks 2.pdf`)"
          icon-left="download"
        >
          Lessenreeks 2
        </b-button>
        <b-button
          type="is-primary"
          class="download-btn"
          outlined
          rounded
          tag="a"
          :href="require(`@/assets/pdfs/Lessenreeks 3.pdf`)"
          icon-left="download"
        >
          Lessenreeks 3
        </b-button>
      </div>
    </section>
    <section>
      <h2>Publicaties</h2>
      <p>
        Janssen, N. & Van Keulen, R. (2023). Redeneren en inzichten verwerven
        over literatuur.
        <em>
          In WODN Werkgroep Onderzoek Didactiek Nederlands (Ed.), Handboek
          Didactiek Nederlands. Levende Talen. Geraadpleegd via
        </em>
        <a
          href="https://didactieknederlands.nl/handboek/2023/05/redeneren-en-inzichten-verwerven-over-literatuur/"
          target="_blank"
        >
          Redeneren en inzichten verwerven over literatuur – Didactiek
          Nederlands
        </a>
      </p>
      <p>
        Van Keulen, R. & Doreleijers, K. (2023). Daar zou ik zonder dit project
        nooit bij stilstaan. Integratie van vaardigheden en taalkundige
        vakinhoud in de lessen Nederlands.
        <em>
          In Neerlandistiek. Online tijdschrift voor taal- en letterkunde.
          Geraadpleegd via
        </em>
        <a
          href="https://neerlandistiek.nl/2023/04/daar-zou-ik-zonder-dit-project-niet-bij-stilstaan/"
          target="_blank"
        >
          “Daar zou ik zonder dit project niet bij stilstaan”
          (neerlandistiek.nl)
        </a>
      </p>
      <p>
        Van Keulen, R. (2023). Proza in de klas. Op literaire teksten
        reflecteren met leerlingen.
        <em>
          In: J. Dera, J. Gubbels, J. van der Loo & J. van Rijt, Vaardig met
          vakinhoud. Handboek vakdidactiek Nederlands
        </em>
        (p. 299-310). Bussum: Couthino.
      </p>
      <p>
        Bax, S. & Van Keulen, R. (2022). Redeneren met en over literatuur.
        <em> In: Bundel HSN </em>
        nr.35 (p 343-346). Den Haag: Taalunie.

        <a
          href="https://hsnbundels.taalunie.org/bijdrage/redeneren-met-en-over-literatuur/"
          target="_blank"
        >
          Redeneren met en over literatuur - Taalunie HSN-archief
        </a>
      </p>
      <p>
        Keulen, R. van (2021). Theorie en praktijk van historisch
        literatuuronderwijs: een vignettenonderzoek.
        <em> Pedagogische Studiën, 98</em>(1), 6-26.
      </p>

      <p>
        Van Keulen, R., & Bax, S. (2021). Literatuurhistorisch redeneren: hoe
        leerlingen met redeneerdidactiek literatuurhistorische inzichten kunnen
        verwerven.
        <em>
          Kunstzone, Tijdschrift voor Kunst en Cultuur in het onderwijs, 4,
        </em>
        16-18.
      </p>
    </section>
    <ContactForm />
  </ArticleLayout>
</template>

<script lang="ts">
import ContactForm from "@/core/components/ContactForm.vue";
import ImageWithText from "@/core/components/ImageWithText.vue";
import ArticleLayout from "@/core/layout/ArticleLayout.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  components: {
    ArticleLayout,
    ImageWithText,
    ContactForm,
  },
})
export default class Research extends Vue {
  @Getter("isAuthenticated", { namespace: "login" })
  private isAuthenticated!: boolean;
  @Getter("isTeacher", { namespace: "login" })
  private isTeacher!: boolean;
  @Getter("isAdmin", { namespace: "login" })
  private isAdmin!: boolean;

  private get canSeeFiles() {
    return this.isAuthenticated && (this.isTeacher || this.isAdmin);
  }
}
</script>


<style scoped lang="scss">
.vdivider {
  @media screen and (max-width: $desktop) {
    display: none;
  }
}
.header {
  max-width: $widescreen + 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  justify-items: center;
  margin: 0 auto;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }
  iframe {
    max-width: 100%;
    border-radius: 8px;
  }
}
.long-cta {
  background: $primary-soft;
  padding: 0.75rem;
  text-align: center;
  border-radius: 12px;
  :deep {
    a.button {
      height: max-content;
    }
    span {
      font-size: 1.075rem;
      font-weight: 600;
      white-space: break-spaces;
    }
  }
}
.download-btn {
  justify-content: flex-start;
  height: max-content;
  border-radius: 1.5rem;
  :deep span:not(.icon) {
    white-space: normal;
    text-align: left;
    @media screen and (max-width: $tablet) {
      font-size: 0.875rem;
    }
  }
}

.downloads {
  background-color: $secondary-soft;
  border-radius: 1rem;
  padding: 2rem 2rem;
  display: grid;
  grid-template-columns: minmax(320px, 1fr) 3fr;
  gap: 2rem;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }

  .feature-image {
    max-width: 560px;
    border-radius: 1rem;
    overflow: hidden;
    margin: auto;
    display: flex;
    &.fbp-embed {
      + div {
        margin: auto;
      }
    }
  }
  .downloads-wrapper {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: $tablet) {
      max-width: max-content;
    }
    .subtitle {
      color: $secondary;
    }
    .download-btn {
      justify-content: flex-start;
      height: max-content;
      border-radius: 1.5rem;
      :deep span:not(.icon) {
        white-space: normal;
        text-align: left;
        @media screen and (max-width: $tablet) {
          font-size: 0.875rem;
        }
      }
    }
  }
}
</style>
