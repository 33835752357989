<template>
  <b-tooltip label="Jouw verdiende punten" position="is-bottom" class="tooltip">
    <transition name="pulse" mode="out-in">
      <div class="wrapper" :key="score">
        <span class="btn-text">{{ score }}</span>
        <b-icon icon="medal" />
      </div>
    </transition>
  </b-tooltip>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class ScoreNavItem extends Vue {
  @Getter("score", { namespace: "login" })
  private score!: number;
}
</script>

<style scoped lang="scss">
.tooltip {
  height: 100%;
}
.wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 100%;
  color: $primary;
  font-family: $headering-font-family;
}

.wrapper::after {
  content: "";
  position: absolute;
  background: $primary;
  width: 100%;
  inset: 0;
  margin: auto;
  aspect-ratio: 1 / 1;
  z-index: -1;
  border-radius: 50%;
  transform: scale(1.2);
  opacity: 0;
}

.pulse-enter-active {
  $duration: 1s;
  $delay: 0.25s;
  animation: pulse-in $duration $delay;
  &::after {
    animation: ping $duration $delay;
  }
}
@keyframes pulse-in {
  0% {
    transform: scale(1);
    color: $primary;
  }
  10% {
    transform: scale(1.1);
    color: $primary;
  }
  66% {
    transform: scale(2.1);
    color: $secondary-light;
  }
  75% {
    transform: scale(2.1);
    color: $primary;
  }
  100% {
    transform: scale(1);
    color: $primary;
  }
}
@keyframes ping {
  0% {
    transform: scale(0.2);
    opacity: 0.8;
  }
  66% {
    transform: scale(1.2);
    opacity: 0.33;
  }
  75% {
    transform: scale(1.2);
    opacity: 0.33;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
</style>
