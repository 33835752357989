<template>
  <div class="flip-book" id="flip-book">
    <div class="page page-cover page-cover-top" data-density="hard">
      <div class="page-content">
        <h2>{{ course.name }}</h2>
      </div>
    </div>
    <BookLevelPage :lesson="introVideo" introVideo />
    <BookLevelPage
      v-for="lesson in course.lessons"
      :key="lesson.id"
      :lesson="lesson"
    />
    <div class="page">
      <div class="page-content">
        <div class="page-text">Door Renate</div>
        <div class="page-footer">{{ lastPage }}</div>
      </div>
    </div>
    <div class="page page-cover">
      <div class="page-content"></div>
    </div>

    <div class="page page-cover page-cover-bottom" data-density="hard">
      <div class="page-content">
        <h2>Einde</h2>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import MainLayout from "@/core/layout/MainLayout.vue";
import Navbar from "@/core/components/Navbar.vue";
import BookLevelPage from "@/course/components/BookLevelPage.vue";
import { PageFlip } from "page-flip";
import { Course, SizeType } from "../course.types";
import { Getter } from "vuex-class";
import { LessonBase, LessonStatus } from "@/lesson/lesson.types";

@Component({
  components: {
    MainLayout,
    Navbar,
    BookLevelPage,
  },
})
export default class BookTest extends Vue {
  @Getter("course", { namespace: "course" })
  private course!: Course;

  private get introVideo(): LessonBase | null {
    if (this.course.intro_video_id) {
      return {
        id: "-1",
        name: "Over deze game",
        order_no: 0,
        status: this.course.user_has_started
          ? LessonStatus.FINISHED
          : LessonStatus.OPEN,
      };
    }
    return null;
  }

  private flipSpeed = 800;

  private get lastPage() {
    // each level has 2 pages, plus 1 introvideo page, plus 1 this last page.
    return this.course.lessons.length * 2 + 1 + 1;
  }

  private get firstOpenLesson() {
    // Check if course has a prologue video, and if the user has started the course (seen the video)
    if (!!this.course.intro_video_id && !this.course.user_has_started) {
      return 0;
    }
    for (const lesson of this.course.lessons) {
      if (
        lesson.status === LessonStatus.OPEN ||
        lesson.status === LessonStatus.IN_PROGRESS
      ) {
        return lesson.order_no;
      }
    }
    return this.course.lessons.length;
  }

  private initializeBook() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pageFlip = new PageFlip(document.getElementById("flip-book")!, {
      width: 300, // required parameter - base page width
      height: 360, // required parameter - base page height

      size: SizeType.STRETCH,
      // set threshold values:
      minWidth: 150,
      maxWidth: 420,
      minHeight: 180,
      maxHeight: 504,

      flippingTime: this.flipSpeed,
      usePortrait: false,
      maxShadowOpacity: 0.5, // Half shadow intensity
      showCover: true,
      disableFlipByClick: true,
    });

    pageFlip.loadFromHTML(document.querySelectorAll(".page"));

    for (let i = 0; i <= this.firstOpenLesson; i++) {
      this.delay(600 + this.flipSpeed * i).then(() => {
        setTimeout(() => {
          pageFlip.flip(i * 2 + 1);
        }, 1500 * 1);
      });
    }
  }

  private delay(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  private mounted() {
    this.initializeBook();
  }
  private updated() {
    this.initializeBook();
  }
}
</script>

<style lang="scss">
#flip-book {
  display: none;
  background-size: cover;
  margin: 0 auto;

  &.stf__parent {
    transform: unset;
  }

  .page {
    padding: 1.5rem;
    @media screen and (max-width: $desktop) {
      padding: 1rem;
    }
    @media screen and (max-width: $tablet) {
      padding: 0.625rem;
    }

    background-color: hsl(35 55% 98%);
    color: hsl(35 35% 35%);
    border: solid 1px hsl(35 20% 70%);

    overflow: hidden;

    .page-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;

      .page-header {
        text-transform: uppercase;
        text-align: center;
        &.level {
          margin: 1rem auto;
          transition: margin 1s;
          @media screen and (max-width: $tablet) {
            margin: 0 auto 0.375rem auto;
          }
        }
      }

      .page-image {
        flex-grow: 1;
        margin-top: 15%;
        img {
          border-radius: 0.625rem;
          cursor: pointer;
        }
      }

      .page-text {
        flex-grow: 1;
        font-size: 80%;
        text-align: justify;
        margin: 10% 0 20% 0;
        padding-top: 1rem;
        box-sizing: border-box;
        border-top: solid 1px hsl(35 55% 90%);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        transition: margin 1s;

        @media screen and (max-width: $tablet) {
          margin: 3% 0 3% 0;
          padding-top: 0.5rem;
        }

        .button {
          min-width: max-content;
          width: 160px;
          max-width: 100%;
          transition: font-size 1s;
          @media screen and (min-width: $desktop) {
            min-width: 200px;
            width: max-content;
          }
          @media screen and (min-width: $widescreen) {
            min-width: 240px;
          }
          @media screen and (max-width: $tablet) {
            min-width: max-content;
            width: 120px;
            padding-left: 0.875em;
            padding-right: 0.875em;
            font-size: 0.875rem;
          }
        }
      }

      .page-footer {
        height: 30px;
        border-top: solid 1px hsl(35 55% 90%);
        font-size: 80%;
        color: hsl(35 20% 50%);
      }
    }

    &.--left {
      // for left page (property will be added automatically)
      border-right: 0;
      box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
      border-radius: 4px 0 0 4px;
    }

    &.--right {
      // for right page (property will be added automatically)
      border-left: 0;
      box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
      border-radius: 0 4px 4px 0;

      .page-footer {
        text-align: right;
      }
    }

    &.hard {
      // for hard page
      background-color: hsl(35 50% 90%);
      border: solid 1px hsl(35 20% 50%);
    }

    &.page-cover {
      background-color: hsl(35 45% 80%);
      color: hsl(35 35% 35%);
      border: solid 1px hsl(35 20% 50%);

      h2 {
        text-align: center;
      }

      &.page-cover-top {
        box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
          -2px 0 5px 2px rgba(0, 0, 0, 0.4);
      }

      // &.page-cover-bottom {
      //   box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
      //     10px 0 8px 0px rgba(0, 0, 0, 0.4);
      // }
    }
  }
}
</style>
