import { AxiosResponse } from "axios";
import { ApiClient } from "@/core/api/api-client.class";
import { LoginPost, NewAccountDetailsPost, NewAccountDetailsRequestPost, NewPasswordPost, RegistrationStatus, ResetPasswordPost, User } from "@/login/login.types";

const client = new ApiClient();

export const loginApi = {
  async login(data: LoginPost): Promise<any> {
    try {
      const response = (await client.post('/login', data)) as AxiosResponse<any>;
      return response;
    } catch (err) {
      throw err;
    }
  },
  async logout(): Promise<any> {
    try {
      const response = (await client.post('/logout')) as AxiosResponse<any>;
      return response;
    } catch (err) {
      throw err;
    }
  },
  me(): Promise<AxiosResponse<{ message: string, user: User }>> {
    return client.get('/me') as Promise<AxiosResponse<{ message: string, user: User }>>;
  },
  async registrationStatus(): Promise<RegistrationStatus> {
    try {
      const response = (await client.get('/registration-status')) as AxiosResponse<any>;
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  async register(data: NewAccountDetailsPost): Promise<any> {
    try {
      const response = (await client.post('/register', data)) as AxiosResponse<any>;
      return response;
    } catch (err) {
      throw err;
    }
  },
  async requestAccount(data: NewAccountDetailsRequestPost): Promise<any> {
    try {
      const response = (await client.post('/request-account', data)) as AxiosResponse<any>;
      return response;
    } catch (err) {
      throw err;
    }
  },
  async resetPassword(data: ResetPasswordPost): Promise<any> {
    try {
      const response = (await client.post('/reset-password', data)) as AxiosResponse<any>;
      return response;
    } catch (err) {
      throw err;
    }
  },
  async newPassword(data: NewPasswordPost): Promise<any> {
    try {
      const response = (await client.post('/new-password', data)) as AxiosResponse<any>;
      return response;
    } catch (err) {
      throw err;
    }
  },
};
