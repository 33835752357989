'use strict';

import { MutationTree } from 'vuex';
import { CourseState, Course } from '@/course/course.types';
import Vue from 'vue';

export const mutations: MutationTree<CourseState> = {
  setCourse(state: CourseState, course: Course) {
    Vue.set(state, 'course', course);
  },
  removeCourse(state: CourseState) {
    Vue.set(state, 'course', undefined);
  },
};
