import store from '@/store';
import { Route } from 'vue-router';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function courseGuard(to: Route, from: Route, next: (param?: any) => void): Promise<void> {
  let id = 1;
  if (to.params.courseId) {
    id = +to.params.courseId;
  }
  await store.dispatch('course/LOAD_COURSE', id);
  next();
}