import { ColorModifiers } from "buefy/types/helpers";

export interface LoginState {
  user?: User;
  accessToken?: string;
  lockAccountRegistration: boolean;
  pct?: string;
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  created_at: string;
  type: UserableType;
  score: number;
}

export enum UserableType {
  TEACHER = 'Teacher',
  STUDENT = 'Student',
  ADMIN = 'Admin',
}

export interface LoginPost {
  email: string,
  password: string,
}
export interface LoginResponse {
  message: string;
  token: string;
}
export interface TokenPayload {
  auth: {
    iss: string,
    sha: string,
    sub: number,
    exp: number,
    nbf: number,
    iat: number,
    idf: string,
  },
  usr: User,
  pct: string,
}

export interface RegistrationStatus {
  lock_account_registration: boolean;
}

export interface NewAccountDetailsPost {
  first_name: string,
  last_name: string,
  email: string,
  password: string,
  password_confirmation: string,
}

export interface NewAccountDetailsRequestPost {
  first_name: string,
  last_name: string,
  email: string,
  message: string,
}
export interface ResetPasswordPost {
  email: string,
}
export interface NewPasswordPost {
  token: string,
  password: string,
  password_confirmation: string,
}

export interface UpdateUserScoreNotification {
  message: string;
  type: ColorModifiers;
  score: number;
  collected_score: number;
}