import store from '@/store';
import { Route } from 'vue-router';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function loginGuard(to: Route, from: Route, next: (param?: any) => void): Promise<void> {
  await store.dispatch('login/LOAD_REGISTRATION_STATUS');
  if (store.getters['login/isAuthenticated']) {
    next({
      name: 'Course',
    });
  } else {
    next();
  }
}