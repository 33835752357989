<template>
  <BlockBase
    :block="block"
    :checked="answerChecked"
    :edited="isEditedAfterComplete"
    :answer="answer"
    :answerBtnHint="answerBtnHint"
    :ttsText="ttsText"
    @answerCorrectResponse="setCorrectAnswer"
  >
    <template v-slot="{ isEnabled, isComingUp, isDoneTrying }">
      <p class="explainer">{{ explainer }}</p>
      <b-field class="choice-items" :class="`choice-items--${block.layout}`">
        <b-radio-button
          v-for="(item, i) in block.choice_items"
          :key="item.key"
          v-model="answerKey"
          :native-value="item.key"
          :disabled="!isEnabled || isComingUp || isDoneTrying"
          :type="getType(item.key)"
          :class="{ 'is-success': isCorrect(item.key) }"
        >
          <span class="radio-letter">{{ String.fromCharCode(65 + i) }}.</span>
          <span>{{ item.text }}</span>
        </b-radio-button>
      </b-field>
    </template>
  </BlockBase>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  AnswerReply,
  ChoiceItem,
  MultipleChoiceBlock,
} from "@/lesson/block.types";
import BlockBase from "@/lesson/components/BlockBase.vue";

@Component({
  components: {
    BlockBase,
  },
})
export default class MultipleChoiceBlockComponent extends Vue {
  @Prop() private block!: MultipleChoiceBlock;

  private explainer = "Kies uit één van de volgende opties: ";

  private get ttsText() {
    return `
            ${this.block.content}.\n 
            ${this.block.question}.\n 
            ${this.explainer} \n
            ${
              this.block.choice_items
                ? this.block.choice_items
                    .map((item, i) => {
                      return (
                        "Optie " +
                        String.fromCharCode(65 + i) +
                        ". " +
                        item.text
                      );
                    })
                    .join(". ")
                : ""
            }.\n 
            Jouw gekozen antwoord is: \n
            ${this.answer ? this.answer.text : "Nog geen gekozen"}.\n
          `;
  }

  private get answer() {
    if (this.block.choice_items) {
      return this.block.choice_items.find(
        (item) => item.key === this.answerKey
      );
    }
    return undefined;
  }

  private answerKey = "";

  private beforeMount() {
    if (this.block.latest_answer) {
      this.answerKey = (this.block.latest_answer.answer as ChoiceItem).key;
      if (this.block.correct_answers) {
        this.correctKeys = (this.block.correct_answers as ChoiceItem[]).map(
          (item) => item.key
        );
      }
    }
  }

  private get isEditedAfterComplete() {
    if (
      this.answerKey !==
      (this.block.latest_answer?.answer as ChoiceItem | undefined)?.key
    ) {
      return true;
    }
    return false;
  }

  private get answerChecked() {
    if (this.answerKey === "") {
      return false;
    }
    if (!this.answer) {
      return false;
    }
    return true;
  }

  private get answerBtnHint() {
    if (!this.answerChecked) {
      return "Selecteer een antwoord";
    }
    return "";
  }

  private isCorrect(key: string) {
    if (this.correctKeys === null) {
      return false;
    }
    return this.correctKeys.includes(key);
  }

  private getType(key: string) {
    if (this.correctKeys === null && !this.block.complete) {
      return "is-primary";
    }
    const keyACorrectKey = this.isCorrect(key);
    if (keyACorrectKey) {
      return "is-success";
    }
    if (
      this.correctKeys !== undefined &&
      !keyACorrectKey &&
      key === this.answerKey
    ) {
      return "is-danger";
    }
    if (!this.isEditedAfterComplete && key === this.answerKey) {
      return "is-danger";
    }
    return "is-primary";
  }

  private correctKeys: string[] | null = null;
  private setCorrectAnswer(response: AnswerReply) {
    if (response.correct_answer) {
      this.correctKeys = (response.correct_answer as ChoiceItem[]).map(
        (item) => item.key
      );
    }
  }
}
</script>

<style scoped lang="scss">
.explainer {
  font-style: italic;
  margin-bottom: 4px;
}
.choice-items {
  min-width: 33%;
  max-width: 100%;
  width: fit-content;

  &:deep .field-body .field {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.625rem;
    .b-radio {
      justify-content: flex-start;
      border-radius: 6px !important;
      white-space: normal;
      text-align: start;
      height: max-content;

      &.radio[disabled].is-selected,
      &.radio[disabled].is-success {
        opacity: 1;
        color: #fff;
      }

      .radio-letter {
        margin-right: 12px;
        font-weight: 600;
      }
    }
    .control.is-success .b-radio {
      background-color: hsl(153, 53%, 53%);
      border-color: hsl(153, 53%, 53%);
      box-shadow: none;
      opacity: 1;
      color: #fff;
    }
  }
  &--row {
    width: 100%;
    &:deep .field-body .field {
      grid-template-columns: repeat(auto-fill, minmax(206px, 1fr));
      gap: 1rem;
      .b-radio {
        min-height: 2em;
        height: 100%;
      }
    }
  }
}
</style>
