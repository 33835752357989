<template>
  <div>
    <transition name="fade">
      <div class="overlay" v-if="open" @click="setOpen(false)" />
    </transition>
    <aside :class="{ 'sidebar-open': open }">
      <div class="sidebar-container">
        <div class="header">
          <b-button rounded icon-left="close" @click="setOpen(false)" />
        </div>
        <b-tabs
          expanded
          class="previous-answer-container"
          @input="tabsChange"
          v-model="currentTab"
        >
          <b-tab-item value="all">
            <template #header>
              <span class="tab-label">
                Vragen van klasgenoten
                <transition name="fade">
                  <b-tag rounded v-if="loadedOnceAll">
                    {{ questions_all.length }}
                  </b-tag>
                </transition>
              </span>
            </template>
            <div class="tab-container">
              <StudentQuestionCard
                v-for="(question, i) in questions_all"
                :key="question?.id ? question.id : 'all-' + i"
                :question="question"
              />
            </div>
          </b-tab-item>
          <b-tab-item value="own">
            <template #header>
              <span class="tab-label">
                Jouw vragen
                <transition name="fade">
                  <b-tag rounded v-if="loadedOnceOwn">
                    {{ questions_own.length }}
                  </b-tag>
                </transition>
              </span>
            </template>
            <div class="tab-container">
              <StudentQuestionCard
                v-for="(question, i) in questions_own"
                :key="question ? question.id : 'own-' + i"
                :question="question"
                ref="questionOwn"
              />
            </div>
          </b-tab-item>
        </b-tabs>
        <form class="question-container" onsubmit="return false">
          <!-- TODO make max amount question dynamic -->
          <b-field label="Wat wil je aan een medeleerling vragen? Let op, je mag maximaal 2 vragen stellen per level">
            <b-input
              v-model="question"
              maxlength="400"
              type="textarea"
              placeholder="Stel hier je vraag"
              :disabled="isSend"
            />
          </b-field>
          <b-button
            :type="isSend ? 'is-success' : 'is-primary'"
            native-type="submit"
            rounded
            @click="postQuestion"
            :disabled="isSend || questionWrong"
            :loading="loading"
            :icon-left="isSend ? 'check' : ''"
          >
            {{ isSend ? "Verstuurd" : "Vraag" }}
          </b-button>
          <b-button
            v-if="isSend"
            type="is-primary"
            rounded
            @click="newQuestion"
          >
            Stel nog een vraag
          </b-button>
          <div class="placeholder-btn" v-else></div>
        </form>
      </div>
    </aside>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { lessonApi } from "../api/lesson.api";
import { Lesson } from "../lesson.types";
import {
  StudentQuestion,
  StudentQuestionAnswerNotification,
  StudentQuestionPost,
} from "../studentQuestion.types";
import StudentQuestionCard from "./StudentQuestionCard.vue";

enum Tabs {
  OWN = "own",
  ALL = "all",
}

@Component({
  components: {
    StudentQuestionCard,
  },
})
export default class StudentQuestionsSidebar extends Vue {
  @Prop({ default: true, type: Boolean }) private open!: boolean;
  @Getter("lesson", { namespace: "lesson" })
  private lesson!: Lesson;

  // Tabs and content
  private currentTab = Tabs.ALL;
  private questions_own: (StudentQuestion | null)[] = [null, null, null];
  private questions_all: (StudentQuestion | null)[] = [null, null, null];
  private loadedOnceOwn = false;
  private loadedOnceAll = false;

  // Forms
  private loading = false;
  private isSend = false;
  private question = "";

  private get questionWrong() {
    if (this.question === "") {
      return true;
    }
    return false;
  }

  private setOpen(open: boolean) {
    this.$emit(open ? "open" : "close");
  }

  private async mounted() {
    this.$root.$on(
      "questionAnswered",
      async (data: StudentQuestionAnswerNotification) => {
        this.setOpen(true);
        this.currentTab = Tabs.OWN;
        await this.loadOwnQuestions();

        setTimeout(() => {
          this.$nextTick(() => {
            (this.$refs.questionOwn as StudentQuestionCard[]).forEach(
              (card) => {
                if (card?.question?.id === data.student_question_id) {
                  card.scroll(data.student_question_answer_id);
                }
              }
            );
          });
        }, 120);
      }
    );
  }

  @Watch("open")
  private onOpenChange() {
    if (this.open) {
      this.loadAllQuestions();
      this.loadOwnQuestions();
    }
  }

  @Watch("currentTab")
  private async tabsChange() {
    switch (this.currentTab) {
      case Tabs.ALL:
        this.loadAllQuestions();
        break;
      case Tabs.OWN:
        this.loadOwnQuestions();
        break;
    }
  }

  private async loadAllQuestions() {
    try {
      this.questions_all = await lessonApi.getStudentQuestions(
        this.lesson.id,
        false
      );
      this.loadedOnceAll = true;
    } catch (error) {
      this.errorNotification("GSQ1A");
    }
  }

  private async loadOwnQuestions() {
    try {
      this.questions_own = await lessonApi.getStudentQuestions(
        this.lesson.id,
        true
      );
      this.loadedOnceOwn = true;
    } catch (error) {
      this.errorNotification("GSQ1O");
    }
  }

  private async postQuestion() {
    this.loading = true;
    const question: StudentQuestionPost = {
      lesson_id: this.lesson.id,
      question_text: this.question,
    };
    try {
      // send to api
      const id = await lessonApi.postStudentQuestion(question);
      this.isSend = true;
      this.currentTab = Tabs.OWN;
      await this.loadOwnQuestions();
      setTimeout(() => {
        this.$nextTick(() => {
          (this.$refs.questionOwn as StudentQuestionCard[]).forEach((card) => {
            if (card?.question?.id === id) {
              card.scroll(id);
            }
          });
        });
      }, 120);
    } catch (error: any) {
      // give error message
      this.errorNotification("PSQ1", error.response.data.message);
    }
    this.loading = false;
  }

  private errorNotification(errorNumber?: string, message = "") {
    if (message === "" || message === "Server Error") {
      message = `Er ging helaas wat mis. Probeer het later nog eens. <br/>Error: <code>${errorNumber}</code>`;
    }
    this.$buefy.notification.open({
      indefinite: true,
      message: message,
      position: "is-bottom-right",
      type: "is-danger",
      hasIcon: true,
    });
  }

  private newQuestion() {
    this.question = "";
    this.isSend = false;
    this.loading = false;
  }
}
</script>

<style scoped lang="scss">
aside {
  background: #fff;
  height: 100svh;
  position: sticky;
  top: 0px;
  width: 0px;
  transition: width 0.65s ease;
  overflow: hidden;
  box-shadow: -3px 6px 6px #00000012;

  &.sidebar-open {
    width: var(--sidebar-width);
  }

  @media screen and (max-width: $desktop) {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    height: 100svh;
    box-shadow: -3px 0 6px #00000036;
  }

  .sidebar-container {
    width: var(--sidebar-width);
    padding: 6px 10px;
    display: grid;
    gap: 8px;
    grid-template-rows: auto 6fr 3fr;
    height: 100%;

    .header {
      display: flex;
      justify-content: flex-end;
    }
    .previous-answer-container {
      display: grid;
      grid-template-rows: auto 1fr;
      overflow: hidden;
      :deep #all-label,
      :deep #own-label {
        @media screen and (max-width: $fullhd) {
          padding: 0.5em 0.875em;
        }
        .tab-label {
          font-size: 0.8rem;
          @media screen and (min-width: $desktop) {
            font-size: 0.85rem;
          }
          @media screen and (min-width: $fullhd) {
            font-size: 0.925rem;
          }
        }
      }
      :deep .tab-content {
        background: $primary-soft;
        padding: 0.6rem 0.75rem;
        border-radius: 0 0 6px 6px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
    .question-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      .placeholder-btn {
        height: 40px;
      }
    }
  }
}
.overlay {
  @media screen and (min-width: $desktop) {
    display: none;
  }
  position: fixed;
  inset: 0;
  background: #00000040;
  height: 100%;
  width: 100%;
  z-index: 98;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.33s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
