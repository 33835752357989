<template>
  <LoginContainer>
    <template>
      <ContentLayout :back="false">
        <template #title>Welkom, </template>
        <template #subtitle>Vul je gegevens in om in te loggen.</template>
        <template>
          <form @submit.prevent="submitLogin()">
            <!-- Email -->
            <b-field
              label="Email"
              :type="!emailError ? '' : 'is-danger'"
              :message="emailMsg"
            >
              <b-input
                icon="email"
                type="username"
                v-model="username"
                @blur="emailCheck()"
                placeholder="leerling@school.nl"
              />
            </b-field>
            <!-- Password -->
            <b-field
              label="Wachtwoord"
              :type="!passwordError ? '' : 'is-danger'"
              :message="passwordMsg"
            >
              <b-input
                icon="lock"
                type="password"
                v-model="password"
                @blur="passwordCheck()"
                placeholder="••••••••"
                password-reveal
              />
            </b-field>
            <!-- Submit -->
            <b-button
              type="is-primary"
              icon-right="chevron-right"
              rounded
              expanded
              native-type="submit"
              :loading="loading"
            >
              Login
            </b-button>
            <b-button
              tag="router-link"
              :to="{ name: 'NewAccount' }"
              type="is-primary is-light"
              rounded
              expanded
            >
              Maak een nieuw account aan
            </b-button>
          </form>
        </template>
        <template #links>
          <router-link :to="{ name: 'ForgotPassword' }">
            Wachtwoord vergeten?
          </router-link>
          <!-- <router-link :to="{ name: 'Contact' }">
            Contact & over ons
          </router-link> -->
        </template>
      </ContentLayout>
    </template>
  </LoginContainer>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LoginContainer from "@/login/layout/LoginContainer.vue";
import ContentLayout from "@/login/layout/ContentLayout.vue";
import { LoginPost } from "@/login/login.types";
import { Action } from "vuex-class";

@Component({
  components: {
    LoginContainer,
    ContentLayout,
  },
})
export default class Login extends Vue {
  @Action("LOGIN", { namespace: "login" })
  private login!: (response: LoginPost) => Promise<void>;

  private username = "";
  private password = "";

  private emailError = false;
  private passwordError = false;

  private emailMsg = "";
  private passwordMsg = "";

  private loading = false;

  private beforeMount() {
    if (typeof this.$route.query.email !== "undefined") {
      this.username = this.$route.query.email.toString();
    }
  }

  private emailCheck() {
    const reEmail = /\S+@\S+\.\S+/;
    if (this.username === "") {
      this.emailError = true;
      this.emailMsg = "Vul hier je (school-)emailadres in.";
      return true;
    } else if (!reEmail.test(this.username)) {
      this.emailError = true;
      this.emailMsg = "Dit is geen geldig emailadres";
      return true;
    } else {
      this.emailError = false;
      this.emailMsg = "";
      return false;
    }
  }

  private passwordCheck() {
    if (this.password === "") {
      this.passwordError = true;
      this.passwordMsg = "Vul je wachtwoord in.";
      return true;
    } else {
      this.passwordError = false;
      this.passwordMsg = "";
      return false;
    }
  }

  private get badInput() {
    let error = false;
    if (this.emailCheck()) {
      error = true;
    }
    if (this.passwordCheck()) {
      error = true;
    }
    return error;
  }

  private async submitLogin() {
    if (!this.badInput) {
      const credentials: LoginPost = {
        email: this.username,
        password: this.password,
      };
      this.loading = true;
      try {
        await this.login(credentials).then(() => {
          if (this.$route.query.redirect) {
            this.$router
              .push({ path: `${this.$route.query.redirect}` })
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              .catch(() => {});
          } else {
            this.$router.push({ name: "Course" });
          }
        });
      } catch (err) {
        this.$buefy.notification.open({
          duration: 9000,
          message: `Er ging helaas wat mis. Probeer het later nog eens of neem contact met ons op.`,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: true,
        });
        // throw err;
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
</style>
