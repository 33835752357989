import { ApiClient } from '@/core/api/api-client.class';
import { AxiosResponse } from 'axios';

const client = new ApiClient();

export const mailApi = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async sendContact(contactInfo: any): Promise<boolean> {
    try {
      await client.post(`/mail/contact`, contactInfo);
      return true;
    } catch (err) {
      // fail
      throw err;
    }
  },
  async getParams(): Promise<string> {
    try {
      const response = (await client.get(`/mail/form-parameters`)) as AxiosResponse<string>;
      return response.data;
    } catch (err) {
      // fail
      throw err;
    }
  },
};
