import { Course } from "@/course/course.types";
import { Block, BlockType, FillBlanksBlock, HintItem, InfoBlock, SomeBlock, SourceBlock, TextBlock } from "../block.types";
import { LessonStep, Lesson, LessonStatus, LessonBase, LessonStepType } from "../lesson.types";

export const hint_items: HintItem[] = [{
  key: "0",
  layout: "item",
  attributes: {
    hint_header: "Header 1",
    hint_text_html: "Text 1",
  }
},
{
  key: "0",
  layout: "item",
  attributes: {
    hint_header: "Header 2",
    hint_text_html: "Text 2",
  }
}];

/*
 *  Blocks
*/
export const sourceBlock: SourceBlock = {
  id: "1",
  order_no: 1,
  block_type: BlockType.SomeBlock,
  content: "<p>Dit is wat tekst bij de vraag.</p>",
  hint_html: "hint",
  hint_items: hint_items,
  complete: true,
  depends_on_other_question: false,
  source: '<b>Bron</b>',
  source_no: '1',
  source_type: 'bron',
};
export const infoBlock: InfoBlock = {
  id: "1",
  order_no: 1,
  block_type: BlockType.SomeBlock,
  content: "<p>Dit is wat tekst bij de vraag.</p>",
  hint_html: "hint",
  hint_items: hint_items,
  complete: true,
  depends_on_other_question: false,
};
export const textBlock: TextBlock = {
  id: "1",
  order_no: 1,
  block_type: BlockType.TextBlock,
  content: "<p>Dit is wat tekst bij de vraag.</p>",
  hint_html: "hint",
  hint_items: hint_items,
  question: "<p>Is dit de vraag?</p>",
  is_example: 0,
  complete: true,
  depends_on_other_question: false,
  prefilled_answer: 'Dit is text wat er al staat.',
  min_length_answer: 200,
  type: 'long',
};
export const fillBlanksBlock: FillBlanksBlock = {
  id: "1",
  order_no: 1,
  block_type: BlockType.FillBlanksBlock,
  content: "<p>Dit is wat tekst bij de vraag.</p>",
  hint_html: "hint",
  hint_items: hint_items,
  is_example: 0,
  question: "<p>Is dit de vraag?</p>",
  complete: true,
  depends_on_other_question: false,
  question_items: [
    {
      key: "0",
      layout: "item",
      attributes: {
        subquestion_text: "",
        prefilled_answer: "Maak deze zin af"
      }
    },
    {
      key: "1",
      layout: "item",
      attributes: {
        subquestion_text: "",
        prefilled_answer: "Maak deze zin af"
      }
    },
    {
      key: "2",
      layout: "item",
      attributes: {
        subquestion_text: "",
        prefilled_answer: "Maak deze zin af"
      }
    }
  ],
};
export const block: SomeBlock = {
  id: "1",
  order_no: 1,
  block_type: BlockType.SomeBlock,
  content: "<p>Dit is wat tekst bij de vraag.</p>",
  hint_html: "hint",
  hint_items: hint_items,
  question: "<p>Is dit de vraag?</p>",
  is_example: 0,
  complete: true,
  depends_on_other_question: false,
};
export const block2: Block = {
  ...block,
  id: "2",
  order_no: 2,
};
export const block3: Block = {
  ...block,
  id: "3",
  order_no: 3,
  complete: false,
};
export const block4: Block = {
  ...block,
  id: "4",
  order_no: 4,
  complete: false,
};

/*
 *  Steps
*/

export const step: LessonStep = {
  id: "1",
  name: "De tekst",
  order_no: 1,
  type: LessonStepType.LINEAR,
  // blocks: [block, block2],
  blocks: [],
}

/*
 *  Lessons
*/
export function lesson(lessonId: string): Lesson {
  return {
    course_id: lessonId,
    id: "",
    name: "Titel van deze les",
    order_no: +lessonId,
    start_page_html: "<h1>error</h1>",
    finish_page_html: "<h1>Gefeliciteerd!</h1>",
    status: LessonStatus.CLOSED,
    steps: [],
    // steps: [
    //   step,
    //   {
    //     ...step,
    //     id: "2",
    //     name: "Eigen inbreng",
    //     order_no: 2,
    //   },
    //   {
    //     ...step,
    //     id: "3",
    //     name: "Bron",
    //     order_no: 3,
    //     blocks: [
    //       block,
    //       block2,
    //       block3,
    //       block4,
    //     ],
    //   },
    //   {
    //     ...step,
    //     id: "4",
    //     name: "Inzichten verwoorden",
    //     order_no: 4,
    //     blocks: [{ ...block, complete: false }, { ...block2, complete: false }],
    //   },
    // ]
  }
};
export const lessonBase: LessonBase = {
  id: "1",
  name: "Titel van deze les",
  order_no: 1,
  status: LessonStatus.CLOSED,
};
export const lessonBases: LessonBase[] = [];
//   {
//     ...lessonBase,
//     order_no: 1,
//     status: LessonStatus.CLOSED,
//   },
//   {
//     ...lessonBase,
//     id: "2",
//     order_no: 2,
//     status: LessonStatus.CLOSED,
//   },
//   {
//     ...lessonBase,
//     id: "3",
//     order_no: 3,
//   },
//   {
//     ...lessonBase,
//     id: "4",
//     order_no: 4,
//   },
//   {
//     ...lessonBase,
//     id: "5",
//     order_no: 5,
//   },
// ];

/*
 *  Courses
*/
export const course: Course = {
  name: "De Donkere kamer van Damokles",
  id: "1",
  image: "https://media.s-bol.com/Y56LAENY3NBK/739x1200.jpg",
  lessons: lessonBases,
  description: 'Korte omschrijving van deze course',
  user_has_started: false,
}