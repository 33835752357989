import { state } from '@/login/store/login.state';
import store from '@/store';
import { Route } from 'vue-router';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function navigationGuard(to: Route, from: Route, next: (param?: any) => void): Promise<void> {
  store.dispatch('login/INIT');

  document.title = to.meta?.title ? to.meta?.title + ' - Redeneren over Literatuur' : 'Redeneren over Literatuur';

  if (to.matched.some((routes) => routes.meta.disableAuthentication)) {
    // Continue if path is not protected by auth
    next();
  } else if (state.accessToken) {
    // user is auth
    next();
  } else {
    // If path is protected and user is not auth
    next({ name: 'Login', query: { redirect: to.fullPath } });
  }
  // const notSupportedRouteName = 'not-supported';
  // const atNotSupported = to.matched.some((route: any) => {
  //   return route.name === notSupportedRouteName;
  // });

  // check for invalid browser
  // const ua = navigator.userAgent;
  // let invalidBrowser = false;

  // if (ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1 || ua.indexOf('Edge/') > -1) {
  //   invalidBrowser = true;
  // }


  // if (invalidBrowser && !atNotSupported) {
  //   console.log('not supported');
  //   next({
  //     name: notSupportedRouteName,
  //     params: {
  //       invalidBrowser,
  //     },
  //   });
  // }
}