<template>
  <div class="tts__large" v-if="!small">
    <b-button
      type="is-primary"
      rounded
      :icon-left="paused ? 'play' : 'pause'"
      @click="pauseSpeech()"
      v-if="speaking"
    >
      <template #icon>
        <span class="mdi mdi-play" v-if="paused"></span>
        <span class="mdi mdi-pause" v-else></span>
      </template>
      {{ ttsControlBtnText }}
    </b-button>
    <b-button
      type="is-primary"
      rounded
      :icon-left="!speaking ? 'play' : 'stop'"
      @click="speak()"
    >
      {{ ttsBtnText }}
    </b-button>
  </div>
  <div class="tts__small" v-else>
    <b-button
      type="is-primary"
      outlined
      rounded
      icon-right="play"
      @click="pauseSpeech()"
      v-if="speaking && paused"
    />
    <b-button
      type="is-primary"
      rounded
      icon-right="pause"
      @click="pauseSpeech()"
      v-else-if="speaking && !paused"
    />
    <b-button
      type="is-primary"
      outlined
      rounded
      icon-right="volume-high"
      @click="speak()"
      v-else
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class TTSControlComponent extends Vue {
  @Prop({ default: "", type: String }) public text!: string;
  @Prop({ default: false, type: Boolean }) public small!: boolean;
  // @Prop() public bus!: Vue;
  private bus = new Vue();

  private synth = window.speechSynthesis;
  private voices = speechSynthesis.getVoices();
  private lang = "nl-NL";
  private volume = 1;
  private pitch = 1;
  private rate = 0.9;
  private ttsBtnText = "Lees voor!";
  private ttsControlBtnText = "Pauzeer";
  private timeoutResumeInfinity: any;
  private paused = false;
  private speaking = false;

  private mounted() {
    if (this.bus) {
      this.bus.$on("stopSpeech", () => {
        this.stopSpeech();
      });
    }
  }

  private speak() {
    if (this.synth.speaking) {
      this.stopSpeech();
    } else {
      const voice = this.voices.find(
        (voiceInst) => voiceInst.lang === this.lang
      );
      const text = this.text
        .replace(/<br\s*\/?>/gm, `\n`)
        .replace(/(<([^>]+)>)/gi, ` `);

      if (text !== "") {
        const SSU = new SpeechSynthesisUtterance(text);
        if (voice !== undefined) {
          SSU.voice = voice;
        }
        SSU.lang = this.lang;
        SSU.pitch = this.pitch;
        SSU.rate = this.rate;
        SSU.volume = this.volume;
        this.synth.cancel();
        this.synth.speak(SSU);
        this.speaking = true;
        this.ttsBtnText = "Stop";
        this.resumeInfinity();
        SSU.onend = () => {
          this.endSpeech();
        };
      }
    }
  }

  private resumeInfinity() {
    this.synth.pause();
    this.synth.resume();
    if (this.synth.speaking && !this.paused) {
      this.timeoutResumeInfinity = setTimeout(this.resumeInfinity, 1000);
    }
  }

  private pauseSpeech() {
    if (this.paused) {
      this.synth.resume();
      this.paused = false;
      this.ttsControlBtnText = "Pauzeer";
    } else {
      clearTimeout(this.timeoutResumeInfinity);
      this.synth.pause();
      this.paused = true;
      this.ttsControlBtnText = "Ga door";
    }
  }

  private stopSpeech() {
    clearTimeout(this.timeoutResumeInfinity);
    this.synth.cancel();
    this.endSpeech();
  }
  private endSpeech() {
    this.speaking = false;
    this.ttsBtnText = "Lees nog eens voor!";
    this.paused = false;
    this.ttsControlBtnText = "Pauzeer";
  }
}
</script>

<style scoped lang="scss">
.tts__large {
  display: flex;
  gap: 8px;
}
.tts__small :deep .button {
  width: 0;
}
</style>
