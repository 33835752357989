<template>
  <BlockBase :block="block" :ttsText="ttsText">
    <div class="fragment-container">
      <div class="video-wrapper" v-if="block.video_id">
        <YoutubeVideo
          :videoId="block.video_id"
          :begin="block.video_begin_time"
          :end="block.video_end_time"
          :fit="false"
          :autoplay="0"
        />
      </div>
      <div class="fragment-heading-container" v-if="block.source || block.audio">
        <!-- <h3>
          {{ capitalizeFirstLetter(block.source_type) }} {{ block.source_no }}.
        </h3> -->
        <div />
        <audio controls controlsList="nodownload" v-if="block.audio">
          <source :src="block.audio" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <TTSControl
          :text="`
            ${block.source}.\n
          `"
          v-else
        />
      </div>
      <div class="fragment" v-html="block.source" v-if="block.source" />
    </div>
  </BlockBase>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { SourceBlock } from "@/lesson/block.types";
import BlockBase from "@/lesson/components/BlockBase.vue";
import { sourceBlock } from "@/lesson/utils/exampleTypes";
import TTSControl from "@/core/components/TTSControl.vue";
import YoutubeVideo from "@/core/components/YoutubeVideo.vue";

@Component({
  components: {
    BlockBase,
    TTSControl,
    YoutubeVideo,
  },
})
export default class SourceBlockComponent extends Vue {
  @Prop({
    default() {
      return sourceBlock;
    },
  })
  private block!: SourceBlock;

  private capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  private get ttsText() {
    return `${this.block.content}.\n
      ${this.block.source}.\n`;
    // ${this.block.source_type} ${this.block.source_no}. \n
  }
}
</script>

<style scoped lang="scss">
.fragment-heading-container {
  display: flex;
  align-content: center;
  justify-content: space-between;
  gap: 8px;

  position: sticky;
  top: 4px;
  background: rgba($white, 0.9);
  // backdrop-filter: blur(1px);
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 1rem 1rem;

  h3 {
    align-self: center;
  }
}
.fragment-container {
  width: #{"max(600px, 80%)"};
  max-width: 100%;
  margin: auto;
}
.fragment {
  background: $primary-light;
  margin-top: -0.5rem;
  padding: 1rem 1.5rem;
  border-radius: 1.5rem;
  min-height: 6rem;
  line-height: 1.75;
}
.video-wrapper {
  background: $primary-light;
  padding: 1rem;
  border-radius: 1.5rem;
  min-height: 6rem;
  margin-bottom: 2rem;
}
</style>
