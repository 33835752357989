<template>
  <div class="content-layout">
    <b-button
      v-if="back || true"
      :class="{ 'not-visible': !back }"
      tag="router-link"
      :to="{ name: 'Login' }"
      type="is-ghost"
      icon-left="chevron-left"
      class="content-layout__back"
    >
      Terug naar login
    </b-button>
    <div class="content-layout__header-title">
      <h1><slot name="title">Hi,</slot></h1>
      <p><slot name="subtitle"></slot></p>
    </div>
    <slot></slot>
    <div class="content-layout__page-links">
      <slot name="links"></slot>
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ContentLayout extends Vue {
  @Prop({ default: true, type: Boolean }) public back!: boolean;
}
</script>

<style scoped lang="scss">
.content-layout {
  .not-visible {
    visibility: hidden;
  }
  &__back {
    margin-left: -0.875rem;
  }
  &__header-title {
    margin-bottom: 1.05rem;
    h1 {
      margin-bottom: 0.075em;
    }
  }
  :deep .label {
    font-size: .875rem;
  }
  .button {
    &:not(:first-child) {
      margin-top: 1.5rem;
    }
    & + .button {
      margin-top: 1.75rem;
    }
  }

  &__page-links {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 2rem 0 1.5rem 0;
  }
}
</style>
