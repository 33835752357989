import { AxiosResponse } from "axios";
import { ApiClient } from "@/core/api/api-client.class";
import { LeaderboardUser } from "../leaderboard.types";

const client = new ApiClient();

export const leaderboardApi = {
  async getLeaderboard(): Promise<LeaderboardUser[]> {
    try {
      const response = (await client.get(`/leaderboard`)) as AxiosResponse<LeaderboardUser[]>;
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  async getLeaderboardForUser(userId: string): Promise<LeaderboardUser> {
    try {
      const response = (await client.get(`/leaderboard/${userId}`)) as AxiosResponse<LeaderboardUser>;
      const question = response.data;
      return question;
    } catch (err) {
      throw err;
    }
  },
}
