<template>
  <div style="display: contents">
    <b-modal v-model="isImageModalActive" v-if="lesson.image">
      <img :src="lesson.image" :alt="lesson.name" v-if="lesson.image" />
    </b-modal>
    <b-steps
      class="lesson-steps"
      v-model="activeStep"
      animated
      animateInitially
      rounded
      mobile-mode="compact"
      ref="page"
      :class="{ 'no-header-img': !lesson.image }"
    >
      <b-step-item
        :step="0"
        :label="'Video'"
        :icon="'video'"
        @click.native="goToStart"
      />
      <b-step-item
        v-for="step in lesson.steps"
        :key="step.id"
        :step="step.order_no"
        :label="step.name"
        :clickable="isStepClickable(step.order_no)"
        :icon="stepIcon(step)"
        :headerClass="{
          'answer-q-step':
            answerStudentQuestionsActive && activeStep === step.order_no,
        }"
      >
        <Page :step="step" v-if="!answerStudentQuestionsActive" />
        <AnswerStudentQuestionPage
          @continue="doneAnsweringQuestions()"
          v-else
        />
      </b-step-item>

      <template #navigation="{ previous, next }">
        <div class="step-navigation-container">
          <b-message
            type="is-primary"
            has-icon
            icon="information-outline"
            icon-size="is-medium"
            v-if="showNextWarning"
          >
            {{ warningNextPage }}
          </b-message>
          <div class="step-navigation-buttons">
            <b-button
              type="is-primary"
              inverted
              rounded
              size="is-medium"
              icon-left="chevron-left"
              @click.prevent="previousBtn(previous)"
            >
              {{ prevLabel }}
            </b-button>
            <b-tooltip
              :active="nextToolTip !== ''"
              :label="nextToolTip"
              multilined
            >
              <b-button
                type="is-primary"
                inverted
                rounded
                size="is-medium"
                icon-right="chevron-right"
                :disabled="!isNextClickable"
                :loading="nextBtnLoad"
                @click.prevent="nextBtn(next)"
              >
                {{ nextLabel }}
              </b-button>
            </b-tooltip>
          </div>
        </div>
      </template>
    </b-steps>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  Lesson,
  LessonStatus,
  LessonStep,
  LessonStepType,
} from "@/lesson/lesson.types";
import Page from "@/lesson/components/Page.vue";
import { stepIsComplete } from "@/lesson/utils/stepIsComplete";
import { QuestionBlockType } from "../block.types";
import AnswerStudentQuestionPage from "./AnswerStudentQuestionPage.vue";
import { lessonApi } from "../api/lesson.api";

@Component({
  components: {
    Page,
    AnswerStudentQuestionPage,
  },
})
export default class Steps extends Vue {
  @Getter("lesson", { namespace: "lesson" })
  private lesson!: Lesson;
  @Getter("currentStep", { namespace: "lesson" })
  private currentStep!: LessonStep;
  @Getter("furthestStep", { namespace: "lesson" })
  private furthestStep!: number;
  @Getter("progressPassedCurrentStep", { namespace: "lesson" })
  private progressPassedCurrentStep!: boolean;
  @Getter("blocksInEdit", { namespace: "lesson" })
  private blocksInEdit!: string[];

  @Action("UPDATE_LESSON_STATUS", { namespace: "lesson" })
  private updateLessonStatus!: (LessonStatus: LessonStatus) => void;
  @Action("SET_CURRENT_STEP", { namespace: "lesson" })
  private setCurrentStep!: (index: number) => void;
  @Action("SET_PROGRESS_PASSED_CURRENT_STEP", { namespace: "lesson" })
  private setprogressPassedCurrentStep!: (boo: boolean) => void;

  private activeStep = 1;
  private isImageModalActive = false;
  private answerStudentQuestionsActive = false;

  private get warningNextPage() {
    if (this.currentStep.type === LessonStepType.QUIZ) {
      return "Let op! Als je doorgaat naar de volgende stap en mocht je nog niet alle vragen hebben beantwoord, dan kun je voor die vragen geen punten meer verdienen.";
    }
    return "Let op! Als je door gaat naar de volgende stap, dan kan je de bovenstaande antwoorden niet meer bewerken.";
  }

  private stepIcon(step: LessonStep) {
    if (step.type === LessonStepType.QUIZ) {
      return "alphabetical-variant";
    }
    return this.complete(step) ? "check" : null;
  }

  private complete(s: LessonStep) {
    return stepIsComplete(s);
  }

  private doneAnsweringQuestions() {
    this.answerStudentQuestionsActive = false;
    this.scrollToTop();
  }

  @Watch("activeStep")
  private async onactiveStepChange() {
    if (this.activeStep === 0) {
      this.goToStart();
    } else {
      this.setCurrentStep(this.activeStep);
      this.updateProgress();
      if (
        this.lesson.status !== LessonStatus.FINISHED &&
        this.activeStep === this.furthestStep
      ) {
        this.nextBtnLoad = true;
        let questions = [];
        try {
          questions = await lessonApi.getStudentQuestions(
            this.lesson.id,
            false
          );
        } catch (error) {
          console.error(error);
        }
        this.answerStudentQuestionsActive = questions.length > 0;
        this.nextBtnLoad = false;
      } else {
        this.answerStudentQuestionsActive = false;
      }
      this.scrollToTop();
    }
  }

  private scrollToTop() {
    setTimeout(() => {
      this.$nextTick(() => {
        if ((this.$refs.page as Vue)?.$el) {
          (this.$refs.page as Vue).$el.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      });
    }, 250);
  }

  private updateProgress() {
    if (this.lesson.status === LessonStatus.FINISHED) {
      this.setprogressPassedCurrentStep(true);
    } else if (this.activeStep < this.furthestStep) {
      this.setprogressPassedCurrentStep(true);
    } else {
      this.setprogressPassedCurrentStep(false);
    }
  }

  private beforeMount() {
    if (
      !this.$route.params.startFromBeginning &&
      this.lesson.status !== LessonStatus.FINISHED
    ) {
      this.activeStep = this.furthestStep;
    }
    this.updateProgress();
  }

  private mounted() {
    if (this.lesson.image && (this.$refs.page as any)?.$el?.childNodes[0]) {
      (
        this.$refs.page as any
      ).$el.childNodes[0].style.backgroundImage = `url('${this.lesson.image}')`;
    }
    const stepsContainer = document.querySelector("nav.steps");
    if (stepsContainer) {
      stepsContainer.addEventListener("click", (e) => {
        if (e.target !== stepsContainer) return;
        this.isImageModalActive = true;
      });
    }
  }

  private isStepClickable(stepNum: number) {
    if (this.furthestStep >= stepNum) {
      return true;
    }
    return false;
  }

  private get showNextWarning() {
    return (
      !this.answerStudentQuestionsActive &&
      !this.progressPassedCurrentStep &&
      this.questionBlockOnPage
    );
  }

  private get questionBlockOnPage() {
    return this.currentStep.blocks.some((block) =>
      Object.values(QuestionBlockType).includes(
        block.block_type as QuestionBlockType
      )
    );
  }

  private goToStart() {
    this.$router.push({
      name: "LessonStart",
      params: { lessonId: `${this.lesson.id}` },
    });
  }

  private previousBtn(previous: any) {
    if (previous.disabled || this.activeStep <= 1) {
      // if on first step.
      this.goToStart();
    } else {
      previous.action();
    }
  }

  private get nextToolTip() {
    if (this.progressPassedCurrentStep || this.answerStudentQuestionsActive) {
      // if user is looking at a previous step, don't show tooltip
      // if user is answering student questions, show tooltip
      return "";
    } else if (this.blocksInEdit.length > 0) {
      return "Je hebt nog een antwoord gewijzigd. Beantwoord deze eerst voordat je verder kan.";
    } else if (!stepIsComplete(this.currentStep)) {
      return "Je hebt nog niet alle vragen beantwoord.";
    }
    return "";
  }

  private get isNextClickable() {
    if (this.answerStudentQuestionsActive) {
      return true;
    }
    if (this.progressPassedCurrentStep) {
      // if user is on a previous step, allow next
      return true;
    } else if (stepIsComplete(this.currentStep)) {
      // if all block on current step are answered and none of the blocks are edited after a save.
      return true;
    }
    return false;
  }

  @Watch("isNextClickable")
  private onisNextClickableChange() {
    if (
      this.currentStep.type === LessonStepType.QUIZ &&
      !this.progressPassedCurrentStep &&
      this.isNextClickable
    ) {
      this.$buefy.notification.open({
        message:
          "Je hebt de quiz voltooid! Ga door voor meer punten, of ga door naar de volgende stap via de knop onderaan de pagina.",
        type: "is-success",
        position: "is-bottom-right",
        duration: 12000,
        hasIcon: true,
      });
    }
  }

  private nextBtnLoad = false;
  private async nextBtn(next: any) {
    if (this.answerStudentQuestionsActive) {
      this.doneAnsweringQuestions();
    } else if (this.isNextClickable) {
      if (this.currentStep.order_no === this.lesson.steps.length) {
        // if at last step
        this.nextBtnLoad = true;
        try {
          if (this.lesson.status !== LessonStatus.FINISHED) {
            await lessonApi.postLessonFinished(this.lesson.id);
          }
          await new Promise((resolve) => setTimeout(resolve, 2000));
          this.$router.push({
            name: "LessonFinish",
            params: { lessonId: `${this.lesson.id}` },
          });
          this.updateLessonStatus(LessonStatus.FINISHED);
        } catch (er: any) {
          this.$buefy.notification.open({
            message: er.response.data.message,
            type: "is-danger",
            position: "is-bottom-right",
            duration: 5000,
            hasIcon: true,
          });
        }
      } else {
        if (
          this.lesson.order_no <= 2 &&
          this.currentStep.order_no <= 2 &&
          this.showNextWarning
        ) {
          // if new user show extra warning
          this.$buefy.dialog.confirm({
            message: this.warningNextPage,
            hasIcon: true,
            icon: "alert-outline",
            confirmText: "Door naar volgende stap",
            cancelText: "Blijf op deze stap",
            onConfirm: () => next.action(),
          });
        } else {
          next.action();
        }
      }
      this.nextBtnLoad = false;
    }
  }

  private get prevLabel() {
    if (this.activeStep === 1) {
      return "Start";
    } else {
      return "Stap " + (this.activeStep - 1);
    }
  }
  private get nextLabel() {
    if (this.answerStudentQuestionsActive) {
      return `Stap ${this.activeStep}`;
    }
    if (this.lesson.steps.length === this.activeStep) {
      return "Voltooi";
    }
    return "Stap " + (this.activeStep + 1);
  }
}
</script>

<style lang="scss">
// Watch out! This is unscoped
.b-steps.lesson-steps {
  flex-grow: 1;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background: $primary-soft;
  padding: 0;
  transition: padding 0.5s ease;
  @media screen and (min-width: $tablet) {
    padding: 0 0.5rem 1rem 0.5rem;
  }
  &.no-header-img .steps {
    cursor: default;
    aspect-ratio: unset;
    min-height: max-content;
    padding: 1.5rem 0.5rem 1.5rem 0.5rem;
    &:before {
      background: none;
    }
    .step-link {
      color: $primary !important;
    }
  }
  .steps {
    cursor: pointer;
    min-height: 14rem;
    aspect-ratio: 3 / 2;
    display: flex;
    flex-direction: column-reverse;
    background-position: center;
    background-size: cover;
    padding: 0 0.5rem 1.5rem 0.5rem;
    margin: 0.5rem 0.375rem;
    @media screen and (max-width: $desktop) {
      margin: 0.5rem 0;
    }
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    font-family: $headering-font-family;
    transition: 1s ease;
    &:before {
      content: "";
      position: absolute;
      inset: 64% 0 0 0;
      background: linear-gradient(
        0deg,
        rgba(10, 10, 10, 0.94) 0%,
        rgba(10, 10, 10, 0.8) 50%,
        rgba(10, 10, 10, 0) 100%
      );
      transition: 1s ease;
    }
    .step-link {
      gap: 6px;
      color: #fff !important;
      transition: 1s ease;
    }
    .step-item:not(.is-active) .step-link.is-clickable .step-marker {
      &:hover {
        background-color: $primary;
      }
    }
    & + .step-content {
      padding: 0;
      min-height: 100%;
      .step-item {
        flex-grow: 1;
      }
    }
    .step-items .step-item.answer-q-step.is-active {
      &::before {
        background-position: center bottom;
      }
      &::after {
        $border-size: 0.1875rem;
        $font-size: 1.125rem;
        $padding: 0.25rem;
        $size: $font-size + 2 * ($padding + $border-size);
        content: "\F02D6";
        position: absolute;
        inset: 0;
        top: calc((2rem - $size) / 2);
        left: calc($size / -2);
        height: max-content;
        width: max-content;
        border-radius: 100%;
        border: $border-size solid $primary;
        background: $white;
        font: normal normal normal 24px/1 "Material Design Icons";
        font-size: $font-size;
        padding: $padding;
      }
      .step-link .step-marker {
        background: hsl(0, 0%, 86%);
        color: #fff;
        border-color: #fff;
      }
    }
  }
}
.step-navigation-container {
  background: $white;
  padding: 1.5rem 3rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
  border-radius: 1rem;
  .step-navigation-buttons {
    display: flex;
    justify-content: space-between;
    .button.is-primary.is-loading::after {
      border-color: transparent transparent $primary $primary !important;
    }
  }
}
.inserted-answer {
  color: $primary;
}
</style>
