<template>
  <BlockBase :block="block" :ttsText="ttsText"> </BlockBase>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { InfoBlock } from "@/lesson/block.types";
import BlockBase from "@/lesson/components/BlockBase.vue";
import { infoBlock } from "@/lesson/utils/exampleTypes";

@Component({
  components: {
    BlockBase,
  },
})
export default class InfoBlockComponent extends Vue {
  @Prop({
    default() {
      return infoBlock;
    },
  })
  private block!: InfoBlock;

  private get ttsText() {
    return `${this.block.content}.\n`;
  }
}
</script>

<style scoped lang="scss">
</style>
