import { AxiosResponse } from "axios";
import { ApiClient } from "@/core/api/api-client.class";
import { Lesson } from "@/lesson/lesson.types";
import { AnswerPost, AnswerReply, Block } from "@/lesson/block.types";
import { StudentQuestion, StudentQuestionPost } from "../studentQuestion.types";

const client = new ApiClient();

export const lessonApi = {
  async getLesson(lessonId: string): Promise<Lesson> {
    try {
      const response = (await client.get(`/lesson/${lessonId}`)) as AxiosResponse<Lesson>;
      const lesson = response.data;
      return lesson;
    } catch (err) {
      throw err;
    }
  },
  postLessonInProgress(id: string): Promise<AxiosResponse<{message: string}>> {
      return client.post(`/lesson/${id}/in-progress`);
  },
  postLessonFinished(id: string): Promise<AxiosResponse<{message: string}>> {
      return client.post(`/lesson/${id}/finished`);
  },
  async getBlock(blockId: string): Promise<Block> {
    try {
      const response = (await client.get(`/block/${blockId}`)) as AxiosResponse<Block>;
      const block = response.data;
      return block;
    } catch (err) {
      throw err;
    }
  },
  postAnswer(answer: AnswerPost): Promise<AxiosResponse<AnswerReply>> {
    return client.post('/answer', answer);
  },
  async postStudentQuestion(question: StudentQuestionPost): Promise<any> {
    try {
      const response = (await client.post('/student-questions', question)) as AxiosResponse<any>;
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  async getStudentQuestions(lessonId: string, own: undefined | boolean = undefined): Promise<StudentQuestion[]> {
    let includeOwn = 'true';
    if (own !== undefined) {
      if (own) {
        includeOwn = '&own=1';
      } else {
        includeOwn = '&own=0';
      }
    }
    try {
      const response = (await client.get(`/student-questions?lesson_id=${lessonId}${includeOwn}`)) as AxiosResponse<StudentQuestion[]>;
      const questions = response.data;
      return questions;
    } catch (err) {
      throw err;
    }
  },
  async getStudentQuestion(questionId: string): Promise<StudentQuestion> {
    try {
      const response = (await client.get(`/student-questions/${questionId}`)) as AxiosResponse<StudentQuestion>;
      const question = response.data;
      return question;
    } catch (err) {
      throw err;
    }
  },
  postStudentQuestionAnswer(question_id: string, answer_text: string): Promise<AxiosResponse<string>> {
    return client.post('/student-question-answers', {question_id, answer_text});
  },
}
