'use strict';

import { RootState } from '@/store/store.types';
import { GetterTree } from 'vuex';
import { LoginState, UserableType } from '@/login/login.types';

export const getters: GetterTree<LoginState, RootState> = {
  user(state: LoginState) {
    return state.user;
  },

  score(state: LoginState) {
    return state.user?.score || 0;
  },

  accessToken(state: LoginState) {
    return state.accessToken;
  },

  // Calculated getters
  isAuthenticated(state: LoginState) {
    return !!state.accessToken;
  },

  isStudent(state: LoginState) {
    return state.user?.type === UserableType.STUDENT;
  },
  isTeacher(state: LoginState) {
    return state.user?.type === UserableType.TEACHER;
  },
  isAdmin(state: LoginState) {
    return state.user?.type === UserableType.ADMIN;
  },
  
  lockAccountRegistration(state: LoginState) {
    return state.lockAccountRegistration;
  },
};
