<template>
  <HelperLayout
    :video="{
      id: lesson.start_video_id,
      begin: lesson.start_video_begin_time,
      end: lesson.start_video_end_time,
    }"
    :text="lesson.start_page_html"
    :to="{ name: 'Lesson', params: { lessonId: lesson.id, startFromBeginning: true } }"
    :btnText="btnText"
  />
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Lesson, LessonStatus } from "@/lesson/lesson.types";
import HelperLayout from "@/lesson/layout/HelperLayout.vue";

@Component({
  components: {
    HelperLayout,
  },
})
export default class StartView extends Vue {
  @Getter("lesson", { namespace: "lesson" })
  private lesson!: Lesson;

  private get btnText() {
    switch (this.lesson.status) {
      case LessonStatus.CLOSED:
        return `CLOSED`;
      case LessonStatus.OPEN:
        return `Start het level`;
      case LessonStatus.IN_PROGRESS:
        return `Verdergaan met het level`;
      case LessonStatus.FINISHED:
        return `Terugkijken`;
      default:
        return `Start`;
    }
  }
}
</script>

<style scoped lang="scss">
</style>
