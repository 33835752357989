<template>
  <BlockBase
    :block="block"
    :checked="answerChecked"
    :edited="isEditedAfterComplete"
    :answer="trimmedAnswer"
    :answerBtnHint="showTooShortMessage"
    :ttsText="ttsText"
  >
    <template v-slot="{ isEnabled, isComingUp }">
      <b-field
        v-for="(item, i) in block.question_items"
        :key="item.key"
        :label="item.attributes.subquestion_text"
        :type="showStartWarning(i) ? 'is-danger' : ''"
        :message="
          showStartWarning(i)
            ? `Let op! Dit antwoord moet beginnen met: \'${item.attributes.prefilled_answer}\'`
            : ''
        "
        class="fill-item"
      >
        <div class="hint-btn-container" v-if="item.attributes.subquestion_hint">
          <b-tooltip
            type="is-primary is-light"
            label="Vraag om wat extra hulp bij deze vraag."
            size="is-small"
            :delay="600"
            multilined
          >
            <b-button
              type="is-primary"
              :outlined="!hintsUsed[i]"
              rounded
              icon-left="information-variant"
              @click="setHint(i)"
              :active="hintsUsed[i]"
            >
              Hulp
            </b-button>
          </b-tooltip>
        </div>
        <b-collapse
          v-if="item.attributes.subquestion_hint"
          class="hint-container"
          :open="hintsUsed[i]"
          :aria-id="'HintForItem' + i"
        >
          <b-message
            type="is-primary"
            has-icon
            icon="help-circle-outline"
            icon-size="is-medium"
          >
            <div v-html="item.attributes.subquestion_hint" />
          </b-message>
        </b-collapse>
        <b-input
          v-model="answer[i]"
          :disabled="!isEnabled || isComingUp || block.is_example === 1"
          type="textarea"
          rows="4"
          @focus="focus(i)"
        />
      </b-field>
    </template>
  </BlockBase>
</template>


<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { FillBlanksBlock } from "@/lesson/block.types";
import BlockBase from "@/lesson/components/BlockBase.vue";
import { fillBlanksBlock } from "@/lesson/utils/exampleTypes";

@Component({
  components: {
    BlockBase,
  },
})
export default class FillBlanksBlockComponent extends Vue {
  @Prop({
    default() {
      return fillBlanksBlock;
    },
  })
  private block!: FillBlanksBlock;

  private get ttsText() {
    let text = `
            ${this.block.content}.\n 
            ${this.block.question}.\n
          `;
    for (let i = 0; i < this.block.question_items.length; i++) {
      const item = this.block.question_items[i];
      text =
        text +
        `${i + 1}. \n
        ${item.attributes.subquestion_text}.\n
        Jouw antwoord:\n
        ${this.answer[i]}.\n`;
    }
    return text;
  }

  private answer: string[] = [];
  private hintsUsed: boolean[] = [];
  private startWarnings: number[] = [];

  private setHint(i: number) {
    Vue.set(this.hintsUsed, i, true);
  }

  private beforeMount() {
    for (let i = 0; i < this.block.question_items.length; i++) {
      this.hintsUsed.push(false);
    }
    if (
      this.block.latest_answer &&
      Array.isArray(this.block.latest_answer.answer)
    ) {
      let somethingIsNotString = false;
      this.block.latest_answer.answer.forEach(function (item) {
        if (typeof item !== "string") {
          somethingIsNotString = true;
        }
      });
      if (!somethingIsNotString && this.block.latest_answer.answer.length > 0) {
        this.answer = this.block.latest_answer.answer as string[];
      } else {
        // something wrong with previous answer. Go back to default.
        this.prepareAnswer();
      }
    } else {
      this.prepareAnswer();
    }
  }

  private prepareAnswer() {
    let added = "";
    if (this.block.is_example === 0) {
      added = "…";
    }
    for (const item of this.block.question_items) {
      this.answer.push(item.attributes.prefilled_answer + added);
      this.hintsUsed.push(false);
    }
  }

  private focus(i: number) {
    this.$set(this.answer, i, this.answer[i].replace(/…\s*$/, " "));
  }

  @Watch("answer")
  private onAnswerChange() {
    for (let i = 0; i < this.answer.length; i++) {
      let a = this.answer[i];
      const startsWithThis =
        this.block.question_items[i].attributes.prefilled_answer.trim();
      if (
        !a.startsWith(startsWithThis) &&
        !this.startWarnings.some((w) => w === i)
      ) {
        // give item startWarning if it doesn't start with given string. (and isnt in the array yet)
        this.startWarnings.push(i);
      } else if (a.startsWith(startsWithThis)) {
        this.startWarnings = this.startWarnings.filter((w) => w !== i);
      }
    }
  }

  private showStartWarning(index: number) {
    return this.startWarnings.some((w) => w === index);
  }

  private get isEditedAfterComplete() {
    if (
      this.block.complete &&
      this.block.latest_answer &&
      Array.isArray(this.block.latest_answer.answer)
    ) {
      if (
        this.trimmedAnswer.length === this.block.latest_answer?.answer.length &&
        this.trimmedAnswer.every(
          (val, index) =>
            val === (this.block.latest_answer?.answer as string[])[index]
        )
      ) {
        // check if arrays are exactly the same.
        return false;
      }
    }
    return true;
  }

  private get trimmedAnswer() {
    return this.answer.map((item) => item.trim());
  }

  private get answerTooShort() {
    if (
      this.trimmedAnswer.some(
        (i, index) =>
          i.replace(/…\s*$/, "").length <
          this.block.question_items[index].attributes.prefilled_answer.length +
            1
      )
    ) {
      return true;
    }
    return false;
  }

  private get answerChecked() {
    if (this.block.is_example === 1) {
      return true;
    }
    if (this.answerTooShort) {
      return false;
    }
    if (this.startWarnings.length > 0) {
      return false;
    }
    return true;
  }

  private get showTooShortMessage() {
    // Filter the array of sentences to only keep the ones where the sentence is smaller than the answer
    // (plus one character, because the last character of the answer is a space).
    // Then count the amount of sentences that are smaller than the answer
    const amountOfSentences = this.trimmedAnswer.filter(
      (sentence, index) =>
        sentence.replace(/…\s*$/, "").length <
        this.block.question_items[index].attributes.prefilled_answer.length + 1
    ).length;

    if (!this.answerChecked && this.startWarnings.length > 0) {
      return `Zorg dat al je zinnen op de juiste manier beginnen.`;
    }
    if (!this.answerChecked && this.answerTooShort) {
      return `Je ${amountOfSentences} ${
        amountOfSentences > 1 ? "zinnen" : "zin"
      } nog niet afgemaakt.`;
    }
    return "";
  }
}
</script>

<style scoped lang="scss">
.hint-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.625rem;
  :deep .button .mdi-information-variant {
    font-size: 1.375em;
  }
}
.fill-item :deep .field-body .field.has-addons {
  flex-direction: column;
}
.char-count-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
