<template>
  <BlockBase
    :block="block"
    :checked="answerChecked"
    :edited="isEditedAfterComplete"
    :answer="trimmedAnswer"
    :answerBtnHint="showTooShortMessage"
    :ttsText="ttsText"
  >
    <template v-slot="{ isEnabled, isComingUp }">
      <b-field>
        <b-input
          v-model="answer"
          :disabled="!isEnabled || isComingUp || block.is_example === 1"
          :type="block.type === 'short' ? 'text' : 'textarea'"
          rows="7"
        />
      </b-field>
    </template>
    <template
      #button-bar
      v-if="block.min_length_answer && block.min_length_answer > 1"
    >
      <div class="char-count-wrapper">
        <transition name="fade">
          <div
            class="char-count"
            v-if="!block.complete || isEditedAfterComplete"
          >
            <span
              :class="answerChecked ? 'has-text-primary' : 'has-text-danger'"
            >
              {{ trimmedAnswer.length }}
            </span>
            <span> / </span>
            <span>
              {{ block.min_length_answer }}
              <small>tekens minimaal</small>
            </span>
          </div>
        </transition>
      </div>
    </template>
  </BlockBase>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { TextBlock } from "@/lesson/block.types";
import BlockBase from "@/lesson/components/BlockBase.vue";
import { textBlock } from "@/lesson/utils/exampleTypes";

@Component({
  components: {
    BlockBase,
  },
})
export default class TextBlockComponent extends Vue {
  @Prop({
    default() {
      return textBlock;
    },
  })
  private block!: TextBlock;

  private get ttsText() {
    return `
            ${this.block.content}.\n 
            ${this.block.question}.\n 
            Jouw antwoord: \n
            ${this.answer}.\n
          `;
  }

  private answer = "";

  private beforeMount() {
    if (
      this.block.latest_answer &&
      typeof this.block.latest_answer.answer === "string"
    ) {
      this.answer = this.block.latest_answer.answer;
    } else if (this.block.prefilled_answer) {
      this.answer = this.block.prefilled_answer;
    }
  }

  private get isEditedAfterComplete() {
    return (
      this.trimmedAnswer !== this.block.latest_answer?.answer &&
      this.block.complete
    );
  }

  private get trimmedAnswer() {
    return this.answer.trim();
  }

  private get answerTooShort() {
    if (
      this.block.min_length_answer &&
      this.trimmedAnswer.length < this.block.min_length_answer
    ) {
      return true;
    } else if (this.trimmedAnswer.length < 1) {
      return true;
    }
    return false;
  }

  private get answerChecked() {
    if (this.block.is_example === 1) {
      return true;
    }
    if (this.answerTooShort) {
      return false;
    }
    return true;
  }

  private get showTooShortMessage() {
    if (!this.answerChecked && this.answerTooShort) {
      return "Je antwoord is nog te kort. Probeer misschien nog wat meer te vertellen.";
    }
    return "";
  }
}
</script>

<style scoped lang="scss">
.char-count-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
