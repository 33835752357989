import { RouteConfig } from 'vue-router';
import Login from '@/login/views/Login.vue';
import NewAccount from '@/login/views/NewAccount.vue';
import NewPassword from '@/login/views/NewPassword.vue';
import NewAccountFromRequest from '@/login/views/NewAccountFromRequest.vue';
import ForgotPassword from '@/login/views/ForgotPassword.vue';
// import Contact from '@/login/views/Contact.vue';
import { loginGuard } from '@/login/guards/login.guard';
import { registrationGuard } from '@/login/guards/registration.guard';

export const loginRoutes: RouteConfig[] = [
  {
    path: "",
    name: "Login",
    beforeEnter: loginGuard,
    component: Login,
    meta: {
      disableAuthentication: true,
      title: 'Login',
    },
  },
  {
    path: "nieuw-account",
    name: "NewAccount",
    beforeEnter: registrationGuard,
    component: NewAccount,
    meta: {
      disableAuthentication: true,
      title: 'Registreren',
    },
  },
  {
    path: "wachtwoord-vergeten",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      disableAuthentication: true,
      title: 'Wachtwoord vergeten',
    },
  },
  {
    path: "nieuw-wachtwoord",
    name: "NewPassword",
    component: NewPassword,
    meta: {
      disableAuthentication: true,
      title: 'Nieuw wachtwoord',
    },
  },
  {
    path: "account-instellen",
    name: "NewAccountPassword",
    component: NewAccountFromRequest,
    meta: {
      disableAuthentication: true,
      title: 'account-instellen',
    },
  },
  // {
  //   path: "contact",
  //   name: "Contact",
  //   component: Contact,
  //   meta: {
  //     disableAuthentication: true,
  //   },
  // },
];
