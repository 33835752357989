<template>
  <MainLayout class="main">
    <h3>{{ course.name }}</h3>
    <h5 class="main-question" v-if="course.main_question">
      {{ course.main_question }}
    </h5>
    <div class="course__wrapper">
      <CourseBook />
    </div>
  </MainLayout>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import MainLayout from "@/core/layout/MainLayout.vue";
import { Course } from "@/course/course.types";
import { Getter } from "vuex-class";
import CourseBook from "@/course/components/CourseBook.vue";

@Component({
  components: {
    MainLayout,
    CourseBook,
  },
})
export default class CourseBookView extends Vue {
  @Getter("course", { namespace: "course" })
  private course!: Course;
}
</script>

<style scoped lang="scss">
.main {
  overflow: hidden;
}
.main-question {
  color: $grey;
}
.course {
  &__wrapper {
    width: 100%;
    height: 100%;
    margin: 1.5rem auto;

    @media screen and (min-width: $widescreen) {
      margin: 1.125rem auto;
    }
    @media screen and (min-width: $ultrawide) {
      margin: 2.5rem auto;
    }
  }
}
</style>
