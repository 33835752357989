<template>
  <div class="vdivider" />
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class VDivider extends Vue {}
</script>

<style scoped lang="scss">
.vdivider {
  width: 1px;
  min-height: 4px;
  height: 80%;
  margin: auto;
  background-color: $grey-light;
  border-radius: 16px;
}
</style>
