'use strict';

import { RootState } from '@/store/store.types';
import { ActionTree } from 'vuex';
import { Lesson, LessonState, LessonStatus, LessonStep } from '@/lesson/lesson.types';
import { lessonApi } from '@/lesson/api/lesson.api';
import { AnswerPost, Block } from '@/lesson/block.types';
import vueInstance from '@/main';
import { state as courseState } from '@/course/store/course.state';

export const actions: ActionTree<LessonState, RootState> = {
  async LOAD_LESSON({ dispatch }, lessonId: string) {
    try {
      const lesson = await lessonApi.getLesson(lessonId);
      dispatch('SET_LESSON', lesson);
    } catch (error) {
      vueInstance.$router.push({ name: 'Course', params: { courseId: courseState.course?.id ?? '' } });
      vueInstance.$buefy.notification.open({
        indefinite: true,
        message: `Er ging helaas wat mis. Probeer het later nog eens of neem contact met ons op.`,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
      throw error;
    }
  },
  SET_LESSON({ commit, dispatch }, lesson: Lesson) {
    commit('setLesson', lesson);
    dispatch('SET_CURRENT_STEP', 1);
  },
  REMOVE_LESSON({ commit }) {
    commit('removeLesson');
  },
  UPDATE_LESSON_STATUS({ commit }, lessonStatus: LessonStatus) {
    commit('updateLessonStatus', lessonStatus);
  },
  SET_CURRENT_STEP({ commit, getters }, index: number) {
    const step: LessonStep = getters.lesson.steps[index - 1];
    commit('setCurrentStep', step);
  },
  SET_BLOCK_ANSWER({ commit }, answerPost: AnswerPost) {
    commit('setBlockAnswer', answerPost);
  },
  SET_PROGRESS_PASSED_CURRENT_STEP({ commit }, boo: boolean) {
    commit('setprogressPassedCurrentStep', boo);
  },
  PUSH_BLOCKS_IN_EDIT({ commit, getters }, id: string) {
    if (!getters.blocksInEdit.includes(id)) {
      commit('pushBlocksInEdit', id);
    }
  },
  REMOVE_BLOCKS_IN_EDIT({ commit, dispatch, getters }, id: string) {
    const index = getters.blocksInEdit.indexOf(id);
    if (index > -1) {
      commit('removeBlocksInEdit', id);
    }
    // Since we dont know what block should be updated based on this change, update all.
    const dependentBlocks: Block[] = [];
    for (const step of getters.lesson.steps as LessonStep[]) {
      dependentBlocks.push(...step.blocks.filter((block) => { return block.depends_on_other_question }));
    }
    for (const block of dependentBlocks) {
      dispatch('UPDATE_BLOCK', block.id);
    }
  },
  async UPDATE_BLOCK({ commit }, id: string) {
    try {
      const block = await lessonApi.getBlock(id);
      commit('update_block', block);
    } catch (error) {
      // fail
    }
  }
};
