import { render, staticRenderFns } from "./BlockBase.vue?vue&type=template&id=0621e898&scoped=true"
import script from "./BlockBase.vue?vue&type=script&lang=ts"
export * from "./BlockBase.vue?vue&type=script&lang=ts"
import style0 from "./BlockBase.vue?vue&type=style&index=0&id=0621e898&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0621e898",
  null
  
)

export default component.exports