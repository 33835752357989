<template>
  <HelperLayout
    :isCourse="true"
    :video="{
      id: course.intro_video_id,
      begin: course.intro_video_begin_time,
      end: course.intro_video_end_time,
    }"
    :to="{ name: 'CourseIntroductionPart2', params: { courseId: course.id } }"
    :btnText="btnText"
  />
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import HelperLayout from "@/lesson/layout/HelperLayout.vue";
import { Course } from "@/course/course.types";

@Component({
  components: {
    HelperLayout,
  },
})
export default class IntroVideoView extends Vue {
  @Getter("course", { namespace: "course" })
  private course!: Course;

  private btnText = "Verder";
}
</script>

<style scoped lang="scss">
</style>
