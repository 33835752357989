import { Block } from "@/lesson/block.types";

export interface LessonState {
  lesson?: Lesson,
  currentStep?: LessonStep,
  progressPassedCurrentStep: boolean,
  blocksInEdit: string[],
}

export interface LessonStep {
  id: string,
  name: string,
  type: LessonStepType,
  order_no: number,
  blocks: Block[],
}

export enum LessonStepType {
  QUIZ = 'quiz',
  LINEAR = 'linear',
  INTRO = 'intro',
}

export interface Lesson extends LessonBase {
  course_id: string;
  steps: LessonStep[];
  start_page_html: string;
  start_video_id?: string;
  start_video_begin_time?: number;
  start_video_end_time?: number;
  finish_page_html: string;
}

export interface LessonBase {
  id: string,
  name: string,
  order_no: number,
  status: LessonStatus,
  image?: string;
}

export enum LessonStatus {
  CLOSED = 'closed',
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
}
