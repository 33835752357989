<template>
  <div class="wrapper">
    <Youtube
      class="video"
      :fitParent="fit"
      :resize="true"
      :video-id="videoId"
      :player-vars="playerVars"
      @ended="ended"
    />
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Youtube } from "vue-youtube";

@Component({
  components: {
    Youtube,
  },
})
export default class YoutubeVideo extends Vue {
  @Prop({ default: "9kIqEMr5vzI" }) private videoId!: string;
  @Prop({ default: null }) private begin!: number | null;
  @Prop({ default: null }) private end!: number | null;
  @Prop({ default: true, type: Boolean }) private fit!: boolean;
  @Prop({ default: 1, type: Number }) private autoplay!: number;

  private get playerVars() {
    return {
      autoplay: this.autoplay,
      cc_lang_pref: "nl",
      controls: 1,
      disablekb: 1,
      modestbranding: 1,
      rel: 0,
      start: this.begin,
      end: this.end,
    };
  }

  private ended() {
    this.$emit("ended");
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  :deep iframe {
    width: 100%;
    border-radius: 0.75rem;
  }
}
</style>
