<template>
  <MainLayout noTBpadding>
    <template #navbar>
      <Navbar
        :back="{ name: 'Course', params: { courseId: lesson.course_id } }"
      >
        <template #title>
          <span class="nav-title" v-if="lesson.order_no == 0">Proloog</span>
          <span class="nav-title" v-else>Level {{ lesson.order_no }}</span>
          <span class="nav-title">{{ lesson.name }}</span>
        </template>
        <!-- <b-navbar-item>
          <NavbarViewAs />
        </b-navbar-item> -->
      </Navbar>
    </template>
    <Steps />
    <template #sidebar>
      <StudentQuestionsSidebar
        :open.sync="open"
        @close="open = false"
        @open="open = true"
      />
    </template>
    <b-button
      type="is-primary"
      class="fab"
      :class="{ fab__active: open }"
      inverted
      rounded
      :icon-left="open ? 'close-circle-outline' : 'help-circle-outline'"
      @click="open = !open"
    >
      {{ open ? "Sluit" : "Vraag" }}
    </b-button>
  </MainLayout>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import MainLayout from "@/core/layout/MainLayout.vue";
import Navbar from "@/core/components/Navbar.vue";
import { Getter } from "vuex-class";
import { Lesson } from "@/lesson/lesson.types";
import Steps from "@/lesson/components/Steps.vue";
import { User } from "@/login/login.types";
import StudentQuestionsSidebar from "../components/StudentQuestionsSidebar.vue";

@Component({
  components: {
    MainLayout,
    Navbar,
    Steps,
    StudentQuestionsSidebar,
  },
})
export default class LessonView extends Vue {
  @Getter("lesson", { namespace: "lesson" })
  private lesson!: Lesson;
  @Getter("user", { namespace: "login" })
  private user!: User;

  private open = false;
}
</script>

<style scoped lang="scss">
.fab {
  --fab-padding-right: 24px;
  position: absolute;
  bottom: 24px;
  right: var(--fab-padding-right);
  z-index: 99;
  transition: right 0.65s ease;
  box-shadow: 1px 2px 6px #00000032;
  border: 1px solid #4a4745 !important;

  &__active {
    right: calc(var(--sidebar-width) + var(--fab-padding-right));
  }

  :deep .mdi {
    font-size: 1.375em;
    margin-right: 2px;
  }
}
:deep .btn-text {
  width: 100%;
  line-height: 1;
  font-size: 1.125rem;
  font-family: $headering-font-family;
  & + .icon.is-small {
    margin-left: 4px;
    opacity: 0.64;
  }
}
</style>
