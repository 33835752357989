<template>
  <div>
    <div class="page" :class="{ 'page-cover': showPageCover }">
      <div class="page-content" v-if="showPageCover"></div>
      <div class="page-content" v-else>
        <div class="page-image">
          <img
            :src="lesson.image"
            :alt="'Afbeelding uit ' + lesson.name"
            @click="isImageModalActive = true"
            v-if="lesson.image"
          />
        </div>
        <div class="page-footer">{{ lesson.order_no * 2 }}</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <h4 class="page-header level" v-if="introVideo">Proloog</h4>
        <h4 class="page-header level" v-else-if="lesson.order_no == 0">Proloog</h4>
        <h4 class="page-header level" v-else>Level {{ lesson.order_no }}</h4>
        <h3 class="page-header">{{ lesson.name }}</h3>
        <div class="page-text">
          <b-tooltip
            label="Je moet eerst het level hiervoor afmaken."
            :delay="400"
            :active="btnDisabled"
          >
            <b-button
              type="is-primary"
              size="is-medium"
              icon-right="chevron-right"
              rounded
              :disabled="btnDisabled"
              :outlined="btnOutlined"
              tag="router-link"
              :to="to"
            >
              {{ btnText }}
            </b-button>
          </b-tooltip>
        </div>
        <div class="page-footer">{{ lesson.order_no * 2 + 1 }}</div>
      </div>
    </div>
    <b-modal v-model="isImageModalActive" v-if="lesson.image">
      <img :src="lesson.image" :alt="'Afbeelding uit ' + lesson.name" />
    </b-modal>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import MainLayout from "@/core/layout/MainLayout.vue";
import Navbar from "@/core/components/Navbar.vue";
import YoutubeVideo from "@/core/components/YoutubeVideo.vue";
import { Course } from "../course.types";
import { Getter } from "vuex-class";
import { LessonBase, LessonStatus } from "@/lesson/lesson.types";
import { RawLocation } from "vue-router";

@Component({
  components: {
    MainLayout,
    Navbar,
    YoutubeVideo,
  },
})
export default class BookLevelPage extends Vue {
  @Prop({ default: false, type: Boolean }) private introVideo!: boolean;
  @Prop({
    default() {
      const lesson: LessonBase = {
        id: "",
        name: "Titel van deze les",
        order_no: 1,
        status: LessonStatus.CLOSED,
      };
      return lesson;
    },
  })
  private lesson!: LessonBase;

  @Getter("course", { namespace: "course" })
  private course!: Course;

  private isImageModalActive = false;

  private get showPageCover() {
    return this.introVideo || this.lesson.order_no == 0;
  }

  private get to(): RawLocation {
    if (this.introVideo) {
      return {
        name: "CourseIntroduction",
        params: { courseId: this.course.id },
      };
    }
    return {
      name: "LessonStart",
      params: { lessonId: `${this.lesson.id}` },
    };
  }

  private get btnText() {
    if (this.introVideo && !this.course.user_has_started) {
      return `Bekijk`;
    } else if (this.introVideo && this.course.user_has_started) {
      return `Terugkijken`;
    }
    switch (this.lesson.status) {
      case LessonStatus.CLOSED:
        return `Start`;
      case LessonStatus.OPEN:
        return `Start`;
      case LessonStatus.IN_PROGRESS:
        return `Verdergaan`;
      case LessonStatus.FINISHED:
        return `Terugkijken`;
      default:
        return `Start`;
    }
  }
  private get btnDisabled() {
    return this.lesson.status === LessonStatus.CLOSED;
  }
  private get btnOutlined() {
    return this.lesson.status === LessonStatus.FINISHED;
  }
}
</script>

<style scoped lang="scss">
</style>
