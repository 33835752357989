<template>
  <section>
    <div class="background-image">
      <div class="content-wrapper">
        <div class="video-wrapper">
          <YoutubeVideo :videoId="videoId" :autoplay="0" />
        </div>
        <div class="text-wrapper" v-html="text" v-if="text" />
      </div>
    </div>
  </section>
</template>


<script lang="ts">
import YoutubeVideo from "@/core/components/YoutubeVideo.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    YoutubeVideo,
  },
})
export default class Quote extends Vue {
  @Prop() private videoId!: string;
  @Prop({ type: String }) private text!: string;
}
</script>

<style scoped lang="scss">
section {
  background-color: $secondary-soft;
  border-radius: 1rem;
  padding: 1.25rem;
}
.background-image {
  background-image: url("../../assets/bg.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  padding: 6.5rem 4.5rem 6.5rem 3rem;
  margin: auto;
  max-width: 800px;
  width: 72%;
  @media screen and (max-width: $mobile) {
    padding: 3.5rem 2.5rem 3rem 2rem;
    width: 100%;
  }
}
.content-wrapper {
  width: 100%;
  margin: auto;
  text-align: center;
}
.video-wrapper {
  margin-bottom: 1rem;
}
.text-wrapper {
  margin-top: auto;
  margin-bottom: 1.5rem;
  font-size: 1.15rem;
  font-family: $headering-font-family;
}
</style>
