<template>
  <blockquote class="quote">
    <div class="quote__outer">
      <div class="quote__inner">
        <svg
          class="quote__icon"
          fill="currentColor"
          viewBox="0 0 32 32"
          aria-hidden="true"
        >
          <path
            d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
          ></path>
        </svg>
        <p>
          {{ quote }}
        </p>
      </div>
    </div>
    <cite class="quote__cite">
      <span class="name">{{ from }}</span>
      <span class="function">{{ formDetail }}</span>
    </cite>
  </blockquote>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Quote extends Vue {
  @Prop({ default: "Leerling", type: String }) private from!: string;
  @Prop({ default: "Van Maerlantlyceum, Eindhoven", type: String })
  private formDetail!: string;
  @Prop({
    default:
      "Het laat je gewoon veel, veel, veel dieper nadenken dan normale boekverslagen. En het geeft je ook inzicht buiten het verhaal zelf.",
    type: String,
  })
  private quote!: string;
}
</script>

<style scoped lang="scss">
.quote {
  display: flex;
  flex-direction: column;
  $borderRadius: 1rem;
  margin: 0 auto;
  &:first-child {
    margin-top: 0;
  }
  max-width: 640px;
  position: relative;
  background: $white;
  border-radius: $borderRadius;
  box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1),
    0 0px 0 1px rgba($scheme-invert, 0.02);

  $paddingTop: 2rem;
  $paddingX: 1.25 * $paddingTop;
  &__outer {
    padding: $paddingTop $paddingX 1rem $paddingX;
    border-radius: $borderRadius;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__inner {
    position: relative;
    color: $grey;
    font-weight: 600;
    font-size: 1.2rem;
  }
  &__icon {
    position: absolute;
    top: -1 * $paddingTop;
    left: -1 * $paddingX;
    width: 0.75 * $paddingX;
    height: 0.75 * $paddingX;
    color: $grey;
    opacity: 0.36;
    transform: translate(0.75rem, 0.5rem);
  }
  &__cite {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0.75rem $paddingX;
    border-radius: 0 0 $borderRadius $borderRadius;
    background: $primary;
    color: $white;
    font-style: normal;
    .name {
      font-weight: 600;
    }
    .function {
      opacity: 0.875;
    }
  }
}
</style>
