'use strict';

import { RootState } from '@/store/store.types';
import { ActionTree } from 'vuex';
import { LoginPost, LoginResponse, LoginState, TokenPayload, UpdateUserScoreNotification, User } from '@/login/login.types';
import vueInstance from '@/main';
import { loginApi } from '@/login/api/login.api';
import { state } from '@/login/store/login.state';
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { lessonApi } from '@/lesson/api/lesson.api';
import { ColorModifiers } from 'buefy/types/helpers';
import { StudentQuestionAnswerNotification } from '@/lesson/studentQuestion.types';

const LS_JWT_KEY = 'access_token';

export const actions: ActionTree<LoginState, RootState> = {
  SET_USER({ commit }, user: User) {
    commit('setUser', user);
  },
  async UPDATE_USER({ dispatch }) {
    try {
      const user = (await loginApi.me()).data.user;
      dispatch('SET_USER', user);
    } catch (err) {
      throw err;
    }
  },
  UPDATE_USER_SCORE({ commit }, score: number) {
    commit('updateUserScore', score);
  },
  SET_PCT({ commit }, token: string) {
    commit('setPCT', token);
  },
  RESET_USER({ commit }) {
    commit('resetUser');
  },
  SET_ACCESS_TOKEN({ commit }, token: string) {
    commit('accessToken', token);
  },
  CLEAR_ACCESS_TOKEN({ commit }) {
    commit('accessToken', undefined);
  },
  CLEAR_PCT({ commit }) {
    commit('setPCT', undefined);
  },

  async LOGIN({ dispatch }, credentials: LoginPost): Promise<void> {
    try {
      const response: LoginResponse = (await loginApi.login(credentials)).data;
      localStorage.setItem(LS_JWT_KEY, response.token);
      dispatch('SET_ACCESS_TOKEN', response.token);
      dispatch('PARSE_ACCESS_TOKEN');
      dispatch('START_PUSHER_CONNECTION', state.pct);
      vueInstance.$buefy.notification.open({
        duration: 8000,
        message: `Gelukt! Je bent ingelogd.`,
        position: "is-bottom-right",
        type: "is-success",
        hasIcon: true,
      });
    } catch (err) {
      throw err;
    }
  },

  async LOGOUT({ dispatch }) {
    localStorage.removeItem(LS_JWT_KEY);
    dispatch('STOP_PUSHER_CONNECTION');
    dispatch('RESET_USER');
    dispatch('CLEAR_ACCESS_TOKEN');
    dispatch('CLEAR_PCT');
    try {
      await loginApi.logout();
      vueInstance.$buefy.notification.open({
        duration: 6000,
        message: `Je bent uitgelogd.`,
        position: "is-bottom-right",
        type: "is-success",
        hasIcon: true,
      });
    } catch (err) {
      throw err;
    }
    if (vueInstance.$route.name !== 'Home') {
      vueInstance.$router.push({ name: 'Home' });
    }
  },

  PARSE_ACCESS_TOKEN({ dispatch }) {
    if (state.accessToken) {
      const payload = JSON.parse(atob(state.accessToken.split('.')[1])) as TokenPayload;
      dispatch('SET_USER', payload.usr);
      dispatch('SET_PCT', payload.pct);
    }
  },

  INIT({ dispatch }) {
    if (!state.accessToken) {
      const token = localStorage.getItem(LS_JWT_KEY);
      if (token) {
        dispatch('SET_ACCESS_TOKEN', token);
        dispatch('PARSE_ACCESS_TOKEN');
        dispatch('UPDATE_USER');
        dispatch('START_PUSHER_CONNECTION', state.pct);
      }
    }
  },

  START_PUSHER_CONNECTION({ dispatch }, token: string) {
    window.Pusher = Pusher;
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      authEndpoint: process.env.VUE_APP_API_URL + "authorize-private-channel",
      auth: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    });
    // Start listening for notifications
    dispatch('START_LISTENING_FOR_STUDENT_QUESTION_ANSWER_NOTIFICATIONS');
    dispatch('START_LISTENING_FOR_SCORE_UPDATES');
  },

  STOP_PUSHER_CONNECTION({ getters }) {
    const userId = getters.user.id;
    window.Echo.leave(`user-channel.${userId}`);
  },

  START_LISTENING_FOR_STUDENT_QUESTION_ANSWER_NOTIFICATIONS({ getters }) {
    const userId = getters.user.id;
    const privateChannel = window.Echo.private(
      `user-channel.${userId}`
    );
    privateChannel.listen(
      ".question.answered",
      (data: StudentQuestionAnswerNotification) => {
        const notification = vueInstance.$buefy.notification.open({
          duration: 12000,
          pauseOnHover: true,
          message: data.message,
          position: "is-bottom-right",
          type: data.type,
          hasIcon: true,
          icon: "message-badge-outline",
          queue: true,
        });
        // Click opens answer, on modal if not on Lesson page
        notification.$on("click", async () => {
          // Check if route is lesson
          if (vueInstance.$route.name === "Lesson") {
            // TODO Check for same Lesson ID
            // open side bar
            vueInstance.$root.$emit("questionAnswered", data);
          } else {
            // if not, open buefy modal with question
            try {
              const student_question = await lessonApi.getStudentQuestion(
                data.student_question_id
              );
              vueInstance.$buefy.dialog.confirm({
                title: data.message,
                message: `<p><strong>Jouw vraag</strong><br/>
              ${student_question.question_text}</p>
              <p><strong>Het antwoord</strong><br/>
                ${student_question.answers.find((a) => {
                  return a.id === data.student_question_answer_id;
                })?.answer_text
                  }
              </p>`,
                confirmText: "Ga naar deze les",
                type: "is-success" as ColorModifiers,
                onConfirm: () => {
                  vueInstance.$router
                    .push({
                      name: "Lesson",
                      params: {
                        lessonId: data.lesson_id,
                      },
                    })
                    .then(() => {
                      vueInstance.$root.$emit("questionAnswered", data);
                    });
                },
              });
            } catch (error) {
              throw error;
            }
          }
        });
      }
    );
  },

  START_LISTENING_FOR_SCORE_UPDATES({ dispatch, getters }) {
    const userId = getters.user.id;
    const privateChannel = window.Echo.private(
      `user-channel.${userId}`
    );
    privateChannel.listen(
      ".score.updated",
      (data: UpdateUserScoreNotification) => {
        dispatch('UPDATE_USER_SCORE', data.score);
        vueInstance.$buefy.notification.open({
          duration: 12000,
          pauseOnHover: true,
          message: data.message,
          position: "is-bottom-right",
          type: data.type,
        });
      }
    );
  },

  async LOAD_REGISTRATION_STATUS({ commit }) {
    try {
      const status = await loginApi.registrationStatus();
      commit('setRegistrationStatus', status);
    } catch (err) {
      // throw err;
    }
  }
};
