<template>
  <LoginContainer>
    <template>
      <ContentLayout>
        <template #title>
          <slot name="title">Nieuw wachtwoord kiezen</slot>
        </template>
        <template #subtitle>
          <slot name="subtitle">
            Kies hier een nieuw wachtwoord. Zorg dat je wachtwoord uit minimaal
            12 tekens bestaat.
          </slot>
        </template>
        <template>
          <form>
            <b-field
              label="Wachtwoord"
              :type="!passwordError ? '' : 'is-danger'"
              :message="passwordMsg"
            >
              <b-input
                icon="lock"
                type="password"
                v-model="password"
                @blur="passwordCheck()"
                placeholder="••••••••"
                password-reveal
              />
            </b-field>
            <b-field
              label="Herhaal wachtwoord"
              :type="!password2Error ? '' : 'is-danger'"
              :message="password2Msg"
            >
              <b-input
                icon="lock"
                type="password"
                v-model="password2"
                @blur="password2Check()"
                placeholder="••••••••"
                password-reveal
              />
            </b-field>
            <b-button
              @click="submit()"
              type="is-primary"
              icon-right="chevron-right"
              expanded
              rounded
            >
              <slot name="btn"> Wijzig wachtwoord </slot>
            </b-button>
          </form>
        </template>
      </ContentLayout>
    </template>
  </LoginContainer>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import LoginContainer from "@/login/layout/LoginContainer.vue";
import ContentLayout from "@/login/layout/ContentLayout.vue";
import { loginApi } from "@/login/api/login.api";
import { NewPasswordPost } from "@/login/login.types";

@Component({
  components: {
    LoginContainer,
    ContentLayout,
  },
})
export default class NewPassword extends Vue {
  @Prop({
    default: "Gelukt! Je kan nu direct inloggen met je nieuwe wachtwoord.",
    type: String,
  })
  private succesMessage!: string;

  private password = "";
  private password2 = "";

  private passwordError = false;
  private password2Error = false;

  private passwordMsg = "";
  private password2Msg = "";

  private token = "";

  private beforeMount() {
    if (typeof this.$route.query.token !== "undefined") {
      this.token = this.$route.query.token.toString();
    } else {
      this.$router.push({ name: "Login" });
      this.$buefy.notification.open({
        indefinite: true,
        message: `Er ging helaas wat mis. Kopieer en plak de link uit je mail.`,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
    }
  }

  private passwordCheck() {
    if (this.password === "") {
      this.passwordError = true;
      this.passwordMsg = "Kies een wachtwoord.";
      return true;
    } else if (this.password.length < 12) {
      this.passwordError = true;
      this.passwordMsg = "Je wachtwoord moet uit minimaal 12 tekens bestaan.";
      return true;
    } else {
      this.passwordError = false;
      this.passwordMsg = "";
      return false;
    }
  }

  private password2Check() {
    if (this.password2 === "") {
      this.password2Error = true;
      this.password2Msg = "Vul nogmaals je wachtwoord in.";
      return true;
    } else if (this.password !== this.password2) {
      this.password2Error = true;
      this.password2Msg = "De wachtwoorden komen niet overeen.";
      return true;
    } else {
      this.password2Error = false;
      this.password2Msg = "";
      return false;
    }
  }

  private get badInput() {
    let error = false;
    if (this.passwordCheck()) {
      error = true;
    }
    if (this.password2Check()) {
      error = true;
    }
    return error;
  }

  private async submit() {
    if (!this.badInput) {
      const data: NewPasswordPost = {
        token: this.token,
        password: this.password,
        password_confirmation: this.password2,
      };
      try {
        await loginApi.newPassword(data);
        this.$buefy.notification.open({
          duration: 9000,
          message: `Gelukt! Je kan nu direct inloggen met je nieuwe wachtwoord.`,
          position: "is-bottom-right",
          type: "is-success",
          hasIcon: true,
        });
        this.$router.push({ name: "Login" });
      } catch (err) {
        this.$buefy.notification.open({
          indefinite: true,
          message: `Er ging helaas wat mis. Probeer het later nog eens of neem contact met ons op.`,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: true,
        });
        throw err;
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
