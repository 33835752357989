<template>
  <HelperLayout
    :text="lesson.finish_page_html"
    :to="{ name: 'Course', params: { courseId: lesson.course_id } }"
    :btnText="'Naar levels'"
    :back="{ name: 'Lesson', params: { lessonId: lesson.id } }"
  />
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import HelperLayout from "@/lesson/layout/HelperLayout.vue";
import { Getter } from "vuex-class";
import { Lesson } from "@/lesson/lesson.types";

@Component({
  components: {
    HelperLayout,
  },
})
export default class FinishView extends Vue {
  @Getter("lesson", { namespace: "lesson" })
  private lesson!: Lesson;
}
</script>

<style scoped lang="scss">
</style>
