import store from '@/store';
import { Route } from 'vue-router';
import vueInstance from '@/main';
import { Lesson, LessonStatus } from '../lesson.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function lessonGuard(to: Route, from: Route, next: (param?: any) => void): Promise<void> {
  // store.registerModule('lesson', lessonStore);
  await store.dispatch('lesson/LOAD_LESSON', to.params.lessonId);
  const lesson = store.getters['lesson/lesson'] as Lesson;
  if (lesson.status === LessonStatus.CLOSED) {
    vueInstance.$router.push({ name: 'Course', params: { courseId: lesson.course_id } });
  }
  next();
}
