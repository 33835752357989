'use strict';

import { RootState } from '@/store/store.types';
import { GetterTree } from 'vuex';
import { CourseState, Course } from '@/course/course.types';

export const getters: GetterTree<CourseState, RootState> = {
  course(state: CourseState): Course | undefined {
    return state.course;
  },
};
