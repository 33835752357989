<template>
  <BlockBase
    :block="block"
    :checked="answerChecked"
    :edited="isEditedAfterComplete"
    :answer="answer"
    :answerBtnHint="answerBtnHint"
    :ttsText="ttsText"
    @answerCorrectResponse="setCorrectAnswer"
  >
    <template v-slot="{ isEnabled, isComingUp, isDoneTrying }">
      <p class="explainer">{{ explainer }}</p>

      <b-field class="list">
        <b-checkbox
          v-for="item in block.checklist_items"
          :key="item.key"
          v-model="answerKey"
          :native-value="item.key"
          :disabled="!isEnabled || isComingUp || isDoneTrying"
          size="is-medium"
          :type="getType(item.key)"
          :indeterminate="getType(item.key) === 'is-warning'"
          :class="{ 'is-success': isCorrect(item.key) }"
        >
          <span>{{ item.text }}</span>
        </b-checkbox>
      </b-field>
    </template>
  </BlockBase>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  AnswerReply,
  ChecklistBlock,
  ChecklistItem,
} from "@/lesson/block.types";
import BlockBase from "@/lesson/components/BlockBase.vue";

@Component({
  components: {
    BlockBase,
  },
})
export default class ChecklistBlockComponent extends Vue {
  @Prop() private block!: ChecklistBlock;

  private get ttsText() {
    return `
            ${this.block.content}.\n 
            ${this.block.question}.\n 
            ${this.explainer} \n
            ${
              this.block.checklist_items
                ? this.block.checklist_items
                    .map((item, i) => {
                      return "Optie " + (i + 1) + ": " + item.text;
                    })
                    .join(`. \n`)
                : ""
            }.\n 
            Jouw gekozen antwoord is: \n
            ${
              this.answer && this.answer.length > 0
                ? this.answer.map((i) => " " + i.text)
                : "Nog geen gekozen"
            }.\n
          `;
  }

  private get answer() {
    if (this.block.checklist_items) {
      // From all items, only return the ones that are in the answerKey
      return this.block.checklist_items.filter((item) =>
        this.answerKey.includes(item.key)
      );
    }
    return undefined;
  }

  private get explainer() {
    if (this.block.show_amount_correct && this.block.amount_correct) {
      return `Er zijn ${this.block.amount_correct} antwoorden juist.`;
    }
    return "Één of meerdere antwoorden zijn juist.";
  }

  private answerKey: string[] = [];

  private beforeMount() {
    if (this.block.latest_answer) {
      this.answerKey = (this.block.latest_answer.answer as ChecklistItem[]).map(
        (item) => item.key
      );
      if (this.block.correct_answers) {
        this.correctKeys = (this.block.correct_answers as ChecklistItem[]).map(
          (item) => item.key
        );
      }
    }
  }

  private get isEditedAfterComplete() {
    if (
      this.answerKey.length !==
        (this.block.latest_answer?.answer as ChecklistItem[] | undefined)
          ?.length ||
      !this.answerKey.every((key) =>
        (this.block.latest_answer?.answer as ChecklistItem[] | undefined)?.some(
          (item) => key === item.key
        )
      )
    ) {
      return true;
    }
    return false;
  }

  private get answerChecked() {
    if (this.answerKey.length === 0) {
      return false;
    }
    if (!this.answer) {
      return false;
    }
    if (
      this.block.show_amount_correct &&
      this.block.amount_correct &&
      this.answerKey.length !== this.block.amount_correct
    ) {
      return false;
    }
    return true;
  }

  private get answerBtnHint() {
    if (
      this.block.show_amount_correct &&
      this.block.amount_correct &&
      this.answerKey.length !== this.block.amount_correct
    ) {
      return `Selecteer ${this.block.amount_correct} antwoorden`;
    }
    if (!this.answerChecked) {
      return "Selecteer één of meerdere antwoorden";
    }
    return "";
  }
  private isCorrect(key: string) {
    if (this.correctKeys === undefined) {
      return false;
    }
    return this.correctKeys.includes(key);
  }

  private getType(key: string) {
    if (this.correctKeys === undefined && !this.block.complete) {
      return "is-primary";
    }
    const keyACorrectKey = this.isCorrect(key);
    if (keyACorrectKey && !this.answerKey.includes(key)) {
      return "is-warning";
    }
    if (keyACorrectKey) {
      return "is-success";
    }
    if (
      this.correctKeys !== undefined &&
      !keyACorrectKey &&
      this.answerKey.includes(key)
    ) {
      return "is-danger";
    }
    return "is-primary";
  }

  private correctKeys: string[] | undefined = undefined;
  private setCorrectAnswer(response: AnswerReply) {
    if (response.correct_answer) {
      this.correctKeys = (response.correct_answer as ChecklistItem[]).map(
        (item) => item.key
      );
    }
  }
}
</script>

<style scoped lang="scss">
.explainer {
  font-style: italic;
  margin-bottom: 6px;
}
.list {
  width: fit-content;
  min-width: 33%;
  max-width: 100%;

  &:deep .field-body .field {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    .b-checkbox {
      min-height: 28px;
      &.checkbox.is-medium {
        font-size: 1.0675rem;
        .control-label {
          font-family: $headering-font-family;
          margin-top: 1px;
        }
      }
      &.checkbox.is-disabled:has(> input:checked),
      &.checkbox.is-disabled.is-success {
        opacity: 1 !important;
        color: $text;
      }
    }
  }
}
</style>
