<template>
  <article class="media">
    <div class="media-content">
      <div class="content">
        <p>
          <b-skeleton width="45%" height="19px" :active="loading" />
          <strong v-if="!loading">
            {{ question.owner.first_name }}
            {{ question.owner.last_name }}
            <span v-if="question.owner.type === 'Teacher'">
              <b-tooltip label="Vraag van een docent" position="is-top">
                <b-icon
                  icon="school"
                  type="is-light"
                  class="teacher-icon"
                  size="is-small"
                />
              </b-tooltip>
            </span>
          </strong>
          <br />
          <b-skeleton height="60px" :active="loading" />
          <span v-if="!loading">{{ question.question_text }}</span>
          <br />
          <b-skeleton width="80%" height="16px" :active="loading" />
          <small v-if="!loading" class="card-details">
            {{ question?.answers.length ?? 0 }}
            {{
              question?.answers.length > 1 || question?.answers.length === 0
                ? "antwoorden"
                : "antwoord"
            }}
            · {{ question.created_at }} ·
            {{ question.created_at_diff }}
          </small>
        </p>
      </div>
      <div
        v-if="question?.answers && question?.answers.length > 0"
        class="answers"
      >
        <article class="media" v-for="answer in answers" :key="answer.id">
          <div class="media-content">
            <div class="content">
              <p>
                <strong class="response-owner">
                  {{ answer.responder.first_name }}
                  {{ answer.responder.last_name }}
                  <span
                    v-if="
                      answer.responder.type === 'Teacher' ||
                      answer.responder.type === 'Admin'
                    "
                  >
                    <b-tooltip
                      label="Antwoord van een docent"
                      position="is-top"
                    >
                      <b-icon
                        icon="school"
                        type="is-light"
                        class="teacher-icon"
                        size="is-small"
                      />
                    </b-tooltip>
                  </span>
                </strong>
                <b-tag
                  type="is-primary"
                  rounded
                  v-if="answer.new"
                  class="new-tag"
                >
                  Nieuw
                </b-tag>
                <br />
                <span>{{ answer.answer_text }}</span>
                <br />
                <small class="card-details">{{ answer.created_at }}</small>
              </p>
            </div>
          </div>
        </article>
        <b-collapse
          :aria-id="'seeMoreForQ-' + question.id"
          class="see-more-btn"
          @click.native="clickedMore = true"
          v-if="showMoreAnswers"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'seeMoreForQ-' + question.id"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">
                Zie nog {{ question.answers.length - 2 }} antwoorden
              </p>
              <a class="card-header-icon">
                <b-icon :icon="'chevron-down'" />
              </a>
            </div>
          </template>
        </b-collapse>
      </div>

      <div v-else-if="loading" class="answers">
        <article class="media" v-for="j in 2" :key="j">
          <div class="media-content">
            <div class="content">
              <p>
                <b-skeleton width="50%" height="19px" :active="loading" />
                <br />
                <b-skeleton height="38px" :active="loading" />
                <br />
                <b-skeleton width="75%" height="16px" :active="loading" />
              </p>
            </div>
          </div>
        </article>
      </div>

      <div v-if="answerForm" class="answers">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <span class="response-owner write-self">
                  Schrijf een reactie
                </span>
                <br />
                <strong class="response-owner">
                  {{ user.first_name }} {{ user.last_name }}
                </strong>
                <br />
                <b-field class="answer-field">
                  <b-input
                    maxlength="480"
                    type="textarea"
                    placeholder="Schrijf een antwoord..."
                    class="answer-input"
                    @focus="answerFocused = true"
                    @blur="answerFocused = newAnswer != ''"
                    rows="1"
                    :class="{ 'answer-input--focused': answerFocused }"
                    :disabled="answerIsSend"
                    v-model="newAnswer"
                  />
                  <b-button
                    :type="answerIsSend ? 'is-success' : 'is-primary'"
                    class="send"
                    native-type="submit"
                    rounded
                    @click="postAnswer"
                    :disabled="answerIsSend || !newAnswerCheck"
                    :loading="loading || isSending"
                    :icon-left="answerIsSend ? 'check' : 'send-outline'"
                    size="is-medium"
                  >
                  </b-button>
                </b-field>
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </article>
</template>


<script lang="ts">
import { User } from "@/login/login.types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { lessonApi } from "../api/lesson.api";
import { StudentQuestion } from "../studentQuestion.types";

@Component
export default class StudentQuestionCard extends Vue {
  @Prop({ default: null }) public question!: StudentQuestion | null;
  @Prop({ default: false, type: Boolean }) public answerForm!: boolean;
  @Getter("user", { namespace: "login" })
  private user!: User;

  private answerIsSend = false;
  private isSending = false;
  private newAnswer = "";
  private answerFocused = false;
  private clickedMore = false;

  private get loading() {
    return this.question === null;
  }

  private get showMoreAnswers() {
    return (
      !this.clickedMore &&
      this.question?.answers &&
      this.question?.answers?.length > 3
    );
  }

  private get answers() {
    if (this.showMoreAnswers) {
      return this.question?.answers.slice(0, 2);
    }
    return this.question?.answers;
  }

  public async scroll(answer_id: string): Promise<void> {
    await this.$nextTick();
    this.question?.answers.forEach((a) => {
      if (a.id === answer_id) {
        a.new = true;
        this.$forceUpdate();
      }
    });
    await this.$nextTick();
    this.$el.scrollIntoView({ behavior: "smooth" });
  }

  private get newAnswerCheck() {
    if (this.newAnswer.length <= 0) {
      return false;
    }
    if (!this.question?.id) {
      return false;
    }
    return true;
  }

  private async postAnswer() {
    this.isSending = true;
    if (this.newAnswerCheck) {
      try {
        await lessonApi.postStudentQuestionAnswer(
          this.question?.id ?? "",
          this.newAnswer
        );
        this.answerIsSend = true;
      } catch (error: any) {
        this.errorNotification("PSQA3", error.response.data.message);
      }
      this.isSending = false;
    }
  }

  private errorNotification(errorNumber?: string, message = "") {
    if (message === "" || message === "Server Error" || true == true) {
      message = `Er ging helaas wat mis. Probeer het later nog eens. <br/>Error: <code>${errorNumber}</code>`;
    }
    this.$buefy.notification.open({
      indefinite: true,
      message: message,
      position: "is-bottom-right",
      type: "is-danger",
      hasIcon: true,
    });
  }
}
</script>

<style scoped lang="scss">
.media {
  scroll-margin-top: 12px;
  &:last-child:not(:only-child) {
    margin-bottom: 1rem !important;
  }
}
.question-owner {
  color: $secondary;
}
.answers {
  padding: 0 0 0 1.5rem;
  .response-owner {
    color: $secondary;
    &.write-self {
      font-size: 0.875rem;
    }
  }
  .teacher-icon {
    background: $secondary;
    border-radius: 100%;
    font-size: 14.5px;
    height: 1.2rem;
    width: 1.2rem;
    margin-left: 4px;
    &:deep .mdi {
      margin-left: -0.5px;
    }
  }
  .new-tag {
    margin-left: 0.75rem;
    transform: scale(0.875);
  }
}
.card-details {
  color: $primary;
  opacity: 0.8;
}
.answer-field {
  margin-top: 0.2rem;
  :deep .field {
    gap: 1.5rem;
  }
  .answer-input {
    width: 100%;
    margin-bottom: 0 !important;
    :deep textarea {
      height: 3rem;
      transition: 0.3s ease height;
    }
    &--focused :deep textarea {
      height: 6rem;
    }
  }
  .send {
    aspect-ratio: 1/1;
    width: max-content;
    height: 48px;
    padding: 0;
  }
}

.see-more-btn {
  margin-top: 1rem;
  .card-header {
    display: flex;
    align-content: stretch;
    border-radius: 8px;
    box-shadow: none;
    transition: background 0.2s ease, padding 0.2s ease;
    background: #e3d0b536;
    padding: 0;

    &:hover,
    &:focus-visible {
      background: #e3d0b564;
    }
  }
  .card-header-title,
  .card-header-icon {
    color: $secondary;
    font-family: $headering-font-family;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
}
</style>
