<template>
  <div class="l-container">
    <div class="l-container__left">
      <LoginBar>
        <slot />
      </LoginBar>
    </div>
    <div class="l-container__right" />
  </div>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LoginBar from "@/login/layout/LoginBar.vue";

@Component({
  components: {
    LoginBar,
  },
})
export default class LoginContainer extends Vue {
}
</script>

<style scoped lang="scss">
.l-container {
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: $fullhd) {
    grid-template-columns: 2fr 3fr;
  }

  &__right {
    background-color: $primary;
    background-image: url('../../assets/library.webp');
    background-position: center;
    background-size: cover;
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
}
</style>
