'use strict';

import { RootState } from '@/store/store.types';
import { ActionTree } from 'vuex';
import { CourseState, Course } from '@/course/course.types';
import { courseApi } from '@/course/api/course.api';

export const actions: ActionTree<CourseState, RootState> = {
  async LOAD_COURSE({ dispatch }, courseId: string) {
    try {
      const course = await courseApi.getCourse(courseId);
      dispatch('SET_COURSE', course);
    } catch (error) {
      throw error;
    }
  },
  SET_COURSE({ commit }, course: Course) {
    commit('setCourse', course);
  },
  REMOVE_COURSE({ commit }) {
    commit('removeCourse');
  },
};
