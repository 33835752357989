<template>
  <MainLayout noTBpadding>
    <template #navbar>
      <Navbar :back="{ name: 'Course', params: { courseId: courseId } }">
        <template #title v-if="lesson">
          <span class="nav-title" v-if="lesson.order_no == 0">Proloog</span>
          <span class="nav-title" v-else>Level {{ lesson.order_no }}</span>
          <span class="nav-title">{{ lesson.name }}</span>
        </template>
        <template #title v-else>
          <span class="nav-title">{{ course.name }}</span>
        </template>
      </Navbar>
    </template>
    <div class="background-image">
      <div class="content-wrapper">
        <div class="video-wrapper" v-if="video && video.id">
          <YoutubeVideo
            :videoId="video.id"
            :begin="video.begin"
            :end="video.end"
            @ended="ended"
          />
        </div>
        <div class="text-wrapper" v-html="text" v-if="text" />
        <b-tooltip
          label="Je moet eerst de video afkijken."
          :delay="400"
          :active="btnDisabled"
          class="main-btn"
          v-if="to"
        >
          <b-button
            type="is-primary"
            size="is-medium"
            icon-right="chevron-right"
            rounded
            expanded
            :disabled="btnDisabled"
            @click="toNext()"
          >
            {{ btnText }}
          </b-button>
        </b-tooltip>
        <b-button
          class="second-btn"
          v-if="back"
          type="is-primary"
          size="is-medium"
          icon-left="chevron-left"
          rounded
          expanded
          outlined
          tag="router-link"
          :to="back"
        >
          Terugkijken
        </b-button>
      </div>
    </div>
  </MainLayout>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import MainLayout from "@/core/layout/MainLayout.vue";
import Navbar from "@/core/components/Navbar.vue";
import YoutubeVideo from "@/core/components/YoutubeVideo.vue";
import { Action, Getter } from "vuex-class";
import { Lesson, LessonStatus } from "@/lesson/lesson.types";
import { RawLocation } from "vue-router";
import { Course } from "@/course/course.types";
import { courseApi } from "@/course/api/course.api";
import { lessonApi } from "../api/lesson.api";

@Component({
  components: {
    MainLayout,
    Navbar,
    YoutubeVideo,
  },
})
export default class HelperLayout extends Vue {
  @Prop() private video!: { id: string; begin: number; end: number };
  @Prop({ type: String }) private text!: string;
  @Prop() private to!: RawLocation;
  @Prop() private back!: RawLocation;
  @Prop({ default: "btn", type: String }) private btnText!: string;
  @Prop({ default: false, type: Boolean }) private isCourse!: boolean;

  @Getter("lesson", { namespace: "lesson" })
  private lesson!: Lesson;
  @Getter("course", { namespace: "course" })
  private course!: Course;

  @Action("UPDATE_LESSON_STATUS", { namespace: "lesson" })
  private updateLessonStatus!: (LessonStatus: LessonStatus) => void;

  private btnDisabled = true;

  private async ended() {
    this.btnDisabled = false;
    if (this.isCourse) {
      try {
        await courseApi.postCourseStarted(this.course.id);
      } catch (er) {
        // fail
      }
    }
  }

  private beforeMount() {
    if (!this.video?.id) {
      this.btnDisabled = false;
    } else if (this.isCourse && this.course.user_has_started) {
      this.btnDisabled = false;
    } else if (this.lesson && this.lesson.status !== LessonStatus.OPEN) {
      this.btnDisabled = false;
    }
  }

  private get courseId() {
    if (this.isCourse) {
      return this.course.id;
    }
    return this.lesson.course_id;
  }

  private async toNext() {
    if (
      !this.isCourse &&
      this.lesson &&
      this.lesson.status === LessonStatus.OPEN
    ) {
      try {
        await lessonApi.postLessonInProgress(this.lesson.id);
      } catch (er: any) {
        this.$buefy.notification.open({
          message: er.response.data.message,
          type: "is-danger",
          position: "is-bottom-right",
          duration: 5000,
          hasIcon: true,
        });
      }
    }
    this.$router.push(this.to);
    this.updateLessonStatus(LessonStatus.IN_PROGRESS);
  }
}
</script>

<style scoped lang="scss">
.background-image {
  background-image: url("../../assets/bg.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  aspect-ratio: 1100 / 808;
  padding: 6.5rem 4.5rem 6.5rem 3rem;
  margin: 1rem 0;
}
.content-wrapper {
  width: 100%;
  margin: auto;
  text-align: center;
  aspect-ratio: 980 / 580;
  overflow: auto;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.video-wrapper {
  margin-bottom: 1.25rem;
}
.text-wrapper {
  margin-top: auto;
  margin-bottom: 1.75rem;
  font-size: 1.15rem;
  font-family: $headering-font-family;
}
.main-btn {
  min-width: 48%;
}
.second-btn {
  margin: 1.5rem auto;
  min-width: max-content;
  max-width: 48%;
}
</style>
