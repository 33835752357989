<template>
  <section class="cta-area">
    <div class="left">
      <h2 v-if="title !== ''">{{ title }}</h2>
      <h3 v-if="subtitle !== ''">{{ subtitle }}</h3>
    </div>
    <div class="right">
      <b-button
        type="is-primary"
        icon-right="download"
        rounded
        size="is-medium"
        tag="a"
        :href="to"
        target="_blank"
        v-if="download"
      >
        {{ buttonText }}
      </b-button>
      <b-button
        type="is-primary"
        icon-right="chevron-right"
        rounded
        size="is-medium"
        tag="router-link"
        :to="{ name: to }"
        v-else
      >
        {{ buttonText }}
      </b-button>
    </div>
  </section>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CtaArea extends Vue {
  @Prop({ default: "", type: String }) private title!: string;
  @Prop({ default: "", type: String }) private subtitle!: string;
  @Prop({ default: "Klik!", type: String }) private buttonText!: string;
  @Prop({ default: "!", type: String }) private icon!: string;
  @Prop({ default: "Home", type: String }) private to!: string;
  @Prop({ default: false, type: Boolean }) private download!: boolean;
}
</script>

<style scoped lang="scss">
.cta-area {
  background: $secondary-soft;
  width: 100%;
  display: flex;
  padding: 2rem 10%;
  align-content: center;
  justify-content: space-between;
  border-radius: 1rem;
  @media screen and (max-width: $desktop) {
    gap: 1rem;
    flex-direction: column;
  }
  .left {
    align-self: center;
  }
  .right {
    align-self: end;
  }
}
</style>
