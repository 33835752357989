<template>
  <BlockBase
    :block="block"
    :checked="true"
    :edited="isEditedAfterComplete"
    :answer="answer"
    :answerBtnHint="''"
    :ttsText="ttsText"
    @answerCorrectResponse="setCorrectAnswer"
  >
    <template v-slot="{ isEnabled, isComingUp, isDoneTrying }">
      <p class="explainer">{{ explainer }}</p>
      <div class="put-next">
        <div class="position">
          <span
            class="radio-letter"
            v-for="(item, i) in answer"
            :key="'pos' + i"
          >
            {{ i + 1 }}.
          </span>
        </div>
        <b-field class="choice-items">
          <draggable
            class="field-body"
            v-model="answer"
            v-bind="dragOptions"
            :disabled="!isEnabled || isComingUp || isDoneTrying"
          >
            <transition-group class="field">
              <b-radio-button
                v-for="item in answer"
                :key="item.key"
                v-model="empty"
                :native-value="item.key"
                :disabled="!isEnabled || isComingUp || isDoneTrying"
                :class="getType"
              >
                <span class="radio-letter mdi mdi-drag-horizontal-variant" />
                <span v-html="item.text" />
              </b-radio-button>
            </transition-group>
          </draggable>
        </b-field>
      </div>

      <!-- Correct answer -->
      <transition name="fade">
        <div class="correct-answer-container" v-if="correctAnswer">
          <p class="explainer has-text-success">Het goede antwoord is:</p>
          <div class="put-next">
            <div class="position">
              <span
                class="radio-letter"
                v-for="(item, i) in answer"
                :key="'pos' + i"
              >
                {{ i + 1 }}.
              </span>
            </div>
            <b-field class="choice-items">
              <draggable
                class="field-body"
                v-model="answer"
                v-bind="dragOptions"
                :disabled="true"
              >
                <transition-group class="field">
                  <b-radio-button
                    v-for="item in correctAnswer"
                    :key="'correct-' + item.key"
                    v-model="empty"
                    :native-value="'correct-' + item.key"
                    class="is-success"
                  >
                    <span
                      class="radio-letter mdi mdi-drag-horizontal-variant"
                    />
                    <span v-html="item.text" />
                  </b-radio-button>
                </transition-group>
              </draggable>
            </b-field>
          </div>
        </div>
      </transition>
    </template>
  </BlockBase>
</template>


<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AnswerReply, RankingBlock, RankItem } from "@/lesson/block.types";
import BlockBase from "@/lesson/components/BlockBase.vue";
import Draggable from "vuedraggable";

@Component({
  components: {
    BlockBase,
    Draggable,
  },
})
export default class RankingBlockComponent extends Vue {
  @Prop() private block!: RankingBlock;
  private explainer = "Zet de volgende items in de juiste volgorde: ";

  private get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost",
    };
  }

  private get ttsText() {
    return `
            ${this.block.content}.\n 
            ${this.block.question}.\n 
            ${this.explainer}\n
            Jij hebt het op de volgende volgorde staan: \n
            ${this.block.rank_items
              .map((item, i) => {
                return "Op plek " + (i + 1) + ". " + item.text;
              })
              .join(". ")}.\n 
          `;
  }

  private answer: RankItem[] = [];
  private empty = "";
  @Watch("empty")
  private onEmptyChange() {
    this.$nextTick(() => {
      this.empty = "";
    });
  }

  private beforeMount() {
    if (this.block.latest_answer) {
      this.answer = this.block.latest_answer.answer as RankItem[];
      if (this.block.correct_answers) {
        this.correctAnswer = this.block.correct_answers as RankItem[];
      }
    } else {
      this.answer = this.block.rank_items;
    }
  }

  private get isEditedAfterComplete() {
    if (this.block.latest_answer) {
      return this.block.latest_answer.answer !== this.answer;
    }
    return false;
  }

  private get isCorrect() {
    return this.block.latest_answer?.correct;
  }

  private get getType() {
    if (
      this.block.latest_answer &&
      !this.isCorrect &&
      !this.isEditedAfterComplete
    ) {
      return "is-danger";
    }
    if (this.isCorrect) {
      return "is-success";
    }
    return "is-primary";
  }

  private correctAnswer: RankItem[] | null = null;
  private setCorrectAnswer(response: AnswerReply) {
    if (response.correct_answer && response.correct === false) {
      this.correctAnswer = response.correct_answer as RankItem[];
    }
  }
}
</script>

<style scoped lang="scss">
.explainer {
  font-style: italic;
  margin-bottom: 4px;
}
$f-gap: 0.625rem;
.put-next {
  display: flex;
  gap: 0.5rem;
  margin-top: 8px;
  .position {
    display: grid;
    grid-template-columns: max-content;
    gap: $f-gap;
    align-items: center;
    font-family: $headering-font-family;
  }
}
.choice-items {
  min-width: 33%;
  max-width: 100%;
  width: fit-content;

  &:deep .field-body .field {
    display: grid;
    grid-template-columns: 1fr;
    gap: $f-gap;
    .b-radio {
      justify-content: flex-start;
      border-radius: 6px !important;
      white-space: normal;
      text-align: start;
      height: max-content;

      &.radio[disabled].is-selected,
      &.radio[disabled].is-success {
        opacity: 1;
        color: #fff;
      }

      .radio-letter {
        margin-right: 12px;
        font-weight: 600;
        font-size: 1.15em;
      }
    }
    .control .b-radio {
      transition: background-color 0.25s ease;
    }
    .control.is-danger .b-radio,
    .control.is-success .b-radio {
      box-shadow: none;
      opacity: 1;
      color: #fff;
    }
    .control.is-danger .b-radio {
      background-color: hsl(348, 86%, 61%);
      border-color: transparent;
    }
    .control.is-success .b-radio {
      background-color: hsl(153, 53%, 53%);
      border-color: hsl(153, 53%, 53%);
    }
  }
  &--row {
    width: 100%;
    &:deep .field-body .field {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      gap: 1rem;
      .b-radio {
        min-height: 2em;
        height: 100%;
      }
    }
  }
}
.correct-answer-container {
  margin-top: 2rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.33s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
