import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { Component } from "vue-property-decorator";

import { navigationGuard } from '@/core/guards/core.guard';

import AppView from '@/App.vue';
import Home from '@/core/views/Home.vue';
// import Teacher from '@/core/views/Teacher.vue';
import Research from '@/core/views/Research.vue';
import ForTeachers from '@/core/views/ForTeachers.vue';
import TeachingMaterials from '@/core/views/TeachingMaterials.vue';

import { loginRoutes } from "@/login/login.routes";
import { courseRoutes } from "@/course/course.routes";
import { lessonRoutes } from "@/lesson/lesson.routes";

import { lessonGuard } from "@/lesson/guards/lesson.guard";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: "Home",
    component: Home,
    meta: {
      disableAuthentication: true,
    },
  },
  // {
  //   path: '/docent',
  //   name: "Teacher",
  //   component: Teacher,
  //   meta: {
  //     title: 'Docentenomgeving',
  //   },
  // },
  {
    path: '/onderzoek',
    name: "Research",
    component: Research,
    meta: {
      disableAuthentication: true,
      title: 'Onderzoek',
    },
  },
  {
    path: '/voor-docenten',
    name: "ForTeachers",
    component: ForTeachers,
    meta: {
      disableAuthentication: true,
      title: 'Voor docenten',
    },
  },
  {
    path: '/lesmateriaal',
    name: "TeachingMaterials",
    component: TeachingMaterials,
    meta: {
      disableAuthentication: true,
      title: 'Lesmateriaal',
    },
  },
  {
    path: '/login',
    children: loginRoutes,
    component: AppView,
    meta: {
      disableAuthentication: true,
    },
  },
  {
    path: '/levels',
    children: courseRoutes,
    component: AppView,
  },
  {
    path: '/les',
    children: lessonRoutes,
    beforeEnter: lessonGuard,
    component: AppView,
  },
  {
    path: '*',
    redirect: { name: 'Home' },
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];


Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    }
    else {
      return { x: 0, y: 0, behavior: 'auto' };
    }
  }
});

router.beforeEach(navigationGuard);
export default router;
