<template>
  <div
    class="block-layout"
    :class="{
      'block-layout--coming-up': isComingUp,
      'block-layout--disabled': isEnabled,
    }"
  >
    <div class="block-layout__buttons">
      <b-tooltip
        type="is-primary is-light"
        label="Vraag om wat extra hulp bij deze vraag."
        size="is-small"
        :delay="600"
        multilined
        v-if="block.hint_html"
      >
        <b-button
          type="is-primary"
          :outlined="!showHint"
          rounded
          icon-left="information-variant"
          @click="showHint = true"
          :active="showHint"
        >
          Hulp
        </b-button>
      </b-tooltip>
      <b-tooltip
        type="is-primary is-light"
        label="Text voorlezen."
        :delay="600"
      >
        <TTSControl small :text="speech" />
      </b-tooltip>
    </div>

    <BlockHint
      :showHint.sync="showHint"
      :hint_html="block.hint_html"
      :hint_items="block.hint_items"
      :blockId="block.id"
    />
    <div class="block-layout__content">
      <p v-html="block.content" v-if="block.content" />
      <p v-html="block.question" v-if="block.question" />
    </div>
    <div class="block-layout__input">
      <slot
        :isEnabled="isEnabled"
        :isComingUp="isComingUp"
        :isDoneTrying="isDoneTrying"
      />
    </div>
    <div class="block-layout__button-bar">
      <div
        :class="messageIsError ? 'has-text-danger' : 'has-text-success'"
        v-if="errorMessage"
      >
        <small> {{ errorMessage }}</small>
      </div>
      <slot name="button-bar" />
      <b-tooltip
        :active="answerBtnHint !== ''"
        :label="answerBtnHint"
        multilined
      >
        <b-button
          type="is-primary"
          rounded
          @click="giveAnswer()"
          :disabled="!checked || !isEnabled || isDone"
          :loading="sendingAnswer"
          :class="{ 'is-done': isDone && isEnabled }"
          :outlined="isDone"
          :icon-left="isDone ? 'check' : ''"
          v-if="isQuestionBlock"
        >
          {{ answerBtntxt }}
        </b-button>
      </b-tooltip>
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  AnswerPost,
  Block,
  BlockAnswerType,
  QuestionBlock,
  QuestionBlockType,
} from "@/lesson/block.types";
import TTSControl from "@/core/components/TTSControl.vue";
import BlockHint from "@/lesson/components/BlockHint.vue";
import { block } from "@/lesson/utils/exampleTypes";
import { Action, Getter } from "vuex-class";
import { Lesson, LessonStep, LessonStepType } from "@/lesson/lesson.types";
import { isBlockComingUp } from "@/lesson/utils/isBlockComingUp";
import { stepIsComplete } from "@/lesson/utils/stepIsComplete";
import { lessonApi } from "../api/lesson.api";

@Component({
  components: {
    TTSControl,
    BlockHint,
  },
})
export default class BlockBase extends Vue {
  @Prop({
    default() {
      return block;
    },
  })
  private block!: Block;
  @Prop({ default: "error", type: String }) private ttsText!: string;
  @Prop({ default: true, type: Boolean }) private checked!: boolean;
  @Prop({ default: false, type: Boolean }) private edited!: boolean;
  @Prop({}) private answer!: BlockAnswerType;
  @Prop({ default: "", type: String }) private answerBtnHint!: string;

  @Getter("lesson", { namespace: "lesson" })
  private lesson!: Lesson;
  @Getter("currentStep", { namespace: "lesson" })
  private currentStep!: LessonStep;
  @Getter("progressPassedCurrentStep", { namespace: "lesson" })
  private progressPassedCurrentStep!: boolean;

  @Action("SET_BLOCK_ANSWER", { namespace: "lesson" })
  private setBlockAnswer!: (answerPost: AnswerPost) => void;
  @Action("PUSH_BLOCKS_IN_EDIT", { namespace: "lesson" })
  private pushBlocksInEdit!: (id: string) => void;
  @Action("REMOVE_BLOCKS_IN_EDIT", { namespace: "lesson" })
  private removeBlocksInEdit!: (id: string) => void;
  @Action("UPDATE_BLOCK", { namespace: "lesson" })
  private updateBlock!: (id: string) => void;

  private showHint = false;
  private sendingAnswer = false;
  private errorMessage = "";
  private messageIsError = true;

  private mounted() {
    if (this.isQuestionBlock) {
      this.showHint = (this.block as QuestionBlock).latest_answer?.used_hint
        ? true
        : false;
    }
  }

  private get speech() {
    if (this.ttsText) {
      return this.ttsText;
    }
    return `${block.content}.\n ${block.question ? block.question : ""}.`;
  }

  private get isEnabled() {
    return !this.progressPassedCurrentStep || !stepIsComplete(this.currentStep);
  }
  @Watch("isComingUp")
  private onIsComingUpChange() {
    const stepOfBlock = this.lesson.steps.find((s) => {
      return s.blocks.some((b) => {
        return b.id === this.block.id;
      });
    });

    if (
      stepOfBlock?.id === this.currentStep.id &&
      !this.block.complete &&
      this.block.depends_on_other_question
    ) {
      this.updateBlock(this.block.id);
    }
  }

  private get isComingUp() {
    return isBlockComingUp(this.block);
  }

  private get isDoneTrying() {
    // if block has an answer and step is a quiz, then it is done trying
    // if block has an CORRECT answer and block is a question block, then it is done trying
    return (
      this.block.complete &&
      (this.currentStep.type === LessonStepType.QUIZ ||
        (this.isQuestionBlock &&
          (this.block as QuestionBlock).latest_answer?.correct == true))
    );
  }

  private get isDone() {
    return this.block.complete && !this.edited;
  }

  private get isQuestionBlock() {
    return Object.values(QuestionBlockType).includes(
      this.block.block_type as QuestionBlockType
    );
  }

  private get answerBtntxt() {
    if (this.edited && this.block.complete) {
      return "Wijzig antwoord";
    } else if (
      this.isQuestionBlock &&
      (this.block as QuestionBlock).is_example === 1
    ) {
      return "Gelezen";
    } else if (this.block.complete) {
      return "Beantwoord";
    }
    return "Antwoord";
  }

  @Watch("edited")
  private onEditedChange() {
    if (this.edited) {
      this.resetErrorMessage();
      this.pushBlocksInEdit(this.block.id);
    } else if (this.block.complete) {
      this.removeBlocksInEdit(this.block.id);
    }
  }

  private resetErrorMessage() {
    this.errorMessage = "";
    this.messageIsError = true;
  }

  @Watch("answer")
  private onAnswerChange() {
    this.resetErrorMessage();
  }

  private async giveAnswer() {
    this.sendingAnswer = true;
    this.resetErrorMessage();
    try {
      // send to api
      const answerPost: AnswerPost = {
        block_id: this.block.id,
        used_hint: this.showHint,
        answer: this.answer,
      };
      const response = (await lessonApi.postAnswer(answerPost)).data;
      if (response.correct !== undefined) {
        this.errorMessage = response.message;
        answerPost.correct = response.correct;
        if (response.correct) {
          this.messageIsError = false;
        }
        this.$emit("answerCorrectResponse", response);
      }

      // see reply set block complete through action.
      // Set answer in store
      this.setBlockAnswer(answerPost);
    } catch (error: any) {
      // present error message
      this.errorMessage = error.response.data.message;
    }
    this.sendingAnswer = false;
  }
}
</script>

<style scoped lang="scss">
.block-layout {
  position: relative;
  padding: 1.5rem 0;
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
  transition: opacity 0.2s ease;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    top: 100%;
    height: 1px;
    background: $secondary-dark;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
  &--coming-up {
    opacity: 0.24;
    pointer-events: none;
    user-select: none;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-bottom: 4px;
    :deep .button.button--circle {
      width: 0;
    }
    :deep .button .mdi-information-variant {
      font-size: 1.375em;
    }
  }
  &__hint :deep .collapse-content {
    margin: 0.625rem 2.5rem;
  }
  &__input {
    padding-top: 0.75rem;
    padding-bottom: 1rem;
    :deep .field:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  &__button-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    div {
      display: flex;
      gap: 3px;
      font-family: $headering-font-family;
      font-size: 1.125rem;
    }
    .button[disabled].is-done {
      opacity: 1;
    }
  }

  &__content :deep {
    h1 {
      font-size: 1.6em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.375em;
    }
    h4 {
      font-size: 1.25em;
    }
    h5 {
      font-size: 1.15em;
    }
    h6 {
      font-size: 1.1em;
    }
    .attachment {
      display: flex;
      justify-content: center;
      &figure img,
      &.attachment--jpg img,
      &.attachment--png img {
        border-radius: 8px;
        max-width: 720px;
        width: 85%;
        @media screen and (max-width: $desktop) {
          width: 100%;
        }
      }
      a {
        display: flex;
        justify-content: center;
        cursor: default;
        pointer-events: none;
        .attachment__caption:not(.attachment__caption--edited) {
          display: none;
        }
      }
    }
  }
}
</style>
