'use strict';

import { Module } from 'vuex';
import { RootState } from '@/store/store.types';
import { LessonState } from '@/lesson/lesson.types';
import { actions } from '@/lesson/store/lesson.actions';
import { mutations } from '@/lesson/store/lesson.mutations';
import { getters } from '@/lesson/store/lesson.getters';

const namespaced = true;
export const state: LessonState = {
  progressPassedCurrentStep: false,
  blocksInEdit: [],
};

export const lessonStore: Module<LessonState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
