<template>
  <ArticleLayout>
    <template #header>
      <div class="header">
        <div>
          <h1>Voor docenten</h1>
        </div>
      </div>
    </template>
    <ImageWithText
      :image="require(`@/assets/images/86.jpg`)"
      alt="Leerling die bezig is met het lesmateriaal"
    >
      <p>
        Docenten Nederlands weten dat het lezen van literatuur belangrijk is
        voor leerlingen. Het heeft niet alleen een positief effect op hun
        taalvaardigheid, maar verruimt ook hun denken. Historische literatuur
        heeft die voordelen ook, maar doet nog iets extra’s: het kan bijdragen
        aan de culturele ontwikkeling en het historisch besef van leerlingen. Om
        die potentie ook daadwerkelijk te benutten, ontwikkelde ik in mijn
        promotieonderzoek een redeneerdidactiek die leerlingen leert nadenken
        over historische literatuur.
      </p>
    </ImageWithText>
    <CtaArea
      :title="'Meer weten over deze didactiek?'"
      :subtitle="'Download het hier.'"
      :to="require(`@/assets/pdfs/Informatie voor docenten.pdf`)"
      :buttonText="'Download hier'"
      download
    />
    <ImageWithText
      title="Literair en zakelijk lezen gecombineerd"
      :image="require(`@/assets/images/79.jpg`)"
      alt="Groep kinderen achter de laptop met de docent die toekijkt"
      reverse
      style="margin-bottom: 3rem"
    >
      <p>
        Tijdens het redeneren gebruiken leerlingen aangereikte niet-literaire
        hulpbronnen om hun redenering bij te stellen of te verscherpen. Uit het
        onderzoek bleek dat de inzet van deze bronnen zorgde voor een duidelijke
        kwaliteitsverbetering van de antwoorden. Vrijwel alle leerlingen hadden
        de juiste boodschap uit de bronnen gehaald, wat betekent dat zij tot
        diep lezen zijn gekomen. Het inzetten van niet-literaire teksten om de
        redenering te verbeteren is een betekenisvolle activiteit: het lezen
        heeft een duidelijk doel. Leerlingen zijn daardoor eerder bereid de
        inspanning te leveren die nodig is voor diep lezen. De redeneerdidactiek
        biedt daarmee ook interessante mogelijkheden voor het
        leesvaardigheidsonderwijs.
      </p>
    </ImageWithText>
    <ImageWithText
      :image="require(`@/assets/images/61.jpg`)"
      alt="Groep kinderen achter de laptop met de docent die toekijkt"
      style="margin-top: 3rem"
    >
      <p>
        In de loop van het onderzoek ontwikkelde ik op basis van de
        redeneerdidactiek verschillende lesmaterialen die via ‘Lesmateriaal’ op
        deze website beschikbaar zijn voor docenten. Het gaat om opdrachten en
        lesideeën voor workshops, lezingen en gastcolleges. Dit materiaal is
        gemaakt voor zowel de onderbouw- als bovenbouw van vmbo, havo en vwo en
        richt zich op diverse vormen van literatuur. Ook is een analoge versie
        van de lessenreeks over De donkere kamer van Damokles beschikbaar,
        gemaakt voor de bovenbouw van het vwo. Bij deze lessenreeks wordt het
        papieren boek gebruikt.
      </p>
    </ImageWithText>
    <CtaArea
      :title="'Wil je eens rondkijken in het lesmateriaal?'"
      :subtitle="'Vraag hier je account aan.'"
      :to="'NewAccount'"
    />

    <ContactForm />
  </ArticleLayout>
</template>

<script lang="ts">
import ContactForm from "@/core/components/ContactForm.vue";
import CtaArea from "@/core/components/CtaArea.vue";
import ImageWithText from "@/core/components/ImageWithText.vue";
import Navbar from "@/core/components/Navbar.vue";
import VDivider from "@/core/components/VDivider.vue";
import ArticleLayout from "@/core/layout/ArticleLayout.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ArticleLayout,
    Navbar,
    VDivider,
    CtaArea,
    ContactForm,
    ImageWithText,
  },
})
export default class ForTeachers extends Vue {}
</script>


<style scoped lang="scss">
.vdivider {
  @media screen and (max-width: $desktop) {
    display: none;
  }
}
.header {
  max-width: $widescreen + 100px;
  display: grid;
  // grid-template-columns: 1fr 1fr;
  gap: 32px;
  justify-items: center;
  margin: 0 auto;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }
  iframe {
    max-width: 100%;
    border-radius: 8px;
  }
}
ul {
  list-style-type: disc;
  margin: 1rem 1.675rem;
}
</style>
