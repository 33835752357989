<template>
  <section class="image-text" :class="{ reverse: reverse }">
    <b-image
      class="feature-image"
      :src="image"
      :alt="alt"
      lazy
      :class="{ 'feature-image--portrait': portrait }"
    />
    <div>
      <h2 v-if="title">{{ title }}</h2>
      <slot>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur,
        doloribus provident distinctio nesciunt minus facere explicabo eveniet,
        nemo obcaecati dolore neque, fugiat tempora aut. Maiores similique
        placeat illo ullam numquam? Et libero corrupti alias ullam aliquid
        dolore, itaque voluptatem distinctio quasi est labore quisquam velit
        amet magnam architecto quidem! Labore laudantium, libero exercitationem
        tenetur ex repellendus autem qui cumque culpa! Cum dolorum quos
        corporis! Debitis, magnam. Molestiae repudiandae voluptas voluptatum
        tenetur vero, facilis dolores unde aliquid temporibus iure laborum
        distinctio consectetur eos! Dolorum consequatur doloribus doloremque
        nisi possimus soluta quidem? Dicta unde harum aliquam hic nostrum
        cupiditate aliquid reprehenderit eos odit!
      </slot>
    </div>
  </section>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ImageWithText extends Vue {
  @Prop() private title!: string | undefined;
  @Prop({
    default:
      "https://images.unsplash.com/photo-1532294220147-279399e4e00f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80",
    type: String,
  })
  private image!: string;
  @Prop({ default: "Leerling met boek", type: String }) private alt!: string;
  @Prop({ default: false, type: Boolean }) private portrait!: boolean;
  @Prop({ default: false, type: Boolean }) private reverse!: boolean;
}
</script>

<style scoped lang="scss">
.feature-image {
  max-height: 320px;
  max-width: 48%;
  border-radius: 1rem;
  overflow: hidden;
  margin: auto;
  display: flex;

  &--portrait {
    max-width: 20%;
    max-height: 256px;
  }

  @media screen and (max-width: $tablet) {
    max-width: 100%;

    &--portrait {
      max-width: 48%;
      max-height: 280px;
    }
  }
}
.image-text {
  display: flex;
  gap: 1rem;
  @media screen and (max-width: $desktop) {
    flex-direction: column;
  }
  div {
    padding: 0.5rem 1rem;
    width: fit-content;
  }
}
.reverse {
  flex-direction: row-reverse;
  @media screen and (max-width: $desktop) {
    flex-direction: column-reverse;
  }
}
</style>
