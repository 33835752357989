import { LessonBase } from "@/lesson/lesson.types";

export interface CourseState {
  course?: Course,
}

export interface Course extends CourseBase {
  lessons: LessonBase[],
  intro_video_id?: string;
  intro_video_begin_time?: number;
  intro_video_end_time?: number;
}

export interface CourseBase {
  id: string,
  name: string,
  image?: string,
  main_question?: string,
  description: string,
  user_has_started: boolean,
}

export enum SizeType {
  /** Dimensions are fixed */
  FIXED = "fixed",
  /** Dimensions are calculated based on the parent element */
  STRETCH = "stretch"
}