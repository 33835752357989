<template>
  <LoginContainer>
    <template>
      <ContentLayout>
        <template #title>Leuk dat je er bent!</template>
        <template #subtitle v-if="!lock">
          Vul je gegevens in om een account aan te maken
        </template>
        <template #subtitle v-else>
          Helaas kun je op dit moment geen account aanmaken. Wel kunnen je het
          onderstaande formulier invullen. We zullen dan contact met je opnemen.
        </template>
        <template>
          <form>
            <!-- Name -->
            <b-field
              label="Voornaam"
              :type="!fNameError ? '' : 'is-danger'"
              :message="fNameMsg"
            >
              <b-input
                icon="account"
                type="text"
                v-model="fName"
                @blur="fNameCheck()"
                placeholder="Voornaam"
              />
            </b-field>
            <b-field
              label="Achternaam"
              :type="!lNameError ? '' : 'is-danger'"
              :message="lNameMsg"
            >
              <b-input
                icon="account"
                type="text"
                v-model="lName"
                @blur="lNameCheck()"
                placeholder="Achternaam"
              />
            </b-field>
            <!-- Email -->
            <b-field
              label="Email"
              :type="!emailError ? '' : 'is-danger'"
              :message="emailMsg"
            >
              <b-input
                icon="email"
                type="username"
                v-model="username"
                @blur="emailCheck()"
                placeholder="leerling@school.nl"
              />
            </b-field>
            <!-- Password -->
            <b-field
              label="Wachtwoord"
              :type="!passwordError ? '' : 'is-danger'"
              :message="passwordMsg"
              v-if="!lock"
            >
              <b-input
                icon="lock"
                type="password"
                v-model="password"
                @blur="passwordCheck()"
                placeholder="••••••••"
                password-reveal
              />
            </b-field>
            <b-field
              label="Herhaal wachtwoord"
              :type="!password2Error ? '' : 'is-danger'"
              :message="password2Msg"
              v-if="!lock"
            >
              <b-input
                icon="lock"
                type="password"
                v-model="password2"
                @blur="password2Check()"
                placeholder="••••••••"
                password-reveal
              />
            </b-field>
            <!-- Message -->
            <b-field
              label="Waarom zou je een account willen?"
              :type="!messageError ? '' : 'is-danger'"
              :message="messageMsg"
              v-if="lock"
            >
              <b-input
                type="textarea"
                v-model="message"
                @blur="messageCheck()"
                placeholder="Wie ben je? Hoe kwam je op deze website?"
              />
            </b-field>
            <!-- Submit -->
            <b-button
              @click="register()"
              type="is-primary"
              icon-right="chevron-right"
              rounded
              expanded
            >
              Maak account aan
            </b-button>
          </form>
        </template>
      </ContentLayout>
    </template>
  </LoginContainer>
</template>


<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LoginContainer from "@/login/layout/LoginContainer.vue";
import ContentLayout from "@/login/layout/ContentLayout.vue";
import { loginApi } from "@/login/api/login.api";
import {
  NewAccountDetailsPost,
  NewAccountDetailsRequestPost,
} from "@/login/login.types";
import { Getter } from "vuex-class";

@Component({
  components: {
    LoginContainer,
    ContentLayout,
  },
})
export default class NewAccount extends Vue {
  @Getter("lockAccountRegistration", { namespace: "login" })
  private lock!: boolean;

  private fName = "";
  private lName = "";
  private username = "";
  private password = "";
  private password2 = "";
  private message = "";

  private fNameError = false;
  private lNameError = false;
  private emailError = false;
  private passwordError = false;
  private password2Error = false;
  private messageError = false;

  private fNameMsg = "";
  private lNameMsg = "";
  private emailMsg = "";
  private passwordMsg = "";
  private password2Msg = "";
  private messageMsg = "";

  private fNameCheck() {
    if (this.fName.length < 2) {
      this.fNameError = true;
      this.fNameMsg = "Vul hier je voornaam in.";
      return true;
    } else {
      this.fNameError = false;
      this.fNameMsg = "";
      return false;
    }
  }
  private lNameCheck() {
    if (this.lName.length < 2) {
      this.lNameError = true;
      this.lNameMsg = "Vul hier je (tussenvoegsel en) achternaam in.";
      return true;
    } else {
      this.lNameError = false;
      this.lNameMsg = "";
      return false;
    }
  }
  private emailCheck() {
    const reEmail = /\S+@\S+\.\S+/;
    if (this.username === "") {
      this.emailError = true;
      this.emailMsg = "Vul hier je (school-)emailadres in.";
      return true;
    } else if (!reEmail.test(this.username)) {
      this.emailError = true;
      this.emailMsg = "Dit is geen geldig emailadres";
      return true;
    } else {
      this.emailError = false;
      this.emailMsg = "";
      return false;
    }
  }

  private passwordCheck() {
    if (this.password === "") {
      this.passwordError = true;
      this.passwordMsg = "Kies een wachtwoord.";
      return true;
    } else if (this.password.length < 12) {
      this.passwordError = true;
      this.passwordMsg = "Je wachtwoord moet uit minimaal 12 tekens bestaan.";
      return true;
    } else {
      this.passwordError = false;
      this.passwordMsg = "";
      return false;
    }
  }

  private password2Check() {
    if (this.password2 === "") {
      this.password2Error = true;
      this.password2Msg = "Vul nogmaals je wachtwoord in.";
      return true;
    } else if (this.password !== this.password2) {
      this.password2Error = true;
      this.password2Msg = "De wachtwoorden komen niet overeen.";
      return true;
    } else {
      this.password2Error = false;
      this.password2Msg = "";
      return false;
    }
  }
  private messageCheck() {
    if (this.message.length < 2) {
      this.messageError = true;
      this.messageMsg = "Vul hier een bericht in.";
      return true;
    } else {
      this.messageError = false;
      this.messageMsg = "";
      return false;
    }
  }

  private get badInput() {
    let error = false;
    if (this.fNameCheck()) {
      error = true;
    }
    if (this.lNameCheck()) {
      error = true;
    }
    if (this.emailCheck()) {
      error = true;
    }
    if (this.passwordCheck() && !this.lock) {
      error = true;
    }
    if (this.password2Check() && !this.lock) {
      error = true;
    }
    if (this.messageCheck() && this.lock) {
      error = true;
    }
    return error;
  }

  private async register() {
    if (!this.badInput && !this.lock) {
      const data: NewAccountDetailsPost = {
        first_name: this.fName,
        last_name: this.lName,
        email: this.username,
        password: this.password,
        password_confirmation: this.password2,
      };
      try {
        await loginApi.register(data);
        this.$buefy.notification.open({
          duration: 9000,
          message: `Gelukt! Je kan nu direct inloggen.`,
          position: "is-bottom-right",
          type: "is-success",
          hasIcon: true,
        });
        this.$router.push({ name: "Login" });
      } catch (err) {
        this.$buefy.notification.open({
          indefinite: true,
          message: `Er ging helaas wat mis. Probeer het later nog eens of neem contact met ons op.`,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: true,
        });
        throw err;
      }
    } else if (!this.badInput && this.lock) {
      const data: NewAccountDetailsRequestPost = {
        first_name: this.fName,
        last_name: this.lName,
        email: this.username,
        message: this.message,
      };
      try {
        // send request
        await loginApi.requestAccount(data);
        this.$buefy.notification.open({
          duration: 9000,
          message: `Gelukt! Je hoort binnenkort van ons.`,
          position: "is-bottom-right",
          type: "is-success",
          hasIcon: true,
        });
        this.$router.push({ name: "Home" });
      } catch (err) {
        this.$buefy.notification.open({
          indefinite: true,
          message: `Er ging helaas wat mis. Probeer het later nog eens of neem contact met ons op.`,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: true,
        });
        throw err;
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
