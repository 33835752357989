'use strict';

import { MutationTree } from 'vuex';
import Vue from 'vue';
import { Lesson, LessonState, LessonStatus, LessonStep } from '@/lesson/lesson.types';
import { AnswerPost, Block, BlockAnswer } from '@/lesson/block.types';

export const mutations: MutationTree<LessonState> = {
  setLesson(state: LessonState, lesson: Lesson) {
    Vue.set(state, 'lesson', lesson);
  },
  removeLesson(state: LessonState) {
    Vue.set(state, 'lesson', undefined);
  },
  updateLessonStatus(state: LessonState, lessonStatus: LessonStatus) {
    if (state.lesson) {
      Vue.set(state.lesson, 'status', lessonStatus);
    }
  },

  setCurrentStep(state: LessonState, step: LessonStep) {
    Vue.set(state, 'currentStep', step);
  },
  setBlockAnswer(state: LessonState, answerPost: AnswerPost) {
    const block = state.currentStep?.blocks.find((b) => b.id === answerPost.block_id);
    if (block) {
      const latest_answer: BlockAnswer = {
        answer: answerPost.answer,
        used_hint: answerPost.used_hint,
        correct: answerPost.correct,
      };
      Vue.set(block, 'latest_answer', latest_answer);
      Vue.set(block, 'complete', true);
    }
  },
  setprogressPassedCurrentStep(state: LessonState, boo: boolean) {
    Vue.set(state, 'progressPassedCurrentStep', boo);
  },
  pushBlocksInEdit(state: LessonState, id: string) {
    state.blocksInEdit.push(id);
  },
  removeBlocksInEdit(state: LessonState, id: string) {
    const index = state.blocksInEdit.indexOf(id);
    if (index > -1) {
      state.blocksInEdit.splice(index, 1);
    }
  },
  update_block(state: LessonState, newBlock: Block) {
    let blockIndex = -1;
    const stepIndex = state.lesson?.steps.findIndex((s) => {
      return s.blocks.some((b, index) => {
        if (b.id === newBlock.id) {
          blockIndex = index;
          return true;
        }
        return b.id === newBlock.id;
      });
    });
    if (stepIndex && blockIndex > -1 && state.lesson?.steps[stepIndex].blocks) {
      Vue.set(state.lesson?.steps[stepIndex].blocks, blockIndex, newBlock);
    }
  }
};
