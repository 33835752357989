'use strict';

import { MutationTree } from 'vuex';
import { LoginState, RegistrationStatus, User } from '@/login/login.types';
import Vue from 'vue';

export const mutations: MutationTree<LoginState> = {
  setUser(state: LoginState, user: User) {
    Vue.set(state, 'user', user);
  },

  updateUserScore(state: LoginState, score: number) {
    if (state.user) {
      Vue.set(state.user, 'score', score);
    }
  },

  setPCT(state: LoginState, token: string | undefined) {
    Vue.set(state, 'pct', token);
  },

  resetUser(state: LoginState) {
    Vue.set(state, 'user', undefined);
  },

  accessToken(state: LoginState, token: string | undefined) {
    Vue.set(state, 'accessToken', token);
  },

  setRegistrationStatus(state: LoginState, status: RegistrationStatus) {
    Vue.set(state, 'lockAccountRegistration', status.lock_account_registration);
  },
};
