<template>
  <ArticleLayout>
    <template #header>
      <div class="header">
        <div>
          <h1>Redeneren over literatuur</h1>
          <p>
            Nadenken over literatuur kan positief bijdragen aan het cultureel en
            historisch besef, het sociaal inzicht en de taalvaardigheid van
            leerlingen. Het is dan wel belangrijk om leerlingen te leren hoe zij
            kunnen reflecteren over literatuur. Renate van Keulen ontwikkelde
            tijdens haar promotieonderzoek samen met Sander Bax een nieuwe
            redeneerdidactiek voor het literatuuronderwijs en ontdekte dat
            leerlingen daarmee tot verdiepende inzichten komen.
          </p>
        </div>
        <iframe
          width="480"
          height="270"
          src="https://www.youtube-nocookie.com/embed/7o1ErT22NBY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </template>
    <section class="two-sides">
      <div>
        <h2>Hoe werkt de didactiek?</h2>
        <p>
          Het startpunt van de didactiek is een grote vraag die de literaire
          tekst oproept. In hun zoektocht naar een antwoord op die vraag
          verwerven leerlingen al redenerend inzichten over de tekst zelf, de
          historische en maatschappelijke context van de tekst, en zichzelf als
          lezer. Tijdens de redeneeropdrachten verbinden ze fragmenten uit de
          literaire tekst met hun eigen kennis en aangereikte ondersteunende
          informatie. Op basis van hun verworven inzichten geven de leerlingen
          uiteindelijk een antwoord op de startvraag.
        </p>
      </div>
      <div class="right">
        <Quote />
      </div>
    </section>

    <ImageWithText
      :image="require(`@/assets/images/84.jpg`)"
      alt="Leerling die bezig is met het lesmateriaal"
    >
      <p>
        De redeneerdidactiek is geschikt voor alle soorten literatuur, en kan in
        iedere klas en op ieder schoolniveau zowel mondeling als schriftelijk
        worden toegepast. Voor het promotieonderzoek
        <em>
          ‘Spelen met de waarheid. Literatuurhistorisch leren redeneren met een
          digitale game over De donkere kamer van Damokles’
        </em>
        is op basis van de didactiek een digitale game gemaakt over de roman
        <em>De donkere kamer van Damokles</em> van W.F. Hermans.
      </p>
      <p>
        Wil je meer weten over de mogelijkheden van de redeneerdidactiek? Klik
        dan in de startbalk op
        <router-link :to="{ name: 'ForTeachers' }">Voor docenten</router-link>.
        Bij
        <router-link :to="{ name: 'TeachingMaterials' }">
          Lesmateriaal
        </router-link>
        vind je diverse lesideeën en een compleet uitgewerkte lessenreeks over
        De donkere kamer van Damokles. Achtergrondinformatie over de
        totstandkoming van de didactiek vind je onder
        <router-link :to="{ name: 'Research' }"> Onderzoek</router-link>.
      </p>
    </ImageWithText>
    <CtaArea
      :title="'Lesideeën voor jouw les nodig?'"
      :subtitle="'Bekijk hier de tips voor docenten!'"
      :to="'ForTeachers'"
      :buttonText="'Kijk nu'"
    />
    <Video :videoId="'kO89ghhW_94'" />
    <section class="three-sides">
      <Quote
        :quote="'Je gaat nadenken over wat de auteur jou wil laten denken.'"
      />

      <b-image
        class="feature-image"
        :src="require(`@/assets/images/42.jpg`)"
        :alt="'test'"
        lazy
      />

      <b-image
        class="feature-image"
        :src="require(`@/assets/images/55.jpg`)"
        :alt="'test'"
        lazy
      />
    </section>
    <ContactForm />
  </ArticleLayout>
</template>

<script lang="ts">
import ContactForm from "@/core/components/ContactForm.vue";
import CtaArea from "@/core/components/CtaArea.vue";
import ImageWithText from "@/core/components/ImageWithText.vue";
import Quote from "@/core/components/Quote.vue";
import Video from "@/core/components/Video.vue";
import ArticleLayout from "@/core/layout/ArticleLayout.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ArticleLayout,
    CtaArea,
    Quote,
    ImageWithText,
    ContactForm,
    Video,
  },
})
export default class Home extends Vue {}
</script>


<style scoped lang="scss">
.header {
  max-width: $widescreen + 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  justify-items: center;
  margin: 0 auto;
  div {
    align-self: center;
  }
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }
}
iframe {
  max-width: 100%;
  border-radius: 8px;
}
.two-sides {
  display: grid;
  grid-template-columns: 3fr 2fr;
  &--reverse {
    grid-template-columns: 2fr 3fr;
  }
  gap: 3rem;
  width: 100%;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }
}
.three-sides {
  display: grid;
  grid-template-columns: 4fr 3fr 3fr;
  gap: 3rem;
  width: 100%;
  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr;
  }
}

.feature-image {
  max-height: 320px;
  max-width: 560px;
  border-radius: 1rem;
  overflow: hidden;
  margin: auto;
  display: flex;
}
</style>
