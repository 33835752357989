import { RouteConfig } from 'vue-router';
import CourseBookView from '@/course/views/CourseBookView.vue';
import IntroVideoView from '@/lesson/views/IntroVideoView.vue';
import IntroMessageView from '@/lesson/views/IntroMessageView.vue';
import LeaderboardView from '@/course/views/LeaderboardView.vue';
import { courseGuard } from '@/course/guards/course.guard';

export const courseRoutes: RouteConfig[] = [
  {
    path: "introductie-video/:courseId?",
    name: "CourseIntroduction",
    beforeEnter: courseGuard,
    component: IntroVideoView,
    props: true,
    meta: {
      title: 'Introductie video',
    }
  },
  {
    path: "introductie-text/:courseId?",
    name: "CourseIntroductionPart2",
    beforeEnter: courseGuard,
    component: IntroMessageView,
    props: true,
    meta: {
      title: 'Introductie video',
    }
  },
  {
    path: ":courseId?",
    name: "Course",
    beforeEnter: courseGuard,
    component: CourseBookView,
    props: true,
    meta: {
      title: 'Levels',
    }
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    component: LeaderboardView,
    meta: {
      title: 'Leaderboard',
    }
  }
];
