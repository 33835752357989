<template>
  <BlockBase
    :block="block"
    :checked="answerChecked"
    :edited="isEditedAfterComplete"
    :answer="answer"
    v-slot="{ isEnabled, isComingUp }"
  >
    <b-field>
      <b-input
        v-model="answer"
        :disabled="!isEnabled || isComingUp"
        type="textarea"
      ></b-input>
    </b-field>
  </BlockBase>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { SomeBlock } from "@/lesson/block.types";
import BlockBase from "@/lesson/components/BlockBase.vue";
import { block } from "@/lesson/utils/exampleTypes";

@Component({
  components: {
    BlockBase,
  },
})
export default class SomeBlockComponent extends Vue {
  @Prop({
    default() {
      const someBlock: SomeBlock = {
        ...block,
      };
      return someBlock;
    },
  })
  private block!: SomeBlock;

  private answer = "";

  private beforeMount() {
    if (
      this.block.latest_answer &&
      typeof this.block.latest_answer.answer === "string"
    ) {
      this.answer = this.block.latest_answer.answer;
    }
  }

  private get isEditedAfterComplete() {
    return (
      this.answer !== this.block.latest_answer?.answer && this.block.complete
    );
  }

  private get answerChecked() {
    if (this.answer?.length < 1) {
      return false;
    }
    return true;
  }
}
</script>

<style scoped lang="scss">
</style>
