'use strict';

import { Module } from 'vuex';
import { RootState } from '@/store/store.types';
import { CourseState } from '@/course/course.types';
import { actions } from '@/course/store/course.actions';
import { mutations } from '@/course/store/course.mutations';
import { getters } from '@/course/store/course.getters';

const namespaced = true;
export const state: CourseState = {
};

export const courseStore: Module<CourseState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
