import { render, staticRenderFns } from "./ChecklistBlockComponent.vue?vue&type=template&id=0b9afb14&scoped=true"
import script from "./ChecklistBlockComponent.vue?vue&type=script&lang=ts"
export * from "./ChecklistBlockComponent.vue?vue&type=script&lang=ts"
import style0 from "./ChecklistBlockComponent.vue?vue&type=style&index=0&id=0b9afb14&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9afb14",
  null
  
)

export default component.exports