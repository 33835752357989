'use strict';

import { Module } from 'vuex';
import { RootState } from '@/store/store.types';
import { LoginState } from '@/login/login.types';
import { actions } from '@/login/store/login.actions';
import { mutations } from '@/login/store/login.mutations';
import { getters } from '@/login/store/login.getters';

const namespaced = true;
export const state: LoginState = {
  lockAccountRegistration: false,
};

export const loginStore: Module<LoginState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
